import React from 'react';
import AsyncList from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
export default (
  <DefaultBusinessLayout
    key="/business/video/detail"
    exact
    path="/business/video/:videoId"
    component={AsyncList}
  />
);
