import React, {useEffect, useState, useMemo} from 'react';
import './Language.css';
import i18n from 'i18next';
import {useLocation} from 'react-router-dom';
import PopupMenu1 from '../../content/popupmenu1';
import languages from '../../../utils/config/languages';
import webUtils from '../../../utils/webUtils';
import Picture from '../../picture';
import _isEmpty from 'lodash/isEmpty';
import GetStrapiContent from '../../strapi/getstrapicontent';

export default function Language({isBusiness, isScrolled}) {
  const location = useLocation();
  let langs = isBusiness ? languages.business : languages.consumer;
  const [isLoaded, setIsLoaded] = useState(false);
  const {strapiContent} = GetStrapiContent(
    'YOU_CAM_VIDEO',
    setIsLoaded,
    location,
    false
  );
  const isNewHeader = useMemo(() => {
    if (
      window.location.pathname.indexOf('ycv') !== -1 &&
      isLoaded &&
      _isEmpty(strapiContent)
    )
      return false;
    return webUtils.isNewHeader();
  }, [window.location.pathname, isLoaded, strapiContent]);
  const language = i18n.language ? i18n.language : 'en';
  const isNotMakeupArUrl = !webUtils.isMakeupArUrl();
  const getCurLang = () =>
    langs.find(
      l =>
        l.i18nKey.replace('-', '_').toLowerCase() ===
        language.replace('-', '_').toLowerCase()
    );
  const [curLang, setCurLang] = useState(getCurLang);
  const {pathname, search} = window.location;
  const LanguageMenu = () => (
    <div className="pf-language-menu">
      {langs.map(lang => (
        <div className="pf-language-menu__item" key={lang.code}>
          <div onClick={() => webUtils.changeHeaderLanguage(lang.code)}>
            {lang.text}
          </div>
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    if (i18n.language) {
      setCurLang(getCurLang);
    }
  }, [i18n.language, location]);

  return (
    isNotMakeupArUrl && [
      <div key="language" className="pf-header__language">
        <PopupMenu1
          component={LanguageMenu}
          theme={isNewHeader && isScrolled ? 'dark' : 'white'}
          placement="bottom-end"
        >
          <div className="pf-header__language">
            <span className="helper" />
            <img
              alt=""
              src={
                isScrolled && isNewHeader
                  ? require('../../../assets/images/icon_language_black.svg')
                  : require('../../../assets/images/icon_language.svg')
              }
              width="22"
              height="22"
            />{' '}
            <span>{curLang ? curLang.text : langs[0].text}</span>
          </div>
        </PopupMenu1>
      </div>,
    ]
  );
}
