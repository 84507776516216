import {useContext, useEffect, useState} from 'react';
import _get from 'lodash/get';
import {AuthContext} from '../auth';
import webUtils from '../../utils/webUtils';
import _isEmpty from 'lodash/isEmpty';
import authDao from '../../dao/authDao';

export default function GetTrialPlan() {
  const {accountSummary, isFirstLoaded} = useContext(AuthContext);
  const functionType = webUtils.getOnlineServiceLink().functionType;
  const solutionType = webUtils.getOnlineServiceLink().solutionType;
  const [autoFreeTrial, setAutoFreeTrial] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  useEffect(() => {
    if (accountSummary && _get(accountSummary, 'customers')) {
      authDao
        .checkTrial({functionType, solutionType})
        .then(json => {
          if (isFirstLoaded && json.status !== 'form') {
            setIsSignIn(true);
          }
          if (json.status === 'ok') {
            return setAutoFreeTrial(true);
          }
        })
        .catch(() => {});
    }
  }, [isFirstLoaded, accountSummary, functionType]);

  return {autoFreeTrial, isSignIn};
}
