import React from 'react';
import Home from '../../business/blog/home/async';
import Post from '../../business/blog/post/async';
import Author from '../../business/blog/author/async';
import Search from '../../business/blog/search/async';
import {Redirect, Route} from 'react-router-dom';

export default [
  <Route
    key="/consumer/blog/author/:name"
    exact
    path="/:type(consumer)/blog/author/:name"
    component={Author}
  />,
  <Route
    key="/consumer/blog/post"
    exact
    path="/:type(consumer)/blog/post"
    component={Post}
  />,
  <Route
    key="/consumer/blog/author"
    exact
    path="/:type(consumer)/blog/author"
    component={Author}
  />,
  <Route
    key="/consumer/blog/search"
    exact
    path="/:type(consumer)/blog/search/:keywords"
    component={Search}
  />,
  <Route
    key="/consumer/blog/category/:attr"
    exact
    path="/:type(consumer)/blog/category/:attr"
    component={Search}
  />,
  <Route
    key="/consumer/blog/:category/:title"
    exact
    path="/:type(consumer)/blog/:category/:title"
    component={Post}
  />,
  <Route
    key="/consumer/blog/:param"
    exact
    path="/:type(consumer)/blog/:param"
    component={Home}
  />,
  <Redirect from="/consumer/blog" to="/consumer/blog/1" component={Home} />,
];
