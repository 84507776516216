import React from 'react';
import Async from './async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/highlight/ymk"
    exact
    path="/consumer/highlight/ymk"
    component={Async}
  />,
];
