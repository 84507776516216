import React from 'react';
import AsyncPage from '../../../business/misc/impressum/async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/impressum"
    exact
    path="/consumer/impressum"
    component={AsyncPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'Impressum',
        item: '/consumer/impressum',
      },
    ]}
  />,
];
