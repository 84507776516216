import React from 'react';
import Async from './async';
import DefaultConsumerLayout from '../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/gcse"
    exact
    path="/consumer/gcse"
    component={Async}
  />,
];
