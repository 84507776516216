import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import {render, hydrate} from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {LastLocationProvider} from 'react-router-last-location';
import GoTopButton from './components/gotopbtn';
import App from './App';
import './assets/styles';
import './index.css';
import webUtils from './utils/webUtils';

const rootElement = document.getElementById('root');
const isSdkPrivacy = window.location.href.indexOf('business/SDK/privacy') > -1;
if (webUtils.isPrerenderHydration()) {
  console.log('[react hydrate]');
  hydrate(
    <BrowserRouter>
      <LastLocationProvider>
        <App />
        <GoTopButton />
      </LastLocationProvider>
    </BrowserRouter>,
    rootElement
  );
} else {
  console.log(`[react render] prerender-based: ${rootElement.hasChildNodes()}`);
  render(
    <BrowserRouter>
      <LastLocationProvider>
        <App />
        {!isSdkPrivacy && <GoTopButton />}
      </LastLocationProvider>
    </BrowserRouter>,
    rootElement
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
