import React from 'react';
import dayjs from 'dayjs';

export default function FormattedDate({time, type}) {
  /*
   * time:
   * type: short/long/full
   */
  function getFormat(type) {
    if (type === 'newsIndex') {
      return 'MMMM DD, YYYY';
    } else if (type === 'newsDetailY') {
      return 'YYYY';
    } else if (type === 'newsDetailMD') {
      return 'MMMM DD';
    } else if (type === 'newsList') {
      return 'YYYY MMMM DD';
    } else if (type === 'newsCard') {
      return 'MMM DD, YYYY';
    } else if (type === 'estMin') {
      return 'h:mm A';
    } else if (type === 'mon') {
      return 'MMM';
    } else if (type === 'date') {
      return 'D';
    } else if (type === 'sessionMobileCard') {
      return 'MMMM D';
    } else if (type === 'btfDetail') {
      return 'MMM DD, YYYY';
    } else if (type === 'btfGtmClass') {
      return 'MD';
    } else if (type === 'btfMDja') {
      return 'M月D日';
    } else if (type === 'btfYMDHja') {
      return 'YYYY年M月D日H時';
    } else if (type === 'btfYMDja') {
      return 'YYYY年M月D日';
    }
    else if (type === 'gbtf-pre') {
      return `MMMM DD`;
    }
    return 'MM/DD/YYYY';
  }

  if (!time) {
    return <div />;
  }

  return <span>{dayjs(time).format(getFormat(type))}</span>;
}
