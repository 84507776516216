import {useHistory, useLocation} from 'react-router';
import deviceUtils from '../../../../../utils/deviceUtils';
import _toInteger from 'lodash/toInteger';
import _toString from 'lodash/toString';
import _isUndefined from 'lodash/isUndefined';

export const searchParamKeys = {
  debug: 'debug',
  mode: 'mode',
  index: 'index',
  source: 'source',
  autoclose: 'autoclose',
  type: 'type',
  sdk: 'sdk',
};
export const modes = {live: 'live', sizer: 'sizer'};

export default function useSearchParams() {
  const location = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(location.search);
  const debug = params.get(searchParamKeys.debug);
  const mode = params.get(searchParamKeys.mode);
  const index = params.has(searchParamKeys.index)
    ? _toInteger(params.get(searchParamKeys.index))
    : undefined;
  const source = params.get(searchParamKeys.source);
  const autoclose = params.get(searchParamKeys.autoclose);
  const isBusinessWeekly = source === 'business-weekly';
  const isAutoClose = autoclose === 'true';

  const removeSearchParams = () => history.replace({search: ''});

  const removeSearchParam = (key = '', value) => {
    if (!params.has(key)) {
      return;
    }
    if (!_isUndefined(value) && params.get(key) !== _toString(value)) {
      return;
    }
    params.delete(key);
    history.replace({search: _toString(params)});
  };

  const getSearchParam = key => {
    return params.get(key);
  };

  return {
    debug,
    defaultIndex: index,
    autoOpenLive: mode === modes.live || isBusinessWeekly,
    autoOpenSizer: mode === modes.sizer,
    defaultFrontCam: mode === modes.live && !_isUndefined(index), // for Ubitus virtual room
    closeWindowOnModuleClosed:
      isBusinessWeekly ||
      (isAutoClose && (deviceUtils.isMobile() || deviceUtils.isIpad())),
    removeSearchParams,
    removeSearchParam,
    getSearchParam,
  };
}
