import {useEffect, useState} from 'react';
import _throttle from 'lodash/throttle';

export default function useWindowScroll() {
  const getScrollTop = () =>
    window.scrollY || document.documentElement.scrollTop || 0;
  const [scrollTop, setScrollTop] = useState(getScrollTop());

  const handleScroll = _throttle(() => setScrollTop(getScrollTop()), 100, {
    leading: false,
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
    };
  }, []);

  return scrollTop;
}
