import React, {useRef, useState} from 'react';
import Overlay from 'react-bootstrap/Overlay';
import './index.css';
import classnames from 'classnames';
import _isFunction from 'lodash/isFunction';
import {useTranslation} from "react-i18next";

export default function PopupMenu({
  children,
  component: Component,
  theme = 'dark',
  placement = 'bottom',
  onChange,
}) {
  const linkRef = useRef(null);
  const [show, setShow] = useState(false);
  const {i18n} = useTranslation();
  const changeShow = show => {
    setShow(show);
    _isFunction(onChange) && onChange(show);
  };

  return (
    <div
      className={classnames({
        'pf-content-popupmenu1': true,
        'pf-content-popupmenu1--dark': theme === 'dark',
        'pf-content-popupmenu1--white': theme !== 'dark',
      })}
    >
      <a href="javascript:;" ref={linkRef} onClick={() => changeShow(!show)}>
        {children}
      </a>
      <Overlay
        id="popover-1"
        placement={placement}
        rootClose={true}
        show={show}
        transition={true}
        onHide={() => changeShow(false)}
        target={linkRef.current}
      >
        {({
          placement,
          outOfBoundaries,
          scheduleUpdate,
          arrowProps,
          ...props
        }) => (
          <div
            {...props}
            className={classnames({
              'pf-language': true,
              'pf-language-ja':i18n.language === 'ja'
            })}
            style={{
              backgroundColor: '#fff',
              color: 'white',
              borderRadius: 3,
              border: '1px solid rgba(0,0,0, 0.15)',
              zIndex: 10,
              ...props.style,
            }}
          >
            <Component onClick={() => changeShow(false)} />
          </div>
        )}
      </Overlay>
    </div>
  );
}
