import React from 'react';
import {Trans} from 'react-i18next';

const links = {
  applisting: {
    id: 'menu.business.applisting',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.app.listing">
        YouCam Makeup App Listing
      </Trans>
    ),
    link: '/business/services/app-listing',
    i18nKey: 'business:u.c.servicelinks.app.listing',
  },
  facebookarchatbot: {
    id: 'menu.business.facebookarchatbot',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.facebook.ar.chatbot">
        Facebook AR Chatbot
      </Trans>
    ),
    link: '/business/services/facebook-ar-chatbot',
    i18nKey: 'business:u.c.servicelinks.facebook.ar.chatbot',
  },
  instoreconsultation: {
    id: 'menu.business.instoreconsultation',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.in.store.consultation">
        Smart Beauty Mirror for In-Store Consultation
      </Trans>
    ),
    link: '/business/services/in-store-consultation',
    image: require('../../assets/images/business/products/eyewear/icon_service_instore.svg'),
    i18nKey: 'business:u.c.servicelinks.in.store.consultation',
  },
  sdk: {
    id: 'menu.business.sdk',
    text: <Trans i18nKey="business:u.c.servicelinks.sdk">Mobile SDK</Trans>,
    link: '/business/services/sdk',
    image: require('../../assets/images/business/products/eyewear/icon_service_sdk.svg'),
    i18nKey: 'business:u.c.servicelinks.sdk',
  },
  taobaominiprogram: {
    id: 'menu.business.taobaominiprogram',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.taobao.mini.program">
        Taobao Mini-Program
      </Trans>
    ),
    link: '/business/services/taobao-mini-program',
    i18nKey: 'business:u.c.servicelinks.taobao.mini.program',
  },
  wechatminiprogram: {
    id: 'menu.business.wechatminiprogram',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.wechat.mini.program">
        WeChat Mini-Program
      </Trans>
    ),
    link: '/business/services/wechat-mini-program',
    i18nKey: 'business:u.c.servicelinks.wechat.mini.program',
  },
  restfulapi: {
    id: 'menu.business.restfulapi',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.restful.api">RESTful API</Trans>
    ),
    i18nKey: 'business:u.c.servicelinks.restful.api',
  },
  youcam4web: {
    id: 'menu.business.youcam4web',
    text: (
      <Trans i18nKey="business:u.c.header.servicelinks.youcam.4.web">
        YouCam for Web (Enterprise)
      </Trans>
    ),
    link: '/business/services/youcam-4-web',
    i18nKey: 'business:u.c.header.servicelinks.youcam.4.web',
    image: require('../../assets/images/business/products/eyewear/icon_service_webmodule.svg'),
  },
  youcam4google: {
    id: 'menu.business.youcam4google',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.ar.virtual.makeup.for.google.and.youtube">
        AR Virtual Makeup for Google and YouTube
      </Trans>
    ),
    link: '/business/services/youcam-4-google',
    i18nKey:
      'business:u.c.servicelinks.ar.virtual.makeup.for.google.and.youtube',
  },
  virtualmakeupsnapchat: {
    id: 'menu.business.virtualmakeupsnapchat',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.virtual.makeup.snapchat">
        AR Virtual Makeup for Snapchat
      </Trans>
    ),
    link: '/business/services/virtual-makeup-snapchat',
    i18nKey: 'business:u.c.servicelinks.virtual.makeup.snapchat',
  },
  douyinminiprogram: {
    id: 'menu.business.douyinminiprogram',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.douyin.mini.program">
        Douyin Mini-Program
      </Trans>
    ),
    link: '/business/services/douyin-mini-program',
    i18nKey: 'business:u.c.servicelinks.douyin.mini.program',
  },
  webmodule: {
    id: 'menu.business.webmodule',
    text: (
      <Trans i18nKey="business:u.c.web.module">E-commerce Web Module</Trans>
    ),
    link: '/business/services/youcam-4-web',
    image: require('../../assets/images/business/products/eyewear/icon_service_webmodule.svg'),
    i18nKey: 'business:u.c.web.module',
  },
  virtualmakeupinstagram: {
    id: 'menu.business.virtualmakeupinstagram',
    text: (
      <Trans i18nKey="business:u.c.virtual.makeup.instagram">
        AR Virtual Makeup for Instagram
      </Trans>
    ),
    link: '/business/services/virtual-makeup-instagram',
    i18nKey: 'business:u.c.virtual.makeup.instagram',
  },
};

export default links;
