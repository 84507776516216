import React from 'react';
import {Route} from 'react-router-dom';
import AsyncPage from './async';
import DefaultBusinessLayout from '../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/business/compliance"
    exact
    path="/business/compliance"
    component={AsyncPage}
  />,
];
