import React from 'react';
import AsyncList from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
export default (
  <DefaultBusinessLayout
    key="/business/videos"
    exact
    path="/business/videos"
    component={AsyncList}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Videos List',
        item: '/business/videos',
      },
    ]}
  />
);
