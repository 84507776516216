import webUtils from './webUtils';

const virtualPageView = 'VirtualPageView';
const multilingualEvent = 'MultilingualEvent';
const multilingualVirtualPageView = 'MultilingualVirtualPageView';
const rotateBannerShowedEvent = 'RotateBannerShowedEvent';

const dataLayerPush = elm => {
  if (!webUtils.isWithGa()) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(elm);
};

const utils = {
  sendVirtualPageView: elm => {
    dataLayerPush({
      event: virtualPageView,
      virtualPageURL: elm.url,
      virtualPageTitle: elm.title,
    });
  },
  sendMultilingualVirtualPageView: (elm, l) => {
    dataLayerPush({
      event: multilingualVirtualPageView + '_' + l,
      virtualPageURL: elm.url,
      virtualPageTitle: elm.title,
    });
  },
  sendMultilingualPageView: l => {
    dataLayerPush({
      event: `${multilingualEvent + '_' + l}`,
    });
  },
  loadGtagScript: () => {
    if (!webUtils.isWithGa()) return;
    // gtag.js
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-811061413';
    script.async = true;
    script.addEventListener('load', handleLoaded);
    document.body.appendChild(script);

    function handleLoaded() {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        // eslint-disable-next-line no-undef
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'AW-811061413');
    }
  },
  sendRotateBannerShowedEvent: (type, language) => {
    dataLayerPush({
      event: `${rotateBannerShowedEvent}_${language}`,
      bannerType: type,
    });
  },
  sendSubmitSuccessPopUp: () => {
    dataLayerPush({
      event: 'email-sub-success-popup',
    });
  },
  sendSubmitSuccessFooter: () => {
    dataLayerPush({
      event: 'email-sub-success-bottom',
    });
  },
  sendColorTrendsSubmitSuccess: () => {
    dataLayerPush({
      event: 'trend-report-success',
    });
  },
  sendBeautyTechForumSubmitSuccess: () => {
    dataLayerPush({
      event: 'video-archive-success',
    });
  },
  sendBeautyTechForumPardotSubmitSuccess: () => {
    dataLayerPush({
      event: 'ms-register',
    });
  },
  sendInvestorsSubmitSuccess: () => {
    dataLayerPush({
      event: 'ir-register',
    });
  },
  sendPartnersSubmitSuccess: () => {
    dataLayerPush({
      event: 'partners-success',
    });
  },
  sendImpsContactSales: () => {
    dataLayerPush({
      event: 'imps_contact_sales',
    });
  },
};

export default utils;
