import React from 'react';
import {Trans} from 'react-i18next';
import i18n from '../../utils/i18n';
import technologyLinks from '../../utils/config/technologyLinks';
import webUtils from '../../utils/webUtils';
import headerUtils from '../../utils/headerUtils';

export default [
  {
    id: 'menu.business.solutions',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.solutions">
        Solutions
      </Trans>
    ),
    i18nKey: 'business:c.l.f.menudefinitions.solutions',
    items: [
      ...headerUtils.makeupProducts,
      ...headerUtils.faceDiaProducts,
      ...headerUtils.hairProducts,
      ...headerUtils.bearddyeProducts,
      ...headerUtils.accessoriesProducts,
      ...headerUtils.eyewearProducts,
    ],
  },
  {
    id: 'menu.business.solutions.line2',
    text: <div />,
    i18nKey: 'business:c.l.f.menudefinitions.solutions.line2',
    items: [...headerUtils.nailProducts, ...headerUtils.jewelryWatchesProducts],
  },
  {
    id: 'menu.business.services',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service">Services</Trans>
    ),
    i18nKey: 'business:c.l.s.leftmenu.online.service',
    items: [...headerUtils.onlineService],
  },
  {
    id: 'menu.consumer.technologyLinks',
    text: technologyLinks.home.text,
    i18nKey: technologyLinks.home.i18nKey,
    items: [...headerUtils.techMenu],
  },
  {
    id: 'menu.business.company',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.company">Company</Trans>
    ),
    i18nKey: 'business:c.l.f.menudefinitions.company',
    items: [
      {
        id: 'menu.business.aboutus',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.about.us">
            About Us
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.about.us',
        link: '/business/info/aboutus',
      },
      {
        id: 'menu.business.newsevents',
        text: <Trans i18nKey="business:p.b.h.news.title">News</Trans>,
        i18nKey: 'business:p.b.h.news.title',
        link: '/business/news/list',
      },
      {
        id: 'menu.business.awards',
        text: <Trans i18nKey="business:p.b.h.awards.title">Awards</Trans>,
        i18nKey: 'business:p.b.h.awards.title',
        link: '/business/info/awards',
      },
      {
        id: 'menu.business.contactus',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.contact.us">
            Contact Us
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.contact.us',
        link: '/business/contact-us/sales',
      },
      {
        id: 'menu.business.investors',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.investors">
            Investors
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.investors',
        link: 'https://ir.perfectcorp.com/',
      },
      {
        id: 'menu.business.esg',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.esg">
            ESG Commitments
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.esg',
        link: '/business/info/esg',
      },
      {
        id: 'menu.business.partners',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.partners">
            Partners
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.partners',
        link: '/business/partners',
      },
      {
        id: 'menu.business.career',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.career">Career</Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.career',
        link: webUtils.jobLink(),
      },
      {
        id: 'menu.business.compliance',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.compliance">
            Compliance
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.compliance',
        link: '/business/compliance',
      },
    ],
  },
  {
    id: 'menu.business.resources',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.demo.resources">Resources</Trans>
    ),
    i18nKey: 'business:c.l.s.leftmenu.demo.resources',
    items: [...headerUtils.footerResource],
  },
  {
    id: 'menu.business.support',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.support">Support</Trans>
    ),
    i18nKey: 'business:c.l.f.menudefinitions.support',
    items: [
      {
        id: 'menu.business.faq',
        text: <Trans i18nKey="business:c.l.f.menudefinitions.faq">FAQ</Trans>,
        i18nKey: 'business:c.l.f.menudefinitions.faq',
        link: '/business/faq',
      },
      {
        id: 'menu.business.doc',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.document.center">
            Document Center
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.document.center',
        link: `${webUtils.getEnvReactAppUrl()}/document-center/${
          i18n.language === 'ja' ? 'ja' : 'en'
        }/`,
        notFollow: true,
      },
      {
        id: 'menu.business.terms',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.terms">
            Terms of Service
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.terms',
        link: '/business/terms',
        notFollow: true,
      },
      {
        id: 'menu.business.privacy',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.privacy">
            Privacy Policy
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.privacy',
        link: '/business/privacy',
        notFollow: true,
      },
      {
        id: 'menu.business.impressum',
        text: (
          <Trans i18nKey="business:c.l.f.menudefinitions.imrpessum">
            Impressum
          </Trans>
        ),
        i18nKey: 'business:c.l.f.menudefinitions.imrpessum',
        link: '/business/impressum',
      },
    ],
  },
];
