import React from 'react';
import classnames from 'classnames';
import Container from '../../../layout/container';
import Link from '../../../link';
import IosBtn from '../../../downloadbutton/iosbtn';
import AndroidBtn from '../../../downloadbutton/androidbtn';
import webUtils from '../../../../utils/webUtils';
import AppDownloadIcons from '../../../../pages/consumer/home/AppDownloadIcons';
import './appvideobanner.css';
import AppIconList from '../../../../pages/consumer/home/AppIconList';

export default function SectionTopBanner({
  title,
  titleIcon,
  content,
  downloadLinks,
  video = '',
  width,
  poster,
  aspectratio = 820 / 420,
  iconIsSquare = 1,
  appList = [],
  addBannerBorderMb,
}) {
  const aspectRatioDefault = webUtils.isMobile() ? 390 / 478 : 1366 / 612;
  const bannerHeight = aspectratio
    ? `calc( 100vw / ${aspectratio})`
    : `calc( 100vw / ${aspectRatioDefault})`;

  const iosbtn = IosBtn();
  const androidbtn = AndroidBtn();
  const isAndroid = webUtils.isAndroid();
  const downloadStyle = {textAlign: 'center'};
  const containerStyle = {maxWidth: 'none'};
  const getIconTitle = () => {
    return (
      <div
        className={classnames({
          'video-banner-content-title': true,
          icon: titleIcon,
          'video-banner-content-full-icon': !iconIsSquare,
        })}
      >
        {titleIcon && <img src={titleIcon} alt="" />}
        <h1 className="video-banner-content-title">{title}</h1>
      </div>
    );
  };
  return (
    <div>
      {!webUtils.isMobile() && (
        <div
          className={`video-banner consumer-home-banner`}
          style={{height: bannerHeight}}
        >
          <video
            preload={'true'}
            autoPlay
            muted
            loop
            poster={poster}
            style={{position: 'absolute', width: '100%', height: bannerHeight}}
          >
            <source src={video} />
          </video>
          <Container style={containerStyle}>
            <div className="video-banner-content">
              <div>{getIconTitle()}</div>
              {width > 1024 && (
                <div className="video-banner-content-icons">
                  {content}
                  <AppIconList appList={appList} />
                  {downloadLinks && (
                    <AppDownloadIcons
                      links={downloadLinks}
                      type={'videoBanner'}
                    />
                  )}
                </div>
              )}
            </div>
          </Container>
        </div>
      )}
      <div>
        {width <= 1024 && width > 600 && (
          <Container className="video-banner-content-icons">
            {content}
            <div className="video-banner-content-icons-pad">
              <AppIconList appList={appList} />
            </div>
            {downloadLinks && (
              <AppDownloadIcons links={downloadLinks} type={'videoBanner'} />
            )}
          </Container>
        )}
      </div>
      {webUtils.isMobile() && (
        <Container
          className={`video-banner--mobile`}
          style={{
            borderBottom: addBannerBorderMb ? '1px solid #f2f2f2' : 'none',
          }}
        >
          <video
            preload={'true'}
            autoPlay
            loop
            muted
            playsInline
            poster={poster}
            style={{width: '100%', marginBottom: '40px', height: bannerHeight}}
          >
            <source src={video} />
          </video>
          <div className="video-banner-content">
            {getIconTitle()}
            {content}
            <AppIconList appList={appList} />
            <div className="text-center" style={{marginBottom: '30px'}}>
              {isAndroid ? (
                <Link
                  to={downloadLinks[1].link}
                  className="video-banner-content-title__store"
                >
                  <img src={androidbtn} alt="download app" />
                </Link>
              ) : (
                <Link
                  to={downloadLinks[0].link}
                  className="video-banner-content-title__store"
                >
                  <img src={iosbtn} alt="download app" />
                </Link>
              )}
            </div>
          </div>
        </Container>
      )}
    </div>
  );
}
