import React from 'react';
import AsyncList from '../../../business/news/list/async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/:type(consumer)/news/list"
    exact
    path="/:type(consumer)/news/list"
    component={AsyncList}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'News List',
        item: '/consumer/news/list',
      },
    ]}
  />,
];
