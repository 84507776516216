import React from 'react';
import classnames from 'classnames';
import Link from '../../../components/link';
import './index.css';
import {HashLink} from 'react-router-hash-link';
import pathUtils from '../../../utils/pathUtils';
import _get from 'lodash/get';
import _includes from 'lodash/includes';

export default function PinkButton({
  link,
  hash,
  text,
  gtm = '',
  style,
  bgColor = 'pink',
  func,
  square = false,
  href,
  className = '',
  id = '',
  isNewTab,
  rel,
  onlyFunc = false,
  dropdownlistbutton = false,
  disabled,
}) {
  const btnClassNames = classnames(
    {
      'pf-btn': true,
      'pf-pink-btn': bgColor === 'pink',
      'pf-grey-btn': bgColor === 'grey',
      'pf-black-btn': bgColor === 'black',
      'pf-white-btn': bgColor === 'white',
      'pf-linear-btn': bgColor === 'linear',
      'pf-blue-purple-linear-btn': bgColor === 'blue-purple-linear',
      'pf-square-btn': square,
      [gtm]: !!gtm,
    },
    className
  );
  if (dropdownlistbutton) {
    return (
      <div className={btnClassNames} onClick={func} style={style}>
        {text}
      </div>
    );
  }
  if (onlyFunc) {
    return (
      <button
        type={'submit'}
        className={btnClassNames}
        onClick={func}
        style={style}
        id={id}
        disabled={disabled}
      >
        {text}
      </button>
    );
  }
  if (hash) {
    return (
      <HashLink
        className={btnClassNames}
        style={style}
        to={hash}
        onClick={func}
        id={id}
      >
        {text}
      </HashLink>
    );
  }

  const _isEnteringDemostoreFromOtherPages = link =>
    link &&
    !isNewTab &&
    window.location.pathname &&
    !window.location.pathname.includes('/showcase/') &&
    link.includes('/showcase/');

  if (_isEnteringDemostoreFromOtherPages(link)) {
    href = pathUtils.addLangPrefix(link);
    link = '';
  }
  return link ? (
    <Link
      className={btnClassNames}
      to={link}
      style={style}
      onClick={func}
      id={id}
      isNewTab={isNewTab}
      rel={rel}
    >
      {text}
    </Link>
  ) : (
    <a
      className={btnClassNames}
      href={href}
      style={style}
      target={isNewTab ? '_blank' : '_self'}
      rel={rel}
      onClick={func}
      id={id}
    >
      {text}
    </a>
  );
}
