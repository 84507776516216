import React from 'react';
import AsyncPage from './async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/apps/ycn"
    exact
    path="/consumer/apps/ycn"
    component={AsyncPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'YCN',
        item: '/consumer/apps/ycn',
      },
    ]}
  />,
];
