import React from 'react';
import {Redirect} from 'react-router';
import home from './home/routes';
import finder from './finder/routes';
import debug from './debug/routes';

export default [
  <Redirect
    key="/business/showcase/shadefinder/"
    exact
    from="/business/showcase/shadefinder/"
    to="/business/showcase/shadefinder/home"
  />,
  home,
  finder,
  debug,
];
