import React from 'react';
import Home from './home/async';
import Post from './post/async';
import Author from './author/async';
import Search from './search/async';
import {Redirect, Route} from 'react-router-dom';

export default [
  <Route
    key="/business/blog/author/:name"
    exact
    path="/:type(business)/blog/author/:name"
    component={Author}
  />,
  <Route
    key="/business/blog/post"
    exact
    path="/:type(business)/blog/post"
    component={Post}
  />,
  <Route
    key="/business/blog/author"
    exact
    path="/:type(business)/blog/author"
    component={Author}
  />,
  <Route
    key="/business/blog/search"
    exact
    path="/:type(business)/blog/search/:keywords"
    component={Search}
  />,
  <Route
    key="/business/blog/category/:attr"
    exact
    path="/:type(business)/blog/category/:attr"
    component={Search}
  />,
  <Route
    key="/business/blog/:category/:title"
    exact
    path="/:type(business)/blog/:category/:title"
    component={Post}
  />,
  <Route
    key="/business/blog/:param"
    exact
    path="/:type(business)/blog/:param"
    component={Home}
  />,
  <Redirect from="/business/blog" to="/business/blog/1" component={Home} />,
];
