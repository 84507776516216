import {useMemo} from 'react';
import {useLocation} from 'react-router';
import storeUtils from '../../../../../../utils/storeUtils';
import webUtils from '../../../../../../utils/webUtils';
import watchMenuItems from './watchMenuItems';
import _values from 'lodash/values';
import _includes from 'lodash/includes';
import utils from '../../../../../../utils/utils';
import _get from 'lodash/get';

const searchParamKeys = {
  campaign: 'campaign',
};
const supportedCampaigns = {watch: 'watch'};

export default function useCampaignSearchParams() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const campaign = getFeature(params.get(searchParamKeys.campaign));
  const campaignMenuItems = useMemo(() => getCampaignMenuItems(campaign), [
    campaign,
  ]);

  return {
    campaignMenuItems,
    isCampaignMode: !!_get(campaignMenuItems, 'length'),
  };
}

function getFeature(feature) {
  // Only for CN campaign for now (non-CN-domain production sites are excluded)
  if (utils.isProduction && !webUtils.isCnDomain()) {
    return;
  }

  if (isValidFeature(feature)) {
    storeUtils.setDemostoreCampaignMode(feature);
    return feature;
  }
  const storedFeature = storeUtils.getDemostoreCampaignMode();
  return isValidFeature(storedFeature) ? storedFeature : null;
}

function isValidFeature(f) {
  return _includes(_values(supportedCampaigns), f);
}

function getCampaignMenuItems(f) {
  if (f === supportedCampaigns.watch) {
    return watchMenuItems;
  }
}
