import qs from 'query-string';
import fetch from 'isomorphic-fetch';
import gtmUtils from '../utils/gtmUtils';
import utmUtils from '../utils/utmUtils';
import storeUtils from "../utils/storeUtils";

const beautyTechForumDao = {
  buildQs: (params = {}) => '?' + qs.stringify(params),
  listCourse: async params => {
    try {
      const response = await fetch(
        `/smb-frontend/course/list-courses.action` +
        beautyTechForumDao.buildQs(params),
        {
          method: 'GET',
        }
      );
      return response.json();
    } catch (err) {
      return Promise.reject();
    }
  },
  listSession: async params => {
    try {
      const response = await fetch(
        `/smb-frontend/course/list-sessions.action` +
          beautyTechForumDao.buildQs(params),
        {
          method: 'GET',
        }
      );
      return response.json();
    } catch (err) {
      return Promise.reject();
    }
  },
  listSessionsByCourseId: async params => {
    try {
      const response = await fetch(
        `/smb-frontend/course/list-sessions-by-course-id.action` +
          beautyTechForumDao.buildQs(params),
        {
          method: 'GET',
        }
      );
      return response.json();
    } catch (err) {
      return Promise.reject();
    }
  },
  querySession: async params => {
    try {
      const response = await fetch(
        `/smb-frontend/course/query-session.action` +
          beautyTechForumDao.buildQs(params),
        {
          method: 'GET',
        }
      );
      return response.json();
    } catch (err) {
      return Promise.reject();
    }
  },
  SubmitWebinar: async support => {
    const sourceUtm = utmUtils.getSupportSourceUtm();
    const refUrlFirst = storeUtils.getFirstUrlSource();
    const refUrlLast = storeUtils.getLastUrlSource();
    const response = await fetch(
      `/smb-frontend/support/create-request.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({support: {...support, sourceUtm, refUrlFirst, refUrlLast}}),
      }
    );
    if (response.ok) {
      gtmUtils.sendBeautyTechForumPardotSubmitSuccess();
      return await response.json();
    }
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  querySpeaker: async params => {
    try {
      const response = await fetch(
        `/smb-frontend/course/query-speaker.action` +
          beautyTechForumDao.buildQs(params),
        {
          method: 'GET',
        }
      );
      return response.json();
    } catch (err) {
      return Promise.reject();
    }
  },
};

export default beautyTechForumDao;
