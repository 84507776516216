import React from 'react';
import AsyncPage from './async';
import {Redirect} from 'react-router';
import {Route} from 'react-router-dom';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import AsyncThankPage from '../contactsales/thankyou/async';
import BlankLayout from '../../../../components/layout/BlankLayout';
import ContactSalesAsync from '../contactsales/async';

export default [
  <Redirect
    key="/business/contact-us"
    exact
    from="/business/contact-us"
    to="/business/contact-us/sales"
  />,
  <Route
    key="/business/contact-us"
    exact
    path="/business/contact-us/:type(sales|media)?"
    component={AsyncPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'contact-us',
        item: '/business/contact-us',
      },
    ]}
    showContactSales={false}
    demostorebutton={false}
  />,
  <DefaultBusinessLayout
    key="/business/contact-sales/:thankyou"
    exact
    path="/business/contact-sales/:thankyou(thank-you)"
    component={AsyncThankPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Thank you',
        item: '/business/contact-sales/thankyou',
      },
    ]}
    showContactSales={false}
  />,
  <BlankLayout
    key="/business/contact-sales"
    exact
    path="/business/contact-sales"
    component={ContactSalesAsync}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Contact Sales',
        item: '/business/contact-sales',
      },
    ]}
    showContactSales={false}
  />,
];
