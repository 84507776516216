import React, {useState} from 'react';
import {Trans} from 'react-i18next';
import './index.css';
import _get from 'lodash/get';
import classnames from 'classnames';
import webUtils from '../../../../../utils/webUtils';
import VisibilitySensor from 'react-visibility-sensor';
import PFLink from '../../../../link';

export default function OnlineServiceSwitchBtn({pageType, width}) {
  const [inView, setInView] = useState(true);
  const toRedirectLink = type => {
    const paramsSearch = window.location.search;
    return `${_get(webUtils.getOnlineServiceLink(), `${type}`)}${paramsSearch}`;
  };

  const content = () => {
    return (
      <>
        <PFLink
          className={classnames({
            'online-service-switch--btn': true,
            'online-service-switch--btn--active': pageType === 'feature',
          })}
          to={toRedirectLink('feature')}
          onClick={() => {
            if (webUtils.isMobile()) {
              const elem = document.querySelector('#switchBtnAnchor');
              elem &&
                elem.scrollIntoView({behavior: 'instant', block: 'start'});
            }
          }}
        >
          <Trans i18nKey="business:c.l.s.videobanner.features">Features</Trans>
        </PFLink>
        {width > 600 && <div style={{width: '60px'}} />}
        <PFLink
          className={classnames({
            'online-service-switch--btn': true,
            'online-service-switch--btn--active': pageType === 'plan',
          })}
          to={toRedirectLink('plan')}
          onClick={() => {
            if (webUtils.isMobile()) {
              const elem = document.querySelector('#switchBtnAnchor');
              elem &&
                elem.scrollIntoView({behavior: 'instant', block: 'start'});
            }
          }}
        >
          <Trans i18nKey="business:c.l.s.leftmenu.plan.pricing">
            Plan &amp; Pricing
          </Trans>
        </PFLink>
      </>
    );
  };

  return (
    <div>
      <VisibilitySensor onChange={isVisible => setInView(isVisible)}>
        <div className="online-service-switch--btn-anchor" />
      </VisibilitySensor>
      <div id="switchBtnAnchor" />
      <div className="text-center">
        <div
          className={classnames({
            'online-service-switch--btn--container': true,
          })}
          style={{
            visibility:
              !inView && window.scrollY > window.innerHeight
                ? 'hidden'
                : 'visible',
          }}
        >
          {content()}
        </div>
        {!inView && window.scrollY > window.innerHeight && (
          <div
            className={classnames({
              'online-service-switch--btn--container': true,
              'online-service-switch--btn--fixed': true,
            })}
          >
            {content()}
          </div>
        )}
      </div>
    </div>
  );
}
