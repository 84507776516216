import React from 'react';
import Async from './async';
import BlankLayout from '../../../../components/layout/BlankLayout';
import {Redirect} from 'react-router';

export default [
  <BlankLayout
    key="/business/beauty-fashion-metaverse"
    exact
    path="/business/beauty-fashion-metaverse"
    component={Async}
  />,
  <BlankLayout
    key="/business/CES2022"
    exact
    path="/business/CES2022"
    component={Async}
  />,
];
