import {useEffect} from 'react';
import {forceCheck, forceVisible} from 'react-lazyload';

export default function useForceCheck(props = {}) {
  const {callback} = props;

  const onScroll = () => {
    window.removeEventListener('scroll', onScroll);
    forceVisible();
    forceCheck();
    callback && callback();
  };

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
}
