import webUtils from './webUtils';
import shareUtils from './shareUtils';
const twitterPrefix = 'https://twitter.com/intent/tweet';
const linkedInPrefix = 'https://www.linkedin.com/shareArticle';

const utils = {
  appSharePrefix: () => {
    return webUtils.getEnvReactAppUrl();
  },
  videoSharePrefix: () => {
    return `${shareUtils.appSharePrefix()}/business/video/`;
  },
  btfSharePrefix: () => {
    return `${
      shareUtils.appSharePrefix()
    }/business/beauty-tech-forum-master-class/detail/`;
  },
  btfvSharePrefix: () => {
    return `${
      shareUtils.appSharePrefix()
    }/business/global-beauty-tech-forum/detail/`;
  },
  isFbSdkExists: () => {
    const s = document.getElementById('facebook-sdk');
    return undefined != s || null != s;
  },
  loadFbSdk: () => {
    (function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-sdk');
  },
  initFbSdk: () => {
    // eslint-disable-next-line no-undef
    FB.init({
      appId: '534169110558013',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v7.0',
    });
  },
  shareToFb: ({url = '/', display = 'popup'}) => {
    const sharingSetting = {
      method: 'share',
      display: display,
      href: `${shareUtils.appSharePrefix()}${url}`,
    };
    const callback = function(response) {
      console.log(response);
    };

    if (utils.isFbSdkExists()) {
      // eslint-disable-next-line no-undef
      FB.ui(sharingSetting, callback);
      return;
    }

    utils.loadFbSdk();
    window.fbAsyncInit = function() {
      utils.initFbSdk();
      // eslint-disable-next-line no-undef
      FB.getLoginStatus(function(response) {
        // eslint-disable-next-line no-undef
        FB.ui(sharingSetting, callback);
      });
    };
  },
  shareVideoToFb: (video, display = 'popup') => {
    if (utils.isFbSdkExists()) {
      // eslint-disable-next-line no-undef
      FB.ui(
        {
          method: 'share',
          display: display,
          href: shareUtils.videoSharePrefix() + video.id,
        },
        function(response) {
          console.log({response});
        }
      );
      return;
    }

    utils.loadFbSdk();
    window.fbAsyncInit = function() {
      utils.initFbSdk();

      // eslint-disable-next-line no-undef
      FB.getLoginStatus(function(response) {
        // eslint-disable-next-line no-undef
        FB.ui(
          {
            method: 'share',
            display: display,
            href: shareUtils.videoSharePrefix() + video.id,
          },
          function(response) {
            console.log({response});
          }
        );
      });
    };
  },
  shareBtfToFb: (type = 'btf', btf, display = 'popup') => {
    const href =
      type === 'btf' ? shareUtils.btfSharePrefix() + btf.id : `${shareUtils.btfvSharePrefix()}${btf.id}`;
    if (utils.isFbSdkExists()) {
      // eslint-disable-next-line no-undef
      FB.ui(
        {
          method: 'share',
          display: display,
          href,
        },
        function(response) {
          console.log({response});
        }
      );
      return;
    }

    utils.loadFbSdk();
    window.fbAsyncInit = function() {
      utils.initFbSdk();

      // eslint-disable-next-line no-undef
      FB.getLoginStatus(function(response) {
        // eslint-disable-next-line no-undef
        FB.ui(
          {
            method: 'share',
            display: display,
            href,
          },
          function(response) {
            console.log({response});
          }
        );
      });
    };
  },
  shareToLinkedIn: ({url = '/', popupWindowName = ''}) => {
    const sharedLink = `${shareUtils.appSharePrefix()}${url}`;
    const windowUrl = `${linkedInPrefix}?url=${encodeURIComponent(sharedLink)}`;

    utils.popupWindow(windowUrl, popupWindowName);
  },
  shareVideoToLinkedIn: video => {
    const url =
      linkedInPrefix +
      '?url=' +
      encodeURIComponent(shareUtils.videoSharePrefix() + video.id);
    utils.popupWindow(url, video.videoName);
  },
  shareBtfToLinkedIn: (type = 'btf', btf) => {
    const uri =
      type === 'btf' ? shareUtils.btfSharePrefix() + btf.id : `${shareUtils.btfvSharePrefix()}${btf.id}`;
    const url = linkedInPrefix + '?url=' + encodeURIComponent(uri);
    utils.popupWindow(url, btf.sessionTitle);
  },
  tweetToTwitter: ({
    url = '/',
    popupWindowName = '',
    defaultSharingText = '',
    hashtags = [],
  }) => {
    hashtags = Array.isArray(hashtags) ? hashtags.join(',') : hashtags;
    const sharedLink = `${shareUtils.appSharePrefix()}${url}`;
    const windowUrl =
      `${twitterPrefix}?` +
      `text=${encodeURIComponent(defaultSharingText)}` +
      `&hashtags=${hashtags}` +
      `&url=${encodeURIComponent(sharedLink)}`;

    utils.popupWindow(windowUrl, popupWindowName);
  },
  tweetVideoToTwitter: video => {
    const url =
      twitterPrefix +
      '?text=' +
      encodeURIComponent(video.videoName) +
      '&hashtags=' +
      utils.genHashtags(video) +
      '&url=' +
      encodeURIComponent(shareUtils.videoSharePrefix() + video.id);
    utils.popupWindow(url, video.videoName);
  },
  tweetBtfToTwitter: (type = 'btf', btf) => {
    const sharePrefix =
      type === 'btf' ? shareUtils.btfSharePrefix() + btf.id : `${shareUtils.btfvSharePrefix()}${btf.id}`;
    const url =
      twitterPrefix +
      '?text=' +
      encodeURIComponent(btf.sessionTitle) +
      '&url=' +
      encodeURIComponent(sharePrefix);
    utils.popupWindow(url, btf.videoName);
  },
  popupWindow: (url, target = '') => {
    const w = 600;
    const h = 400;
    const left = window.screen.width / 2 - w / 2;
    const top = window.screen.height / 2 - h / 2;
    window.open(
      url,
      target,
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=
        ${w},height=${h},top=${top},left=${left}`
    );
    return false;
  },
  genHashtags: video => {
    let tags = [];
    if (video.productTypeNames && !!video.productTypeNames.length) {
      video.productTypeNames.forEach(pt =>
        tags.push(pt.replaceAll(/[\s-]/g, '_'))
      );
    }
    if (video.serviceTypeNames && !!video.serviceTypeNames.length) {
      video.serviceTypeNames.forEach(st =>
        tags.push(st.replaceAll(/[\s-]/g, '_'))
      );
    }
    if (!!tags.length) {
      return tags.join(',');
    }
    return 'PerfectCorp';
  },
};

export default utils;
