import React, {useState, useEffect} from 'react';
import gdprUtils from '../../../utils/gdprUtils';
import Container from '../container';
import Logo2 from '../header/Logo2';
import Header2Button from '../header/Header2Button';
import './index.css';
import useWindowWidth from '../../hooks/useWindowWidth';
import {Trans, useTranslation} from 'react-i18next';

export default function CampaignHeader({
  isBusiness,
  hideStatement = true,
  width,
}) {
  const {i18n} = useTranslation();
  const [popupMenu, setPopupMenu] = useState(false);
  const clientWidth = useWindowWidth();

  const debounce = fn => {
    let frame;
    return (...params) => {
      if (frame) {
        cancelAnimationFrame(frame);
      }
      frame = requestAnimationFrame(() => {
        fn(...params);
      });
    };
  };
  const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
    document.documentElement.dataset.scroll50 = window.scrollY > 50;
    document.documentElement.dataset.scroll100 = window.scrollY > 100;
    document.documentElement.dataset.scroll150 = window.scrollY > 150;
  };
  document.addEventListener('scroll', debounce(storeScroll));

  useEffect(() => {
    storeScroll();
  }, []);

  const menuHeight = clientWidth > 768 ? 77 : 69;
  const isSkin = window.location.pathname.indexOf('skin-diagnostic') !== -1;
  const isVto = window.location.pathname.indexOf('/vto') !== -1;
  const isSkinAnalysis =
    window.location.pathname.indexOf('skin-analysis') !== -1;
  const isVirtualMakeup =
    window.location.pathname.indexOf('virtual-makeup') !== -1;

  return (
    <>
      <div className="pf-header__ppc">
        <Container
          className="pf-header__ppc-mobile"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div className="pf-header__left">
            <span onClick={() => setPopupMenu(false)}>
              <Logo2 isBusiness={isBusiness} />
            </span>
          </div>
          <div className="pf-header__right" style={{flexGrow: 0}}>
            <Header2Button
              link={
                isVirtualMakeup
                  ? '/business/campaign/virtual-makeup'
                  : isSkin || isVto
                  ? '/business/contact-us/sales'
                  : isSkinAnalysis
                  ? '/business/campaign/ai-skin-analysis'
                  : '/business/user/free-trial'
              }
              hash={'contactsales'}
              text={
                isVto ? (
                  <Trans i18nKey="business:p.b.c.header2.request.free.demo">
                    Request Free Demo
                  </Trans>
                ) : isSkin || isSkinAnalysis || isVirtualMakeup ? (
                  <>
                    {i18n.language === 'ja' && width <= 500 ? (
                      <Trans i18nKey="business:p.b.c.header2.contact.us.now">
                        Contact Us Now
                      </Trans>
                    ) : (
                      <Trans i18nKey="business:p.b.c.arskindiagnostic.contact.us.now">
                        Contact Us Now
                      </Trans>
                    )}
                  </>
                ) : (
                  <Trans i18nKey="business:c.l.h.btn.trial.title">
                    Try free for 30 days
                  </Trans>
                )
              }
            />
          </div>
        </Container>
      </div>
      <div className="pf-header__ppc-shadow" style={{top: `${menuHeight}px`}} />
    </>
  );
}
