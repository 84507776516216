import React, {useState} from 'react';
import Container from '../../container';
import classnames from 'classnames';
import MobileMenus from '../MobileMenus';
import useWindowWidth from '../../../hooks/useWindowWidth';
import useWindowScroll from '../../../hooks/useWindowScroll';
import _isEmpty from 'lodash/isEmpty';
import './index.css';
import {HashLink} from 'react-router-hash-link';
import Link from '../../../link';

export default function NFTHeader({
  is2b,
  item,
  menu,
  activeText,
  BannerIcon,
  isEvent = false,
  showTickets = true,
  utm_medium = '',
}) {
  const scrollTop = useWindowScroll();
  const [popupMenu, setPopupMenu] = useState(false);
  const width = useWindowWidth();
  return (
    <Container
      className={classnames({
        'nft-header': true,
        'nft-header-scroll': !!scrollTop || width <= 1024,
      })}
    >
      <div className="nft-header--left-container">
        <Link
          className="nft-header--pf-btn"
          to={is2b ? `/business` : `/consumer`}
        >
          <img
            alt=""
            src={require('../../../../assets/images/nft/perfect_logo.svg')}
          />
        </Link>
        {!_isEmpty(BannerIcon) && (
          <div
            className="nft-header--nft-btn"
            onClick={BannerIcon.func}
            style={BannerIcon.style}
          >
            {BannerIcon.text}
          </div>
        )}
      </div>
      <div className="nft-header--right-container">
        {!_isEmpty(item) &&
          item.map((s, index) => (
            <div key={`nft-header--item${index}`}>
              {s.hashlink ? (
                <HashLink
                  to={
                    utm_medium ? '?utm_medium=' + utm_medium + s.link : s.link
                  }
                  className={classnames({
                    'nft-header--btn-active': activeText === s.activeText,
                  })}
                >
                  {s.text}
                </HashLink>
              ) : (
                <Link
                  to={s.link}
                  className={classnames({
                    'nft-header--btn-active': activeText === s.activeText,
                  })}
                >
                  {s.text}
                </Link>
              )}
            </div>
          ))}
      </div>
      {width <= 600 && menu && (
        <MobileMenus
          isBusiness={false}
          popupMenu={popupMenu}
          setPopupMenu={setPopupMenu}
          isNewHeader={false}
          isEvent={isEvent}
          showTickets={showTickets}
          utm_medium={utm_medium}
        />
      )}
    </Container>
  );
}
