import React from 'react';
import classnames from 'classnames';
import {Trans, useTranslation} from 'react-i18next';
import {HashLink} from 'react-router-hash-link';

export default function MobileGbtfEventMenus({
  setPopupMenu,
  showTickets,
  utm_medium,
}) {
  const {i18n} = useTranslation();
  const langParam =
    i18n.language === 'fr' ? 'fra' : i18n.language === 'ja' ? 'jpn' : 'enu';
  return (
    <div className="pf-header-mobile-consumer-menus">
      <div
        className="nft-header-mobile-menus__item"
        onClick={() => setPopupMenu(false)}
      >
        <HashLink
          className={classnames({
            'nft-content-popupmenu1--active':
              window.location.href.indexOf(
                '/business/global-beauty-tech-forum#speakers'
              ) !== -1 ||
              window.location.href.indexOf(
                `/business/global-beauty-tech-forum/${langParam}#speakers`
              ) !== -1,
            'nft-content-popupmenu1--white': true,
          })}
          to={
            utm_medium ? '?utm_medium=' + utm_medium + `#speakers` : `#speakers`
          }
        >
          <Trans i18nKey="business:p.b.g.e.speakers">Speakers</Trans>
        </HashLink>
      </div>
      <div
        className="nft-header-mobile-menus__item"
        onClick={() => setPopupMenu(false)}
      >
        <HashLink
          className={classnames({
            'nft-content-popupmenu1--active':
              window.location.href.indexOf(
                '/business/global-beauty-tech-forum#agenda'
              ) !== -1 ||
              window.location.href.indexOf(
                `/business/global-beauty-tech-forum/${langParam}#agenda`
              ) !== -1,
            'nft-content-popupmenu1--white': true,
          })}
          to={utm_medium ? '?utm_medium=' + utm_medium + `#agenda` : `#agenda`}
        >
          <Trans i18nKey="business:p.b.g.e.agenda">Agenda</Trans>
        </HashLink>
      </div>
      {showTickets && (
        <div
          className="nft-header-mobile-menus__item"
          onClick={() => setPopupMenu(false)}
        >
          <HashLink
            className={classnames({
              'nft-content-popupmenu1--active':
                window.location.href.indexOf(
                  '/business/global-beauty-tech-forum#tickets'
                ) !== -1 ||
                window.location.href.indexOf(
                  `/business/global-beauty-tech-forum/${langParam}#tickets`
                ) !== -1,
              'nft-content-popupmenu1--white': true,
            })}
            to={utm_medium ? '?utm_medium=' + utm_medium + `#tickets` : `#tickets`}
          >
            <Trans i18nKey="business:p.b.g.e.tickets">Tickets</Trans>
          </HashLink>
        </div>
      )}
    </div>
  );
}
