import React from 'react';
import AsyncPage from './aimakeuptransfer/async';
import BlankLayout from '../../../../components/layout/BlankLayout';

export default [
  <BlankLayout
    key="/business/showcase/gen-ai/ai-makeup-transfer"
    exact
    path="/business/showcase/gen-ai/ai-makeup-transfer"
    component={AsyncPage}
  />,
];
