import React, {memo} from 'react';
import {PrerenderedComponent} from 'react-prerendered-component';

const prerenderedLoadable = LoadableComponent => {
  return memo(props => (
    <PrerenderedComponent live={LoadableComponent.preload()}>
      <LoadableComponent {...props} />
    </PrerenderedComponent>
  ));
};

export default prerenderedLoadable;
