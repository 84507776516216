import React, {useState} from 'react';
import Link from '../../../components/link';
import webUtils from '../../../utils/webUtils';
import IosBtn from '../../../components/downloadbutton/iosbtn';
import AndroidBtn from '../../../components/downloadbutton/androidbtn';
import './index.css';
import QRCode from 'qrcode.react';
import useWindowWidth from '../../../components/hooks/useWindowWidth';

export default function AppDownloadIcons({
  links = [{type: 'ios', link: ''}, {type: 'android', link: ''}],
  style = {},
  appName = 'banner',
  gtmPrefix = 'gtm_2c-home_',
  qrStyle = {},
  showQR = true,
  noAndroid = false,
}) {
  const width = useWindowWidth();
  const iosImg = IosBtn();
  const androidImg = AndroidBtn(noAndroid);
  const isMobile = webUtils.isMobile();
  const isAndroid = webUtils.isAndroid();
  const [qrLink, setQrLink] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const button = (link, index) => {
    if (!link) return '';
    const isAppleLink = link.indexOf('apple.com') !== -1;
    const isGoogleLink = link.indexOf('google.com') !== -1;
    const isAppDownloadLinks = !isAppleLink && !isGoogleLink;
    const appleDownloadButton = (
      <Link
        target="_blank"
        to={link}
        className={`${gtmPrefix}_${appName}_appstore pf-2c--download-button`}
      >
        <img src={iosImg} alt={'ios'} />
      </Link>
    );
    const googleDownloadButton = (
      <Link
        target="_blank"
        to={link}
        className={`${gtmPrefix}_${appName}_playstore pf-2c--download-button`}
      >
        <img src={androidImg} alt={'android'} />
      </Link>
    );
    if (
      (width > 1024 && (isAppleLink || (isAppDownloadLinks && index === 0))) ||
      (width <= 1024 &&
        !isAndroid &&
        (isAppleLink || (isAppDownloadLinks && index === 0)))
    ) {
      return appleDownloadButton;
    }
    if (
      (width > 1024 && (isGoogleLink || (isAppDownloadLinks && index === 1))) ||
      (width <= 1024 &&
        isAndroid &&
        (isGoogleLink || (isAppDownloadLinks && index === 1)))
    ) {
      return googleDownloadButton;
    }
    return <div />;
  };

  return (
    <div style={style}>
      {!isMobile && (
        <div style={{...qrStyle, display: 'flex'}}>
          {links.map((l, i) => (
            <div>
              <div
                onMouseOver={() => {
                  setQrLink(l.link);
                  setActiveIndex(i);
                }}
                onMouseOut={() => {
                  setQrLink(null);
                  setActiveIndex(null);
                }}
              >
                {button(l.link, i)}
              </div>
              <div>
                {qrLink && activeIndex === i && showQR && (
                  <div className={'pf-app-download__qr-code-block'}>
                    <QRCode size={100} bgColor={'transparent'} value={qrLink} />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {isMobile && (
        <>
          {links.map((l, i) => (
            <>{button(l.link, i)}</>
          ))}
        </>
      )}
    </div>
  );
}
