import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import AsyncPage from './async';

export default [
  <Route
    key="/business/services/youcam-4-web"
    exact
    path="/business/services/youcam-4-web"
    component={AsyncPage}
  />,
];
