import React from 'react';
import {Route} from 'react-router';
import {useTranslation} from 'react-i18next';
import useScrollToTop from '../hooks/useScrollToTop';
import LazyLoad from 'react-lazyload';
import '../../assets/styles/languages/languagefontfamily.css';
import classnames from 'classnames';
import FadeTransition from '../transition/fade';

export default ({
  component: Component,
  isEnterprise = false,
  breadcrumb = [],
  ...rest
}) => {
  useScrollToTop();
  const {i18n} = useTranslation();
  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <FadeTransition>
            <div
              className={classnames({
                'pf-language': true,
                'pf-language-ja': i18n.language === 'ja',
              })}
            >
              <LazyLoad height={1000} once={true} unmountIfInvisible={true}>
                <div
                  style={{
                    backgroundColor: '#fff',
                  }}
                >
                  <Component {...matchProps} breadcrumb={breadcrumb} />
                </div>
              </LazyLoad>
            </div>
          </FadeTransition>
        );
      }}
    />
  );
};
