import React from 'react';
import AsyncPage from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/business/technologies/live-3d-face-ar"
    exact
    path="/business/technologies/live-3d-face-ar"
    component={AsyncPage}
    backgroundImage={require('../../../../assets/images/consumer/technologies/background.jpg')}
  />,
];
