import React from 'react';
import Container from '../container';
import PinkButton from '../../button/pinkbutton';
import cookieUtils from '../../../utils/cookieUtils';
import './index.css';
import _get from 'lodash/get';

export default function PreviewBanner() {
  let pfFormId = cookieUtils.getCookie('pfFormId') || '';
  window.addEventListener('message', event => {
    pfFormId = event.data;
  });
  if (!pfFormId) return <div />;
  return (
    <div className="preview-bg pf-language">
      <Container className="preview-container" mobileType={true}>
        <div>Preview Mode</div>
        <PinkButton
          onlyFunc={true}
          func={() => {
            cookieUtils.setCookie('pfFormId', '');
            window.location.reload();
          }}
          text={'Finish'}
          style={{padding: '0 40px'}}
          square={true}
        />
      </Container>
    </div>
  );
}
