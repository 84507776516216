import React from 'react';
import {Route} from 'react-router';
import {useTranslation} from 'react-i18next';
import Footer from './footer/async';
import useScrollToTop from '../hooks/useScrollToTop';
import LazyLoad from 'react-lazyload';
import '../../assets/styles/languages/languagefontfamily.css';
import classnames from 'classnames';
import FadeTransition from '../transition/fade';

export default ({component: Component, ...rest}) => {
  useScrollToTop();
  const {i18n} = useTranslation();

  return (
    <Route
      {...rest}
      render={matchProps => (
        <FadeTransition>
          <div
            className={classnames({
              'pf-language': true,
              'pf-language-ja': i18n.language === 'ja',
            })}
          >
            <LazyLoad height={1000} once={true} unmountIfInvisible={true}>
              <div
                style={{
                  backgroundColor: '#fff',
                }}
              >
                <Component {...matchProps} />
              </div>
            </LazyLoad>
            <LazyLoad
              height={500}
              once={true}
              unmountIfInvisible={true}
              throttle={100}
            >
              <Footer isCampaign={true} pageReady={true}/>
            </LazyLoad>
          </div>
        </FadeTransition>
      )}
    />
  );
};
