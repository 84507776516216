const notifyAPPWithCommand = (command, paras, errCount) => {
  if (!errCount) errCount = 0;
  try {
    // console.log('success', command, paras);
    // if (debug && command !== 'debug')
    //   logger.addLog('command:' + command, 'paras=' + paras);
    if (
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers[command]
    )
      window.webkit.messageHandlers[command].postMessage(paras);
    else window.control.action(command, paras);
  } catch (err) {
    // console.log('error', err);
    errCount++;
    // if (debug && command !== 'debug')
    //   logger.addLog(
    //     'commandError:' + command,
    //     'paras=' + paras + ', err=' + err + ', errCount=' + errCount
    //   );
    if (errCount > 5) return;
    setTimeout(function() {
      notifyAPPWithCommand(command, paras, errCount);
    }, 1000);
  }
};

export {notifyAPPWithCommand};
