import React from 'react';
import AsyncPage from './async';
import ShowocaseLayout from '../../../../components/layout/ShowocaseLayout';

export default [
  <ShowocaseLayout
    key="/business/showcase/youcam-tutorial"
    exact
    path="/business/showcase/youcam-tutorial"
    component={AsyncPage}
  />,
  // tutorial special link for Allure
  <ShowocaseLayout
    key="/business/showcase/allure-november-look"
    exact
    path="/business/showcase/allure-november-look"
    component={AsyncPage}
  />,
];
