import React, {useEffect, useState} from 'react';
import Seo from '../../../../components/seo/index';
import {useTranslation} from 'react-i18next';
import useWindowWidth from '../../../../components/hooks/useWindowWidth';
import _isEmpty from 'lodash/isEmpty';
import PrerenderLayout from '../../../../components/layout/PrerenderLayout';
import LoadingPage from '../../../../components/loadingpage';
import Strapi from '../../../../components/strapi';
import GetStrapiContent from '../../../../components/strapi/getstrapicontent';
import './index.css';

export default function OnlineServiceCampaign({location}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const {i18n, t} = useTranslation();
  const width = useWindowWidth();
  const {strapiContent} = GetStrapiContent(
    'CAMPAIGN_MAKEUP',
    setIsLoaded,
    location,
    true
  );
  if (!isLoaded || _isEmpty(strapiContent))
    return (
      <PrerenderLayout>
        <LoadingPage />
      </PrerenderLayout>
    );

  return (
    <PrerenderLayout
      showContactSales={false}
      showFreeTrialButton={false}
      showOnlineService={true}
      showFreeTrial={true}
    >
      <Seo
        key="seo"
        title={t(
          'seo:business.online.service.campaign.title',
          'Makeup Try-On Online Service | Campaign'
        )}
        description={t(
          'seo:business.online.service.campaign.desc',
          'The easiest online self-service Virtual Makeup Try-On Widget designed for small and medium beauty e-commerce'
        )}
        url="/business/solutions/online-service/campaign"
      />
      {strapiContent.map((s, i) => (
        <div
          className="campaign-custom-css"
          key={`campaign-makeup-strapi-sec${i}`}
        >
          <Strapi
            content={s}
            width={width}
            productPage={'campaign-makeup'}
            i18n={i18n}
          />
        </div>
      ))}
    </PrerenderLayout>
  );
}
