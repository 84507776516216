const importBusinessResourceBundle = async language => {
  switch (language) {
    case 'zh_CN':
      return import(
        /* webpackChunkName: "business-cn-json" */ `./business/cn.json`
      );
    case 'zh-CN':
      return import(
        /* webpackChunkName: "business-cn-json" */ `./business/cn.json`
      );
    case 'de':
      return import(
        /* webpackChunkName: "business-de-json" */ `./business/de.json`
      );
    case 'es':
      return import(
        /* webpackChunkName: "business-es-json" */ `./business/es.json`
      );
    case 'fr':
      return import(
        /* webpackChunkName: "business-fr-json" */ `./business/fr.json`
      );
    case 'ja':
      return import(
        /* webpackChunkName: "business-ja-json" */ `./business/ja.json`
      );
    case 'ko':
      return import(
        /* webpackChunkName: "business-ko-json" */ `./business/ko.json`
      );
    case 'pt':
      return import(
        /* webpackChunkName: "business-pt-json" */ `./business/pt.json`
      );
    case 'zh_TW':
      return import(
        /* webpackChunkName: "business-tw-json" */ `./business/tw.json`
      );
    case 'zh-TW':
      return import(
        /* webpackChunkName: "business-tw-json" */ `./business/tw.json`
      );
    case 'it':
      return import(
        /* webpackChunkName: "business-it-json" */ `./business/it.json`
      );
    default:
      return import(
        /* webpackChunkName: "business-en-json" */ `./business/en.json`
      );
  }
};

const importConsumerResourceBundle = async language => {
  switch (language) {
    case 'zh_CN':
      return import(
        /* webpackChunkName: "consumer-cn-json" */ `./consumer/cn.json`
      );
    case 'zh-CN':
      return import(
        /* webpackChunkName: "consumer-cn-json" */ `./consumer/cn.json`
      );
    case 'de':
      return import(
        /* webpackChunkName: "consumer-de-json" */ `./consumer/de.json`
      );
    case 'es':
      return import(
        /* webpackChunkName: "consumer-es-json" */ `./consumer/es.json`
      );
    case 'fr':
      return import(
        /* webpackChunkName: "consumer-fr-json" */ `./consumer/fr.json`
      );
    case 'ja':
      return import(
        /* webpackChunkName: "consumer-ja-json" */ `./consumer/ja.json`
      );
    case 'ko':
      return import(
        /* webpackChunkName: "consumer-ko-json" */ `./consumer/ko.json`
      );
    case 'pt':
      return import(
        /* webpackChunkName: "consumer-pt-json" */ `./consumer/pt.json`
      );
    case 'zh_TW':
      return import(
        /* webpackChunkName: "consumer-tw-json" */ `./consumer/tw.json`
      );
    case 'zh-TW':
      return import(
        /* webpackChunkName: "consumer-tw-json" */ `./consumer/tw.json`
      );
    case 'it':
      return import(
        /* webpackChunkName: "consumer-tw-json" */ `./consumer/it.json`
        );
    default:
      return import(
        /* webpackChunkName: "consumer-en-json" */ `./consumer/en.json`
      );
  }
};

const importSeoResourceBundle = async language => {
  switch (language) {
    case 'zh_CN':
      return import(/* webpackChunkName: "seo-cn-json" */ `./seo/cn.json`);
    case 'zh-CN':
      return import(/* webpackChunkName: "seo-cn-json" */ `./seo/cn.json`);
    case 'de':
      return import(/* webpackChunkName: "seo-de-json" */ `./seo/de.json`);
    case 'es':
      return import(/* webpackChunkName: "seo-es-json" */ `./seo/es.json`);
    case 'fr':
      return import(/* webpackChunkName: "seo-fr-json" */ `./seo/fr.json`);
    case 'ja':
      return import(/* webpackChunkName: "seo-ja-json" */ `./seo/ja.json`);
    case 'ko':
      return import(/* webpackChunkName: "seo-ko-json" */ `./seo/ko.json`);
    case 'pt':
      return import(/* webpackChunkName: "seo-pt-json" */ `./seo/pt.json`);
    case 'zh_TW':
      return import(/* webpackChunkName: "seo-tw-json" */ `./seo/tw.json`);
    case 'zh-TW':
      return import(/* webpackChunkName: "seo-tw-json" */ `./seo/tw.json`);
    case 'it':
      return import(/* webpackChunkName: "seo-tw-json" */ `./seo/it.json`);
    default:
      return import(/* webpackChunkName: "seo-en-json" */ `./seo/en.json`);
  }
};

export default {
  type: 'backend',
  init: () => {},
  read: function(language, namespace, callback) {
    if (namespace === 'business') {
      importBusinessResourceBundle(language).then(RB => {
        callback(null, RB.default);
      });
    } else if (namespace === 'consumer') {
      importConsumerResourceBundle(language).then(RB =>
        callback(null, RB.default)
      );
    } else if (namespace === 'seo') {
      importSeoResourceBundle(language).then(RB => callback(null, RB.default));
    } else {
      callback(true, false);
    }
  },
  readMulti: () => {},
  save: () => {},
  create: () => {},
};
