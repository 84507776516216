import fetch from 'isomorphic-fetch';

const parseJson = response => {
  return response.text().then(text => {
    return text ? JSON.parse(text) : {};
  });
};

const handleJsonResponse = response => {
  return parseJson(response).then(json => {
    if (response.ok) {
      return json;
    } else {
      console.error(json.errorMessage);
      return Promise.reject({
        json,
        status: response.status,
        statusText: response.statusText,
      });
    }
  });
};

const utils = {
  fetch: (url, options) => fetch(url, options).then(handleJsonResponse),
};

export default utils;
