import cookieUtils from './cookieUtils';
import users from './config/user';
import webUtils from './webUtils';

const utils = {
  getSignInUrl: (lang, solutionType) => {
    return `/business/user/sign-in?next=${encodeURIComponent(
      webUtils.getProductPageFromSolutionType(lang, solutionType)
    )}`;
  },
  getAccountSettingUrl: () =>
    webUtils.getSMBEnvReactAppUrl('/general-settings/account-settings'),
  getAffiliationDays: ({solutionType = 'MAKEUP'}) => {
    const affiliationDays = cookieUtils.getCookie('affiliationDays');
    const isSkinCare =
      solutionType === 'SKIN_ANALYSIS' ||
      solutionType === 'AESTHETIC_SIMULATION';
    const parsedAffiliationDays = JSON.parse(affiliationDays || '{}');
    if (!parsedAffiliationDays[solutionType]) {
      return isSkinCare ? 14 : users.freeTrialDays;
    }
    return parsedAffiliationDays[solutionType];
  },
};

export default utils;
