import React from 'react';
import AsyncPage from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/business/technologies/agile-face"
    exact
    path="/business/technologies/agile-face"
    component={AsyncPage}
    isEnterprise={true}
  />,
];
