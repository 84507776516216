import React from 'react';
import './index.css';
import Container from '../layout/container';
import useWindowWidth from '../hooks/useWindowWidth';

export default function LoadingPage() {
  const width = useWindowWidth();
  const BannerContent = () => {
    return (
      <div
        className="pf-product__loading-banner--content"
        style={{overflowX: 'hidden'}}
      >
        <div
          className="pf-product__loading-content"
          style={{width: '80%', height: '42px', marginBottom: '19px'}}
        />
        <div
          className="pf-product__loading-content"
          style={{height: '19px', marginBottom: '13px'}}
        />
        <div
          className="pf-product__loading-content"
          style={{width: '60%', height: '19px', marginBottom: '13px'}}
        />
        <div className="pf-product__loading-banner-btn--container">
          <div className="pf-product__loading-content pf-product__loading-banner-btn" />
          <div style={{width: '12px', height: '18px'}} />
          <div className="pf-product__loading-content pf-product__loading-banner-btn" />
        </div>
      </div>
    );
  };
  const LoadingImageText = (theme, reverse) => {
    return (
      <div
        style={{
          backgroundColor: theme === 'dark' ? '#f2f2f2' : '#fff',
        }}
      >
        <Container
          mobileType={true}
          className="pf-product__loading-textImage-container"
          style={{
            flexDirection: reverse ? 'row-reverse' : 'initial',
          }}
        >
          <div
            className="pf-product__loading-content"
            style={{
              height: width > 600 ? '320px' : '219px',
              marginBottom: width > 600 ? 0 : '25px',
            }}
          />
          <div>
            <div
              className="pf-product__loading-content"
              style={{height: '42px', marginBottom: '29px', width: '90%'}}
            />
            <div
              className="pf-product__loading-content"
              style={{height: '19px', marginBottom: '13px'}}
            />
            <div
              className="pf-product__loading-content"
              style={{height: '19px', marginBottom: '29px', width: '60%'}}
            />
            <div style={{textAlign: width > 600 ? 'left' : 'right'}}>
              <div className="pf-product__loading-content pf-product__loading-banner-btn" />
            </div>
          </div>
        </Container>
      </div>
    );
  };
  return (
    <div>
      {width > 600 ? (
        <div className="pf-product__loading-banner--container">
          {BannerContent()}
        </div>
      ) : (
        <div className={'banner-borderBottom'}>
          <div className="pf-product__loading-banner--container" />
          {BannerContent()}
        </div>
      )}
      {LoadingImageText('white', false)}
      {LoadingImageText('dark', true)}
      {LoadingImageText('white', false)}
    </div>
  );
}
