import React from 'react';
import {Trans} from 'react-i18next';
import AgileFaceText from '../../pages/business/technologies/agileface/RegisteredName';
import AgileHandText from '../../pages/business/technologies/agilehand/component/registeredname/index';

const links = {
  home: {
    id: 'menu.consumer.technologies',
    text: (
      <Trans i18nKey="consumer:u.c.technologylinks.home">Technologies</Trans>
    ),
    link: '/business/technologies',
    i18nKey: 'consumer:u.c.technologylinks.home',
  },
  overview: {
    id: 'menu.consumer.overview',
    text: (
      <Trans i18nKey="consumer:u.c.technologylinks.overview">Overview</Trans>
    ),
    link: '/business/technologies/agile-face',
    i18nKey: 'consumer:u.c.technologylinks.overview',
  },
  facedetectiontracking: {
    id: 'menu.consumer.facedetectiontracking',
    text: (
      <Trans i18nKey="consumer:u.c.technologylinks.face.detection.tracking">
        Face Detection & Tracking
      </Trans>
    ),
    link: '/business/technologies/face-detection-tracking',
    i18nKey: 'consumer:u.c.technologylinks.face.detection.tracking',
  },
  makeupar: {
    id: 'menu.consumer.makeupar',
    text: (
      <Trans i18nKey="consumer:u.c.technologylinks.makeup.ar">Makeup AR</Trans>
    ),
    link: '/business/technologies/makeup-ar',
    i18nKey: 'consumer:u.c.technologylinks.makeup.ar',
  },
  live3dfacear: {
    id: 'menu.consumer.live3dfacear',
    text: (
      <Trans i18nKey="consumer:u.c.technologylinks.live.3d.face.ar">
        Live 3D Face AR
      </Trans>
    ),
    link: '/business/technologies/live-3d-face-ar',
    i18nKey: 'consumer:u.c.technologylinks.live.3d.face.ar',
  },
  skincarear: {
    id: 'menu.consumer.skincarear',
    text: (
      <Trans i18nKey="consumer:u.c.technologylinks.skincare.ar">
        Skincare AR
      </Trans>
    ),
    link: '/business/technologies/skincare-ar',
    i18nKey: 'consumer:u.c.technologylinks.skincare.ar',
  },
  faceai: {
    id: 'menu.consumer.faceai',
    text: <Trans i18nKey="consumer:u.c.technologylinks.face.ai">Face AI</Trans>,
    link: '/business/technologies/face-ai',
    i18nKey: 'consumer:u.c.technologylinks.face.ai',
  },
  agileface: {
    id: 'menu.business.agileface',
    text: <AgileFaceText />,
    link: '/business/technologies/agile-face',
    i18nKey: 'business:u.c.solutionlinks.agile.face',
  },
  agilehand: {
    id: 'menu.business.agilehand',
    text: <AgileHandText />,
    link: '/business/technologies/agile-hand-tracking',
    i18nKey: 'business:u.c.solutionlinks.agile.hand',
  },
};

export default links;
