import React, {useState} from 'react';
import {Route} from 'react-router';
import {useTranslation} from 'react-i18next';
import Footer4BusinessAsync from './footer4business/async';
import useScrollToTop from '../hooks/useScrollToTop';
import LazyLoad, {forceVisible} from 'react-lazyload';
import '../../assets/styles/languages/languagefontfamily.css';
import classnames from 'classnames';
import webUtils from '../../utils/webUtils';
import FadeTransition from '../transition/fade';
import _indexOf from 'lodash/indexOf';
import useForceCheck from '../hooks/useForceCheck';
import FloatingBtns from '../floatingbtns';

export default ({
  component: Component,
  isEnterprise = false,
  breadcrumb = [],
  showContactSales = true,
  showDemoStore = true,
  ...rest
}) => {
  useForceCheck();
  forceVisible();
  useScrollToTop();
  const {i18n} = useTranslation();
  const isInvestors = webUtils.isInvestors();
  const isGcse = webUtils.isGcse();
  const langParam = webUtils.getLangPath(i18n.language);
  const isSdkPrivacy =
    _indexOf(
      [`${langParam}/business/SDK/privacy`],
      window.location.pathname
    ) !== -1;
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  return (
    <Route
      {...rest}
      render={matchProps => {
        return (
          <FadeTransition>
            <FloatingBtns
              showContactSales={!isSdkPrivacy}
              showDemoStore={
                !isInvestors && !isSdkPrivacy && !isGcse && showDemoStore
              }
            />
            <div
              className={classnames({
                'pf-language': true,
                'pf-language-ja': i18n.language === 'ja',
              })}
            >
              <div style={{backgroundColor: '#fff'}}>
                <Component
                  {...matchProps}
                  breadcrumb={breadcrumb}
                  isFooterVisible={isFooterVisible}
                />
              </div>
              {!webUtils.isMobile() ? (
                <Footer4BusinessAsync
                  setShow={setIsFooterVisible}
                  pageReady={true}
                />
              ) : (
                <LazyLoad
                  height={500}
                  once={true}
                  unmountIfInvisible={true}
                  throttle={100}
                >
                  {isSdkPrivacy ? (
                    <></>
                  ) : (
                    <Footer4BusinessAsync
                      setShow={setIsFooterVisible}
                      pageReady={true}
                    />
                  )}
                </LazyLoad>
              )}
            </div>
          </FadeTransition>
        );
      }}
    />
  );
};
