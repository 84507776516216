import React from 'react';
import AsyncList from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import BlankLayout from '../../../../components/layout/BlankLayout';

export default [
  <DefaultBusinessLayout
    key="/business/successstory/list"
    exact
    path="/business/successstory/list"
    component={AsyncList}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Success Story List',
        item: '/business/successstory/list',
      },
    ]}
  />,
  <BlankLayout
    key="/business/iframe/successstory/list"
    exact
    path="/business/iframe/successstory/list"
    component={AsyncList}

  />,
];
