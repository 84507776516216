const utils = {
  getHeaderTopByWidth: width => {
    if (width >= 1400) {
      return 40;
    }
    if (width >= 800) {
      return 60;
    }
    if (width >= 500) {
      return 80;
    }
    if (width >= 300) {
      return 100;
    }
  },
  getSubHeaderTopByWidth: width => {
    if (width >= 1400) {
      return 92;
    }
    if (width >= 800) {
      return 112;
    }
    if (width >= 500) {
      return 132;
    }
    if (width >= 300) {
      return 152;
    }
  },
  getHeightByWidth: width => {
    if (width >= 1400) {
      return 40;
    }
    if (width >= 800) {
      return 60;
    }
    if (width >= 500) {
      return 80;
    }
    if (width >= 300) {
      return 100;
    }
  },
};

export default utils;
