import React from 'react';
import {Redirect} from 'react-router';
import home from './home/routes';
import playground from './playground/routes';

export default [
  <Redirect
    key="/business/showcase/3d-viewer/"
    exact
    from="/business/showcase/3d-viewer/"
    to="/business/showcase/3d-viewer/home"
  />,
  home,
  playground,
];
