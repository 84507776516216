import React, {useState, useEffect} from 'react';
import './index.css';
import ProductBannerButton from '../button';
import useWindowWidth from '../../hooks/useWindowWidth';
import '../index.css';
import pathUtils from '../../../utils/pathUtils';
import {Trans, useTranslation} from 'react-i18next';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import ArrowLink from '../../arrowlink';
import webUtils from '../../../utils/webUtils';
import _get from 'lodash/get';
import FreeTrialButton from '../../button/freetrialbutton';
import PinkButton from '../../button/pinkbutton';
import ReactPlayer from 'react-player';
import EnterpriseButtons from './component/enterprisebuttons';
import {VisibilityObserver} from 'reactjs-visibility';
import QRCode from 'qrcode.react';
import OnlineServiceSwitchBtn from './component/onlineserviceswitchbtn';
import GetTrialPlan from '../../hooks/getTrialPlan';

export default function ProductVideoBanner({
  desktopVideo,
  mobileVideo,
  desktopPoster,
  mobilePoster,
  title,
  desc,
  theme = 'dark',
  gtmClassNamePrefix = '',
  extraButtons = null,
  buttonConfig = {},
  bgClassName,
  version = '',
  aspectratio,
  enhancedTitle = '',
  ctaText,
  superscript,
  buttons = [],
  setShow = () => {},
  pageType,
  setPageType,
}) {
  const [qrLink, setQrLink] = useState(false);
  const handleChangeVisibility = visible => {
    setShow(!visible);
  };
  const width = useWindowWidth();
  const {i18n} = useTranslation();
  const isMobile = width <= 600;
  const isSmb = webUtils.isSmb();
  const {autoFreeTrial, isSignIn} = GetTrialPlan();
  const functionType = webUtils.getOnlineServiceLink().functionType;
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const isOnlineService =
    window.location.pathname.indexOf('/online-service') !== -1;

  const buttonsCollections = () => {
    return (
      <div className="pf-product__banner--buttons-pad-container">
        {isSmb && isSignIn && (
          <>
            <div className="pf-product__banner--btn">
              {autoFreeTrial && isSmb ? (
                <div>
                  <FreeTrialButton
                    className={'pf-product__banner--btn-style'}
                    gtm={'gtm_header_menubar_free_trial'}
                    style={{
                      margin: !isMobile ? '0 15px 0 0' : '0 auto 15px',
                    }}
                    bgColor={'pink'}
                  />
                </div>
              ) : (
                <div>
                  <PinkButton
                    func={() => {
                      // if (webUtils.isMobile()) return;
                      const elem = document.querySelector('#switchBtnAnchor');
                      elem &&
                        elem.scrollIntoView({
                          behavior: 'instant',
                          block: 'start',
                        });
                    }}
                    isNewTab={false}
                    className="pf-product__banner--btn-style"
                    text={
                      <Trans i18nKey="business:p.b.h.solutions.smb.index.subscribe">
                        Subscribe Now
                      </Trans>
                    }
                    link={webUtils.getOnlineServiceLink().plan}
                    gtm={`${buttonConfig[1].gtm}_${webUtils.determineLanguage(
                      i18n.language
                    )}`}
                    square={true}
                    bgColor={'pink'}
                    style={{
                      margin: !isMobile ? '0 15px 0 0' : '0 auto 15px',
                    }}
                  />
                </div>
              )}
              {buttonConfig[1].buttonType === 'Image with QRcode' ? (
                <div
                  onMouseOver={() => {
                    setQrLink(true);
                  }}
                  onMouseOut={() => {
                    setQrLink(false);
                  }}
                >
                  <PinkButton
                    isNewTab={buttonConfig[1].newTab}
                    className="pf-product__banner--btn-style"
                    text={buttonConfig[1].text}
                    href={buttonConfig[1].link}
                    gtm={`${buttonConfig[1].gtm}_${webUtils.determineLanguage(
                      i18n.language
                    )}`}
                    square={true}
                    bgColor={'white'}
                  />
                  {buttonConfig[1].link && qrLink && width > 1024 && (
                    <div className="text-center">
                      <div className={'qr-code-block'}>
                        <QRCode
                          size={100}
                          bgColor={'white'}
                          value={buttonConfig[1].link}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {buttonConfig[1].text && (
                    <PinkButton
                      isNewTab={buttonConfig[1].newTab}
                      className="pf-product__banner--btn-style"
                      text={buttonConfig[1].text}
                      href={buttonConfig[1].link}
                      gtm={`${buttonConfig[1].gtm}_${webUtils.determineLanguage(
                        i18n.language
                      )}`}
                      square={true}
                      bgColor={'white'}
                    />
                  )}
                </div>
              )}
            </div>
            {extraButtons}
          </>
        )}
        {(!isSmb || !isSignIn) && (
          <ProductBannerButton
            buttonConfig={buttonConfig}
            theme={theme}
            extraButtons={extraButtons}
            gtmClassNamePrefix={gtmClassNamePrefix}
          />
        )}
        <EnterpriseButtons buttons={buttons} />
        {!_isEmpty(ctaText) && (
          <>
            {ctaText.map((s, i) => (
              <div
                key={`topbanner-${i}`}
                className={classnames({
                  'text-center': width <= 1024,
                })}
                style={{marginTop: '15px'}}
              >
                <ArrowLink
                  newTab={s.newTab}
                  title={s.ctaText}
                  link={s.ctaLink}
                  imgStyle={{width: '22px'}}
                />
              </div>
            ))}
          </>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (isReady) {
      setIsPlaying(true);
    }
  }, [isReady]);

  return (
    <div style={{position: 'relative', backgroundColor: '#fff'}}>
      <VisibilityObserver onChangeVisibility={handleChangeVisibility}>
        <div>
          <div
            className={`pf-product__video-banner--container ${version} ${bgClassName}`}
            style={{
              backgroundImage:
                width > 600 && desktopPoster
                  ? `url(${desktopPoster})`
                  : !bgClassName && 'initial',
              height:
                width > 600 && aspectratio && `calc( 100vw / ${aspectratio})`,
            }}
          >
            {isSmb && functionType !== 'SKINCARE_PRO' && (
              <div
                onClick={() => {
                  if (functionType === 'EYEWEAR') {
                    window.location.href = pathUtils.addLangPrefix(
                      '/business/showcase/online-service/eye-wear'
                    );
                  } else if (functionType === 'SKINCARE_PRO') {
                    window.location.href = pathUtils.addLangPrefix(
                      '/business/showcase/online-service/skincare-pro'
                    );
                  } else {
                    window.location.href = pathUtils.addLangPrefix(
                      '/business/showcase/online-service'
                    );
                  }
                }}
                className={classnames({
                  'opacity-btn': true,
                  'opacity-btn-eyewear': functionType === 'EYEWEAR',
                })}
              />
            )}
            {!isMobile && (
              <ReactPlayer
                playsinline={true}
                onReady={() => {
                  setIsReady(true);
                }}
                fullscreen={false}
                loop={true}
                previewTabIndex={1}
                autoPlay={true}
                url={desktopVideo}
                muted={true}
                playing={true}
                width={'100vw'}
                height={
                  aspectratio ? `calc( 100vw / ${aspectratio})` : 'initial'
                }
                controls={false}
                style={{position: 'absolute'}}
                config={{
                  file: {
                    attributes: {
                      autoplay: true,
                      muted: true,
                    },
                  },
                }}
              />
            )}
            {isMobile && (
              <div
                className={`pf-product__video-mobile--container ${bgClassName} ${version}`}
                style={{
                  backgroundImage: mobilePoster
                    ? `url('${mobilePoster}')`
                    : 'initial',
                  height: aspectratio
                    ? `calc( 100vw / ${aspectratio})`
                    : 'initial',
                }}
              >
                <ReactPlayer
                  playsinline={true}
                  onReady={() => {
                    setIsReady(true);
                  }}
                  fullscreen={false}
                  loop={true}
                  previewTabIndex={1}
                  autoPlay={true}
                  url={mobileVideo}
                  muted={true}
                  playing={true}
                  width={'100vw'}
                  height={
                    aspectratio ? `calc( 100vw / ${aspectratio})` : 'initial'
                  }
                  style={{visibility: !isPlaying ? 'hidden' : 'visible'}}
                  controls={false}
                  config={{
                    file: {
                      attributes: {
                        autoplay: true,
                        muted: true,
                      },
                    },
                  }}
                />
              </div>
            )}
            <div className="pf-product__video-banner--content">
              {_get(superscript, 'title') && (
                <div className="pf-product__banner--superscript">
                  <div>{_get(superscript, 'title')}</div>
                  <img alt="" src={_get(superscript, 'icon')} />
                </div>
              )}
              <h1 className="pf-product__banner--title">
                {title}
                {enhancedTitle && (
                  <span className="pf-product__banner--enhancedTitle">
                    {enhancedTitle}
                  </span>
                )}
              </h1>
              <div className="pf-product__banner--desc">{desc}</div>
              {(width > 1024 || width <= 600) && buttonsCollections()}
            </div>
          </div>
          {width > 600 && width <= 1024 && buttonsCollections()}
          {width <= 600 && <div className="pf-product__banner--divider" />}
        </div>
      </VisibilityObserver>
      {isOnlineService && (
        <OnlineServiceSwitchBtn
          pageType={pageType}
          setPageType={setPageType}
          width={width}
        />
      )}
    </div>
  );
}
