import React from 'react';
import AsyncComponent from './async';
import DefaultCampaignLayout from '../../../../components/layout/DefaultCampaignLayout';

export default [
    <DefaultCampaignLayout
        key="/business/campaign/virtual-makeup"
        exact
        path="/business/campaign/virtual-makeup"
        component={AsyncComponent}
        breadcrumb={[
            {
                position: 1,
                name: 'Home',
                item: '/',
            },
            {
                position: 2,
                name: 'Business',
                item: '/business',
            },
            {
                position: 3,
                name: 'Virtual Makeup',
                item: '/business/campaign/virtual-makeup',
            },
        ]}
    />,
];
