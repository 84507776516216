import webUtils from './webUtils';
import i18n from 'i18next';

const utils = {
  isBtfEnabled: () =>
    ['en', 'ja'].indexOf(webUtils.determineLanguage(i18n.language)) !== -1,
  isBtfvEnabled: () =>
    ['fr', 'ja'].indexOf(webUtils.determineLanguage(i18n.language)) !== -1,
  isEducationEnabled: () =>
    ['en'].indexOf(webUtils.determineLanguage(i18n.language)) !== -1,
};

export default utils;
