import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Link from '../../../components/link';
import classnames from 'classnames';
import storeUtils from '../../../utils/storeUtils';
import './CookieStatement.css';
import Container from '../container';
import _get from 'lodash/get';
import webUtils from '../../../utils/webUtils';
import {Helmet} from 'react-helmet';
import storyUtils from '../../../utils/storyUtils';

export default function CookieStatement({
  hideStatement = true,
  setHideStatement,
}) {
  const {i18n} = useTranslation();

  function hide() {
    storeUtils.setCookieStatement(true);
    setHideStatement(true);
  }

  if (webUtils.isInYmkApp() || storyUtils.isInFrame()) {
    return <div />;
  }

  return (
    <div
      className={classnames('pf-language pf-header-cookiestatement', {
        'pf-language-ja': i18n.language === 'ja',
        'pf-header-cookiestatement-hide': true,
      })}
    >
      {/*{_get(window, 'navigator.userAgent') !== 'ReactSnap' && (*/}
      {/*  <Helmet>*/}
      {/*    <script*/}
      {/*      src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"*/}
      {/*      data-document-language="true"*/}
      {/*      type="text/javascript"*/}
      {/*      charset="UTF-8"*/}
      {/*      data-domain-script={process.env.REACT_APP_COOKIE_PRO}*/}
      {/*    />*/}
      {/*    <script type="text/javascript">{`*/}
      {/*    function OptanonWrapper() {}*/}
      {/*  `}</script>*/}
      {/*  </Helmet>*/}
      {/*)}*/}
      <Container
        mobileType={true}
        className="pf-header-cookiestatement-container"
      >
        <div className="pf-header-cookiestatement-text">
          <Trans i18nKey="business:c.l.h.cookie.statement">
            By using the website, you agree to our use of cookies. Head to our{' '}
            <Link to="/business/privacy" target="_blank">
              cookie statement
            </Link>{' '}
            to learn more about cookies and manage cookies on this website.
          </Trans>
        </div>
        <button
          className="pf-header-cookiestatement-close"
          onClick={() => hide()}
        >
          <Trans i18nKey="business:c.l.h.cookie.statement.continue">
            CONTINUE
          </Trans>
        </button>
      </Container>
    </div>
  );
}
