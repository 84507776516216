import React from 'react';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import BeautyTechVirtualEvent from './../home/async';

export default [
  <DefaultBusinessLayout
    key="/business/beauty-tech-forum-master-class"
    exact
    path="/business/beauty-tech-forum-master-class/:courseKey?"
    component={props => <BeautyTechVirtualEvent {...props} />}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Master Class',
        item: '/business/beauty-tech-forum-master-class',
      },
    ]}
  />,
];
