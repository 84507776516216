import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function IosBtn() {
  const {i18n} = useTranslation();

  const langBtn = {
    de: require('../../../assets/images/consumer/appdownloadicons/btn_apple_deu.png'),
    en: require('../../../assets/images/consumer/appdownloadicons/btn_apple_en.svg'),
    es: require('../../../assets/images/consumer/appdownloadicons/btn_apple_es.png'),
    fr: require('../../../assets/images/consumer/appdownloadicons/btn_apple_fr.png'),
    ja: require('../../../assets/images/consumer/appdownloadicons/btn_apple_ja.png'),
    ko: require('../../../assets/images/consumer/appdownloadicons/btn_apple_kr.png'),
    pt: require('../../../assets/images/consumer/appdownloadicons/btn_apple_ptb.png'),
    it: require('../../../assets/images/consumer/appdownloadicons/btn_apple_ita.png'),
    zh_tw: require('../../../assets/images/consumer/appdownloadicons/btn_apple_ch.png'),
  };

  const [btn, setBtn] = useState(langBtn.en);

  useEffect(() => {
    if (
      i18n.language.toLowerCase() === 'zh-tw' ||
      i18n.language.toLowerCase() === 'zh_tw'
    ) {
      return setBtn(langBtn.zh_tw);
    }

    if (Object.keys(langBtn).includes(i18n.language)) {
      return setBtn(langBtn[i18n.language]);
    }
    setBtn(langBtn.en);
  }, [i18n.language]);

  return btn;
}
