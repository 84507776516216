import React from 'react';
import Link from '../../link';
import classnames from 'classnames';
import webUtils from '../../../utils/webUtils';
import {Trans, useTranslation} from 'react-i18next';
import '../../../assets/styles/fontawesome/css/fontawesome.css';
import './Menus.css';

export default function Menus({
  isBusiness,
  gtmClassNamePrefix = '',
  isNewHeader,
}) {
  const {i18n} = useTranslation();

  return (
    !webUtils.isMakeupArUrl() &&
    window.location.href.indexOf(
      '/consumer/blog/photo-editing/how-to-crop-a-picture'
    ) === -1 && (
      <div className="pf-header__menus">
        <Link
          to="/business"
          className={classnames({
            'pf-header__menu': true,
            'pf-header__menu--active': isBusiness,
            [`${gtmClassNamePrefix}_business_${webUtils.determineLanguage(
              i18n.language
            )}`]: true,
            v1: !isNewHeader,
          })}
          isBusiness={true}
        >
          <Trans i18nKey="business:c.l.h.menu.for.business">Business</Trans>{' '}
        </Link>
        <Link
          to="/consumer"
          className={classnames({
            'pf-header__menu': true,
            'pf-header__menu--active': !isBusiness,
            [`${gtmClassNamePrefix}_consumer_${webUtils.determineLanguage(
              i18n.language
            )}`]: true,
            v1: !isNewHeader,
          })}
          isBusiness={false}
        >
          <Trans i18nKey="business:c.l.h.menu.for.consumer">Consumer</Trans>{' '}
        </Link>
      </div>
    )
  );
}
