import React from 'react';
import AsyncPage from './async';
import {Route} from 'react-router-dom';

export default [
  // <Route
  //   key="/business/campaign/perfect-tone"
  //   exact
  //   path="/business/campaign/perfect-tone"
  //   component={AsyncPage}
  // />,
];
