import React, {useContext, useState} from 'react';
import './MobileMenus.css';
import MobileBusinessMenus from './MobileBusinessMenus';
import MobileConsumerMenus from './MobileConsumerMenus';
import PopupMenu2 from '../../content/popupmenu2/async';
import useWindowWidth from '../../hooks/useWindowWidth';
import MobileNFTMenus from './MobileNFTMenus';
import classnames from 'classnames';
import MobileGbtfEventMenus from '../../../pages/business/globalbeautytechforum/event/compoment/MobileGbtfEventMenus';

export default function MobileMenus({
  isBusiness,
  isScrolled,
  popupMenu,
  setPopupMenu,
  isNewHeader,
  isEvent,
  showTickets,
  utm_medium = '',
}) {
  const width = useWindowWidth();
  const isNft =
    window.location.pathname && window.location.pathname.startsWith('/nft');

  const mobileData = isEvent ? (
    <MobileGbtfEventMenus
      setPopupMenu={setPopupMenu}
      showTickets={showTickets}
      utm_medium={utm_medium}
    />
  ) : isNft ? (
    <MobileNFTMenus setPopupMenu={setPopupMenu} />
  ) : isBusiness ? (
    <MobileBusinessMenus setPopupMenu={setPopupMenu} />
  ) : (
    <MobileConsumerMenus setPopupMenu={setPopupMenu} />
  );

  return (
    <PopupMenu2
      component={mobileData}
      theme="white"
      show={popupMenu}
      setPopupMenu={setPopupMenu}
      version={isEvent && 'v2'}
    >
      <div
        className={classnames({
          'pf-header__mobile-menus-icon': true,
          'nft-header__mobile-menus-icon': isNft || isEvent,
        })}
      >
        <span className="helper" />
        <img
          alt=""
          src={
            isScrolled && isNewHeader
              ? require('../../../assets/images/menu_black.svg')
              : require('../../../assets/images/menu_white.svg')
          }
          width={width > 400 ? 31 : 25}
          height="22"
        />
      </div>
    </PopupMenu2>
  );
}
