import fetch from 'isomorphic-fetch';
import qs from 'query-string';

const dao = {
  queryAffiliate: async param => {
    const response = await fetch(
      `/smb-frontend/aff/query-active-aff.action` + dao.buildQs(param),
      {
        method: 'GET',
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  buildQs: (params = {}) => '?' + qs.stringify(params),
};

export default dao;
