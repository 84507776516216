import React from 'react';
import AsyncList from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
export default [
  <DefaultBusinessLayout
    key="/:type(business)/news/list"
    exact
    path="/:type(business)/news/list"
    component={AsyncList}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'News List',
        item: '/business/news/list',
      },
    ]}
  />,
];
