import React from 'react';
import AsyncPage from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import DownloadAsync from '../download/async';

export default [
  <DefaultBusinessLayout
    key="/business/color-trends/download"
    exact
    path="/business/color-trends/download"
    component={DownloadAsync}
  />,
  <DefaultBusinessLayout
    key="/business/color-trends/:id?"
    exact
    path="/business/color-trends/:id?"
    component={AsyncPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Color Trends',
        item: '/business/color-trends',
      },
    ]}
  />,
];
