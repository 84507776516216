import React from 'react';
import AsyncFaq from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default (
  <DefaultBusinessLayout
    key="/business/faq/:defaultType?"
    exact
    path="/business/faq/:defaultType(|account-payments|integration-of-youcam|subscription|trouble-shooting)?"
    component={AsyncFaq}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Faq',
        item: '/business/faq',
      },
    ]}
  />
);
