import React from 'react';
import AsyncHome from './async';
import DefaultBusinessLayout from '../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/business"
    exact
    path="/business"
    component={AsyncHome}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/business',
      },
    ]}
  />,
  <DefaultBusinessLayout
    key="/"
    exact
    path="/"
    component={AsyncHome}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/business',
      },
    ]}
  />,
];
