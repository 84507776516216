import React from 'react';
import AsyncDetail from '../../../business/news/detail/async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/:type(consumer)/news/detail/:id?"
    exact
    path="/:type(consumer)/news/detail/:id?"
    component={AsyncDetail}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'news_title',
        item: '/consumer/news/detail',
      },
    ]}
  />,
  <DefaultConsumerLayout
    key="/:type(consumer)/news/:newsHandle?"
    exact
    path="/:type(consumer)/news/:newsHandle?"
    component={AsyncDetail}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'news_title',
        item: '/consumer/news',
      },
    ]}
  />,
  <DefaultConsumerLayout
    key="/:type(consumer)/news/preview/:id?"
    exact
    path="/:type(consumer)/news/preview/:id?"
    component={AsyncDetail}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'news_title',
        item: '/consumer/news/preview',
      },
    ]}
  />,
];
