import ShowocaseLayout from '../../../../components/layout/ShowocaseLayout';
import AsyncPage from './async';
import React from 'react';

export default [
  <ShowocaseLayout
    key="/business/showcase/earrings"
    exact
    path="/business/showcase/earrings"
    component={AsyncPage}
  />,
];
