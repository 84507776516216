import React, {useEffect, useState} from 'react';
import {Route} from 'react-router';
import storeUtils from '../../utils/storeUtils';
import CookieStatement from './header/CookieStatement';
import Auth from '../auth';
import classnames from 'classnames';
import {useTranslation} from 'react-i18next';

export default ({component: Component, ...rest}) => {
  const {i18n} = useTranslation();
  const [hideStatement, setHideStatement] = useState(true);
  useEffect(() => {
    if (window.navigator.userAgent === 'ReactSnap') {
      return;
    }
    setHideStatement(storeUtils.getCookieStatement());
  }, []);
  return (
    <Route
      {...rest}
      render={matchProps => (
        <Auth>
          <div
            className={classnames({
              'pf-language': true,
              'pf-language-ja': i18n.language === 'ja',
            })}
          >
            <Component {...matchProps} />
            <CookieStatement
              hideStatement={!!hideStatement}
              setHideStatement={setHideStatement}
            />
          </div>
        </Auth>
      )}
    />
  );
};
