import webUtils from './webUtils';
import _toLower from 'lodash/toLower';
import _replace from 'lodash/replace';
import i18n from 'i18next';

const utils = {
  getParam: (paramName = 'id', params, location) => {
    if (params[paramName]) {
      return params[paramName];
    }
    return new URLSearchParams(location.search).get(paramName);
  },
  addLangPrefix: (url = '') => {
    const lang = webUtils.getPathnameLang(window.location.pathname);
    if (!lang || lang === 'en') {
      return url;
    }
    if (url.startsWith('http')) {
      return url;
    }
    return `/${_replace(_toLower(lang), '_', '-')}${url}`;
  },
  langToPathPrefix: () =>
    i18n.language === 'en' || !i18n.language
      ? ''
      : `/${i18n.language.replace('_', '-').toLowerCase()}`,
};

export default utils;
