import React from 'react';
import Loadable from 'react-loadable';

const Loader = () => {
  return <div className="text-center" />;
};

export default loader =>
  Loadable({
    loader: loader,
    loading: Loader,
  });
