import store from 'store';
import expirePlugin from 'store/plugins/expire';

store.addPlugin(expirePlugin);

const keys = {
  isAttemptingToSignIn: 'is-attempting-to-sign-in',
  cookieStatement: 'cookie-statement',
  unsupportedModal: 'un-supported-modal',
  currentLanguage: 'current-language',
  utmSourceFirst: 'utm-source-first',
  utmSourceLastTargetedAd: 'utm-source-last-targeted-ad',
  urlSourceFirst: 'url-source-first',
  urlSourceLast: 'url-source-last',
  nftFirstTry: 'nft-first-try',
  nftaccesscode: 'nft-access-code',
  nftwalletaddress: 'nft-wallet-address',
  promoType: 'promo-type',
  signupsubscribe: 'sign-up-subscribe',
  demostoreCampaignMode: 'demostore-campaign-mode',
};
const DAYS30 = 2592000000;

const utils = {
  setIsAttemptingToSignIn: value => store.set(keys.isAttemptingToSignIn, value),
  getIsAttemptingToSignIn: () => store.get(keys.isAttemptingToSignIn),
  setCookieStatement: value => store.set(keys.cookieStatement, value),
  getCookieStatement: () => store.get(keys.cookieStatement),
  setUnsupportedModal: value => store.set(keys.unsupportedModal, value),
  getUnsupportedModal: () => store.get(keys.unsupportedModal),
  setCurrentLanguage: value => store.set(keys.currentLanguage, value),
  getCurrentLanguage: () => store.get(keys.currentLanguage),
  setFirstUtmSource: value =>
    store.set(keys.utmSourceFirst, value, new Date().getTime() + DAYS30),
  getFirstUtmSource: () => store.get(keys.utmSourceFirst),
  setLastTargetedAdUtmSource: value =>
    store.set(
      keys.utmSourceLastTargetedAd,
      value,
      new Date().getTime() + DAYS30
    ),
  getLastTargetedAdUtmSource: () => store.get(keys.utmSourceLastTargetedAd),
  setFirstUrlSource: value =>
    store.set(keys.urlSourceFirst, value, new Date().getTime() + DAYS30),
  getFirstUrlSource: () => store.get(keys.urlSourceFirst),
  setLastUrlSource: value =>
    store.set(keys.urlSourceLast, value, new Date().getTime() + DAYS30),
  getLastUrlSource: () => store.get(keys.urlSourceLast),
  setNftFirstTry: value => store.set(keys.nftFirstTry, value),
  getNftFirstTry: value => store.get(keys.nftFirstTry, value),
  setNftAccessCode: value => store.set(keys.nftaccesscode, value),
  getNftAccessCode: value => {
    const curWalletAddr = window.ethereum
      ? window.ethereum.selectedAddress
      : window.web3
      ? window.web3.currentProvider.selectedAddress
      : null;
    if (!curWalletAddr) {
      return null;
    }
    const exWalletAddr = utils.getNftWalletAddress();
    if (!exWalletAddr || curWalletAddr != exWalletAddr) {
      console.log('Reset access code');
      utils.setNftAccessCode();
      return;
    }
    return store.get(keys.nftaccesscode, value);
  },
  setNftWalletAddress: value => store.set(keys.nftwalletaddress, value),
  getNftWalletAddress: () => store.get(keys.nftwalletaddress),
  setPromoType: value =>
    store.set(keys.promoType, value, new Date().getTime() + DAYS30),
  getPromoType: () => store.get(keys.promoType),
  removePromoType: () => localStorage.removeItem(keys.promoType),
  setSignupSubscribe: value => store.set(keys.signupsubscribe, value),
  getSignupSubscribe: () => store.get(keys.signupsubscribe),
  setDemostoreCampaignMode: value =>
    store.set(keys.demostoreCampaignMode, value),
  getDemostoreCampaignMode: () => store.get(keys.demostoreCampaignMode),
};

export default utils;
