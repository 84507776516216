import React from 'react';
import ScrollUpButton from 'react-scroll-up-button'; //Add this line Here
import './index.css';
import webUtils from '../../utils/webUtils';
import classnames from 'classnames';
import useWindowWidth from '../hooks/useWindowWidth';

export default function GoTopButton() {
  const isDemostore = window.location.href.indexOf('/business/showcase') !== -1;
  const width = useWindowWidth();

  return (
    <div>
      <ScrollUpButton
        ContainerClassName={classnames({
          'gotopbutton-for-container': true,
          'gotopbutton-for-container-cn-desktop':
            width > 1024 && webUtils.isCnDomain(),
        })}
        TransitionClassName="gotopbutton-for-transition"
        ShowAtPosition={window.innerHeight * 0.5}
        StopPosition={isDemostore && webUtils.isMobile() ? 104 : 0}
      >
        <div>
          <div className="go-top-btn" />
        </div>
      </ScrollUpButton>
    </div>
  );
}
