import React from 'react';
import Link from '../../../components/link';
import './Logo2.css';
import Picture from '../../picture';

export default function Logo({isBusiness}) {
    return (
        <div className="pf-header__logo-ppc">
            <span className="helper" />
            <Link to="/business">
                {/*<Picture*/}
                {/*    alt="logo"*/}
                {/*    platform="desktop"*/}
                {/*    filename="logo_pf-wyzzm8nmo4.png"*/}
                {/*    small={true}*/}
                {/*/>*/}
                <img src={require('../../../assets/images/adlanding/logo.svg')} alt="perfect"/>
            </Link>
        </div>
    );
}
