import React, {useState} from 'react';
import Link from '../../../components/link';
import classnames from 'classnames';
import appLinks from '../../../utils/config/appLinks';
import webUtils from '../../../utils/webUtils';
import useReactRouter from 'use-react-router';
import './MobileConsumerMenus.css';
import {useHistory} from 'react-router';
import {Trans, useTranslation} from 'react-i18next';
import GoogleSearch from '../googlesearch';
import useWindowWidth from '../../hooks/useWindowWidth';
import headerUtils from '../../../utils/headerUtils';
import _isEmpty from 'lodash/isEmpty';
import ConsumerMenuList from '../subheader4consumer/menuList';

export default function MobileConsumerMenus({setPopupMenu}) {
  const {i18n} = useTranslation();
  const {location} = useReactRouter();
  const history = useHistory();
  const language = webUtils.getLangPath(i18n.language);
  const consumerApps = headerUtils.consumerApps;
  const aiphotoeditingtool = headerUtils.aiphotoeditingtool.filter(r => r);
  const genai = headerUtils.genai;

  return (
    <div className="pf-header-mobile-consumer-menus">
      <div
        className={classnames({
          'pf-header-mobile-consumer-menus__item': true,
        })}
      >
        <GoogleSearch is2b={false} />
      </div>
      <ConsumerMenuList
        menu={consumerApps}
        text={<Trans i18nKey="consumer:u.c.applinks.home">Our Apps</Trans>}
        setPopupMenu={setPopupMenu}
      />
      <ConsumerMenuList
        activeLink={'none'}
        menu={aiphotoeditingtool}
        text={
          <Trans i18nKey="consumer:u.c.leftmenu.ai.photo.editing.tool">
            AI Photo Editing Tool
          </Trans>
        }
        setPopupMenu={setPopupMenu}
      />
      <ConsumerMenuList
        activeLink={'none'}
        menu={genai}
        text={
          <Trans i18nKey="consumer:u.c.leftmenu.generative.ai">
            Generative AI
          </Trans>
        }
        setPopupMenu={setPopupMenu}
      />
      <ConsumerMenuList
        activeLink={'none'}
        menu={headerUtils.faceai}
        text={<Trans i18nKey="consumer:u.c.leftmenu.face.ai">Face AI</Trans>}
        setPopupMenu={setPopupMenu}
      />
      <div
        className={classnames({
          'pf-header-mobile-consumer-menus__item': true,
        })}
        onClick={() => {
          history.push(`${language}/consumer/blog`);
          setPopupMenu(false);
        }}
      >
        <div
          className={classnames({
            'pf-subheader-4-consumer__left-menu--active':
              location.pathname.indexOf('/consumer/blog') !== -1,
            'pf-content-popupmenu1 pf-content-popupmenu1--white': true,
          })}
          style={
            location.pathname.indexOf('/consumer/blog') !== -1
              ? {}
              : {color: '#ffffff'}
          }
        >
          <Trans i18nKey="consumer:u.c.blog">Blog</Trans>
        </div>
      </div>
      <div
        className={classnames({
          'pf-header-mobile-consumer-menus__item': true,
        })}
        onClick={() => {
          window.location.href = 'https://ir.perfectcorp.com/';
        }}
      >
        <div
          className={classnames({
            'pf-content-popupmenu1 pf-content-popupmenu1--white': true,
          })}
          style={{color: '#ffffff'}}
        >
          <Trans i18nKey="business:c.l.s.rightmenu.investors">Investors</Trans>
        </div>
      </div>
    </div>
  );
}
