import storeUtils from './storeUtils';

const utils = {
  isUtmEmpty: params => !params || !params.utm_source || !params.utm_medium,
  updateFirst: params => {
    if (utils.isUtmEmpty(params)) {
      return;
    }
    const utm = storeUtils.getFirstUtmSource();
    if (utm) {
      return;
    }
    storeUtils.setFirstUtmSource(params);
  },
  updateLastTargetedAd: params => {
    if (utils.isUtmEmpty(params)) {
      return;
    }
    if (params.utm_medium !== 'cpc' && params.utm_medium !== 'display') {
      return;
    }
    storeUtils.setLastTargetedAdUtmSource(params);
  },
  getSupportSourceUtm: () => {
    const sourceUtm = {};
    const firstUtm = storeUtils.getFirstUtmSource();
    const lastAdUtm = storeUtils.getLastTargetedAdUtmSource();
    if (firstUtm) {
      sourceUtm['FIRST_IN_30_DAYS'] = {
        utmSource: firstUtm.utm_source,
        utmMedium: firstUtm.utm_medium,
        utmCampaign: firstUtm.utm_campaign,
        utmContent: firstUtm.utm_content,
        utmId: firstUtm.utm_id,
        utmTerm: firstUtm.utm_term,
      };
    }
    if (lastAdUtm) {
      sourceUtm['LAST_ADS_IN_30_DAYS'] = {
        utmSource: lastAdUtm.utm_source,
        utmMedium: lastAdUtm.utm_medium,
        utmCampaign: lastAdUtm.utm_campaign,
        utmContent: lastAdUtm.utm_content,
        utmId: lastAdUtm.utm_id,
        utmTerm: lastAdUtm.utm_term,
      };
    }
    return sourceUtm;
  },
  isUnqualifiedLastUrl: (url) => {
    if (!url) {
      return true;
    }
    const blacklist = ['/business/contact-us', '/business/contact-us/sales', '/business/contact-sales'];
    for (let i = 0; i < blacklist.length; i++) {
      if (url.indexOf(blacklist[i]) !== -1) {
        return true;
      }
    }
    return false;
  },
  updateLandingUrl: (url) => {
    if (storeUtils.getFirstUrlSource()) {
      return;
    }
    storeUtils.setFirstUrlSource(url);
  },
  updateLastUrlBeforeContactUs: (url) => {
    if (utils.isUnqualifiedLastUrl(url)) {
      return;
    }
    storeUtils.setLastUrlSource(url);
  },
};

export default utils;
