import React from 'react';
import AsyncEnterprise from './async';
import {Route} from 'react-router-dom';

export default [
  <Route
    key="/business/solutions/enterprise"
    exact
    path="/business/solutions/enterprise"
    component={AsyncEnterprise}
    isEnterprise={true}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Enterprise',
        item: '/business/solutions/enterprise',
      },
    ]}
  />,
];
