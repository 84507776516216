import React from 'react';
import AsyncPage from './async';
import ShowocaseLayout from '../../../../../components/layout/ShowocaseLayout';

export default [
  <ShowocaseLayout
    key="/business/showcase/shadefinder/finder"
    exact
    path="/business/showcase/shadefinder/finder"
    component={AsyncPage}
  />,
];
