import React from 'react';
import AsyncPage from './async';
import ShowocaseLayout from '../../../../../components/layout/ShowocaseLayout';
import {Redirect} from 'react-router';

export default [
  <Redirect
    key="/business/showcase/emulation/"
    exact
    from="/business/showcase/emulation/"
    to="/business/showcase/skincare/emulation"
  />,
  <Redirect
    key="/business/showcase/emulation/home"
    exact
    from="/business/showcase/emulation/home"
    to="/business/showcase/skincare/emulation"
  />,
  <ShowocaseLayout
    key="/business/showcase/skincare/emulation"
    exact
    path="/business/showcase/skincare/emulation"
    component={AsyncPage}
  />,
];
