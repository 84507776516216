import qs from 'query-string';
import {Trans} from 'react-i18next';
import React from 'react';

const utils = {
  initSalesForm: support => ({
    supportType: 'SALES',
    firstName: support.firstName,
    lastName: support.lastName,
    businessName: support.businessName,
    productType: 'P12_COLOR_REPORT',
    email: support.email,
    numberOfEmployees: support.numberOfEmployees,
    country: support.country,
    state: support.state ? support.state : 'Alabama',
    description: '',
  }),
  getPrefill: () => {
    const search = qs.parse(window.location.search);
    const prefill = {
      event:
        "I would like to schedule a meeting with you during [please provide the event's name].",
      CES2022:
        'I would like to schedule a meeting with you during the CES2022 event.',
      NRF2022:
        'I would like to schedule a meeting with you during the NRF2022 event.',
      SXSW2022:
        'I would like to schedule a meeting with you during the SXSW2022 event.',
      PB2022:
        'I would like to schedule a meeting with you during the Personalized Beauty 2022 event.',
      Shoptalk:
        'I would like to schedule a meeting with you during the Shoptalk event.',
      VEE2022:
        'I would like to schedule a meeting with you during the Vision Expo East 2022 event.',
      SCSNYC:
        'I would like to schedule a meeting with you during the Sustainable Cosmetics Summit NYC event.',
      JCLA2022:
        'I would like to schedule a meeting with you during the Jewelry Conference, Las Vegas 2022 event.',
      SCSEU:
        'I would like to schedule a meeting with you during the Sustainable Cosmetic Summit Europe event.',
      DubaiDerma:
        'I would like to schedule a meeting with you during Dubai Derma.',
      DubaiDerma_Onsite: 'Hi, we met at Dubai Derma 2023.',
      CES2024:
        'I would like to schedule a meeting with you during the CES2024 event.',
    };
    const prefillByLanguage = {
      ENU_Contact:
        'Please tell us about your company and the technology solution that interests you. Our sales team will be in touch soon with a detailed pricing plan.',
      FRA_Contact:
        'Parlez-nous de votre entreprise et de la solution technologique qui vous intéresse. Notre équipe commerciale vous contactera prochainement avec un plan tarifaire détaillé.',
      CHS_Contact:
        '请详细介绍您的公司背景及业务类型，以及您最感兴趣的玩美产品是什么？我们讲尽快提供最适合您的定价方案。',
      JPN_Contact:
        '具体的問い合わせ内容をご記入ください。（例）料金や機能について詳細を知りたい。対応可能な商品カテゴリーを知りたい。等',
      ESP_Contact:
        'Por favor, háblanos de tu empresa y de la solución tecnológica que te interesa. Nuestro equipo de ventas se pondrá en contacto lo antes posible para mas detalles.',
    };

    return search.prefill && prefill[search.prefill]
      ? `${prefill[search.prefill]}`
      : prefillByLanguage[search.prefill] && prefillByLanguage[search.prefill];
  },
  convertEmployee: value => {
    switch (value) {
      case 'C050':
        return 'R_1_50';
      case 'C100':
        return 'R_51_100';
      case 'C200':
        return 'R_101_200';
      case 'C300':
        return 'R_201_300';
      case 'C301500':
        return 'R_301_500';
      case 'C999':
        return 'R_501_999';
      default:
        return 'R_1_50';
    }
  },
  employeeMore200: value => {
    switch (value) {
      case 'C050':
        return false;
      case 'C100':
        return false;
      case 'C200':
        return false;
      case 'C300':
        return true;
      case 'C301500':
        return true;
      case 'C999':
        return true;
      default:
        return true;
    }
  },
};

export default utils;
