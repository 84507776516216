import {Trans} from 'react-i18next';
import React from 'react';

const configs = {
  business: [
    {
      code: 'en',
      i18nKey: 'en',
      text: <Trans i18nKey="business:c.l.h.language.en">English</Trans>,
    },
    {
      code: 'zh_TW',
      i18nKey: 'zh-TW',
      text: <Trans i18nKey="business:c.l.h.language.zh_TW">正體中文</Trans>,
    },
    {
      code: 'zh_CN',
      i18nKey: 'zh-CN',
      text: <Trans i18nKey="business:c.l.h.language.zh_CN">简体中文</Trans>,
    },
    {
      code: 'ja',
      i18nKey: 'ja',
      text: <Trans i18nKey="business:c.l.h.language.ja">日本語</Trans>,
    },
    {
      code: 'fr',
      i18nKey: 'fr',
      text: <Trans i18nKey="business:c.l.h.language.fr">Français</Trans>,
    },
    {
      code: 'ko',
      i18nKey: 'ko',
      text: <Trans i18nKey="business:c.l.h.language.ko">한국어</Trans>,
    },
    {
      code: 'de',
      i18nKey: 'de',
      text: <Trans i18nKey="business:c.l.h.language.de">Deutsch</Trans>,
    },
    {
      code: 'es',
      i18nKey: 'es',
      text: <Trans i18nKey="business:c.l.h.language.es">Español</Trans>,
    },
    {
      code: 'it',
      i18nKey: 'it',
      text: <Trans i18nKey="business:c.l.h.language.it">Italian</Trans>,
    },
  ],
  consumer: [
    {
      code: 'en',
      i18nKey: 'en',
      text: <Trans i18nKey="business:c.l.h.language.en">English</Trans>,
    },
    {
      code: 'zh_TW',
      i18nKey: 'zh-TW',
      text: <Trans i18nKey="business:c.l.h.language.zh_TW">正體中文</Trans>,
    },
    // {
    //   code: 'zh_CN',
    //   i18nKey: 'zh-CN',
    //   text: <Trans i18nKey="business:c.l.h.language.zh_CN">简体中文</Trans>,
    // },
    {
      code: 'ja',
      i18nKey: 'ja',
      text: <Trans i18nKey="business:c.l.h.language.ja">日本語</Trans>,
    },
    // {
    //   code: 'ko',
    //   i18nKey: 'ko',
    //   text: <Trans i18nKey="business:c.l.h.language.ko">한국어</Trans>,
    // },
    {
      code: 'de',
      i18nKey: 'de',
      text: <Trans i18nKey="business:c.l.h.language.de">Deutsch</Trans>,
    },
    {
      code: 'fr',
      i18nKey: 'fr',
      text: <Trans i18nKey="business:c.l.h.language.fr">Français</Trans>,
    },
    {
      code: 'es',
      i18nKey: 'es',
      text: <Trans i18nKey="business:c.l.h.language.es">Español</Trans>,
    },
    {
      code: 'pt',
      i18nKey: 'pt',
      text: <Trans i18nKey="business:c.l.h.language.pt">Português</Trans>,
    },
    {
      code: 'it',
      i18nKey: 'it',
      text: <Trans i18nKey="business:c.l.h.language.it">Italian</Trans>,
    },
  ],
};

export default configs;
