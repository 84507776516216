import Link from '../../link';
import classnames from 'classnames';
import React, {useContext, useEffect, useState} from 'react';
import './index.css';
import useWindowScroll from '../../hooks/useWindowScroll';
import {AuthContext} from '../../auth';
import webUtils from '../../../utils/webUtils';

export default function GotoFreeTrial() {
  const scrollTop = useWindowScroll();
  const [isScroll, setIsScroll] = useState(false);
  const {isFirstLoaded, accountSummary} = useContext(AuthContext);
  const isSignIn = isFirstLoaded && accountSummary;
  const functionType = webUtils.getOnlineServiceLink().functionType;
  const solutionType = webUtils.getOnlineServiceLink().solutionType;
  useEffect(() => {
    if (scrollTop > 0 && !isScroll) {
      setIsScroll(true);
    }
  }, [scrollTop]);

  return (
    <div className="pf-language" style={{position: 'relative'}}>
      <Link
        className={classnames({
          'go-free-trial-btn--container': true,
          'go-free-trial-btn--container--active': isScroll,
          'go-free-trial-btn--container--hide': isSignIn,
          'go-free-trial-7days-btn--container': functionType === 'SKINCARE_PRO',
        })}
        to={webUtils.goToFreeTrial(functionType, solutionType)}
        style={{
          bottom: functionType === 'SKINCARE_PRO' ? '150px' : '220px',
        }}
      >
        <div className="go-free-trial-text">
          <div>FREE</div>
        </div>
      </Link>
    </div>
  );
}
