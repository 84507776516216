import React from 'react';
import AsyncPage from './async';
import ShowocaseLayout from '../../../../components/layout/ShowocaseLayout';
import {Redirect} from 'react-router';
import {makeuproutes} from '../../../../utils/demostoreUtils';

const MAKEUP = makeuproutes.reduce((a, b) => (a ? `${a}|${b}` : b), '');
const ACCESSORIES = 'hat|headband';
const OTHERS = 'eye-wear|hair-color';

export default [
  <Redirect
    key="/business/showcase/"
    exact
    from="/business/showcase/"
    to="/business/showcase/lip-color"
  />,
  <ShowocaseLayout
    key="/business/showcase/producttype"
    exact
    path={`/business/showcase/:productType(${MAKEUP}|${ACCESSORIES}|${OTHERS})`}
    component={AsyncPage}
  />,
];
