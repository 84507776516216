import React from 'react';
import './Header2Button.css';
import PFLink from '../../link';

export default ({link, text, style, hash}) => {
  return (
    <div className="pf-header__btn-ppc" style={{display: 'table'}}>
      <div style={{display: 'table-cell', verticalAlign: 'middle'}}>
        <PFLink
          to={link}
          className="pf-btn__trial gtm_campaignlp-smb_header_start-free-trial"
          isBusiness={true}
          style={style}
          hash={hash}
        >
          <div className="pf-btn__trial-text">{text}</div>
        </PFLink>
      </div>
    </div>
  );
};
