import fetch from 'isomorphic-fetch';
import qs from 'query-string';

const seoDao = {
  buildQs: (params = {}) => '?' + qs.stringify(params),
  querySEO: async (params = {}) => {
    const url = `/smb-frontend/seo/query-meta.action` + seoDao.buildQs(params);
    if (window.snapStore && window.snapStore[url]) return window.snapStore[url];
    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      return response.json();
    } catch (err) {
      return Promise.reject();
    }
  },
  querySEOByPath: async (params = {}) => {
    const url =
      `/smb-frontend/seo/query-meta-by-path.action` + seoDao.buildQs(params);
    if (window.snapStore && window.snapStore[url]) return window.snapStore[url];
    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      return response.json();
    } catch (err) {
      return Promise.reject();
    }
  },
};

export default seoDao;
