import business from './business/routes';
import consumer from './consumer/routes';
import notfound from './notfound/routes';
import _flattenDeep from 'lodash/flattenDeep';

export default lang => {
  const en = _flattenDeep([business, consumer]).map(route => ({
    ...route,
    props: {
      ...route.props,
      path: route.props.path,
    },
  }));

  const all = _flattenDeep([business, consumer]).map(route => ({
    ...route,
    props: {
      ...route.props,
      path:
        route.props.path &&
        `/:lang(ja|zh-tw|zh-cn|fr|de|es|ko|pt|it)${route.props.path}`,
      from:
        route.props.from &&
        `/:lang(ja|zh-tw|zh-cn|fr|de|es|ko|pt|it)${route.props.from}`,
      to:
        route.props.to &&
        `/:lang(ja|zh-tw|zh-cn|fr|de|es|ko|pt|it)${route.props.to}`,
    },
  }));

  const n = _flattenDeep([notfound]).map(route => ({
    ...route,
    props: {
      ...route.props,
    },
  }));
  return [en, all, n];
};
