import React from 'react';
import ShowocaseLayout from '../../../../../components/layout/ShowocaseLayout';
import AsyncPage from './async';

export default [
  <ShowocaseLayout
    key="/business/showcase/3d-viewer/:type(ring|watch)?"
    exact
    path="/business/showcase/3d-viewer/:type(ring|watch)?"
    component={AsyncPage}
  />,
];
