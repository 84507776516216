import React, {useState, useEffect} from 'react';
import _isEmpty from 'lodash/isEmpty';
import VideoSquareButton from '../../button/videosquarebutton';
import {Trans, useTranslation} from 'react-i18next';
import PinkButton from '../../button/pinkbutton';
import webUtils from '../../../utils/webUtils';
import Modal from 'react-bootstrap/Modal';
import VideoPlayer from '../../videoplayer';
import useWindowWidth from '../../hooks/useWindowWidth';
import './index.css';
import usePageScrollController from '../../../pages/business/showcase/components/hooks/useForceControlPageScroll';
import QRCode from 'qrcode.react';

export default function ProductBannerButton({
  buttonConfig,
  theme,
  gtmClassNamePrefix,
  extraButtons,
}) {
  const [qrLink, setQrLink] = useState(false);
  const {i18n} = useTranslation();
  const [show, setShow] = useState(false);
  const hideModel = () => setShow(false);
  const width = useWindowWidth();
  const isMobile = width <= 600;
  const [videoUrl, setVideoUrl] = useState('');
  const {
    isAllowed,
    disablePageScroll,
    enablePageScroll,
  } = usePageScrollController();

  useEffect(() => {
    if (!isAllowed()) {
      return;
    }
    show ? disablePageScroll() : enablePageScroll();
  }, [show]);
  const button = (s, index) => {
    if (s.style === 'whitevideo') {
      return (
        <PinkButton
          isNewTab={s.newTab}
          className="pf-product__banner--btn-style"
          func={() => {
            setVideoUrl(s.videoUrl);
            setShow(true);
          }}
          text={
            s.text || (
              <Trans i18nKey="business:c.c.header1.watch.intro.video">
                Watch Intro Video
              </Trans>
            )
          }
          href={s.href}
          gtm={`${s.gtm}_${webUtils.determineLanguage(i18n.language)}`}
          style={{
            margin:
              index === 0 && !isMobile && buttonConfig.length > 1
                ? '0 15px 0 0'
                : isMobile && buttonConfig.length > 1 && '0 auto 15px',
          }}
          square={true}
          bgColor={'white'}
        />
      );
    }
    if (s.style === 'video') {
      return (
        <VideoSquareButton
          className="pf-product__banner--btn-style-video"
          dark={theme === 'dark' || isMobile}
          func={() => {
            setVideoUrl(s.videoUrl);
            setShow(true);
          }}
          text={
            s.text || (
              <Trans i18nKey="business:c.c.header1.watch.intro.video">
                Watch Intro Video
              </Trans>
            )
          }
          gtmClassNamePrefix={`${s.gtm}_top`}
          style={{
            margin:
              index === 0 && !isMobile && buttonConfig.length > 1
                ? '0 15px 0 0'
                : isMobile && buttonConfig.length > 1 && '0 auto 15px',
          }}
        />
      );
    }
    if (!s.text) return;
    if (s.buttonType === 'Image with QRcode') {
      return (
        <div className="text-center">
          <div
            onMouseOver={() => {
              setQrLink(true);
            }}
            onMouseOut={() => {
              setQrLink(false);
            }}
          >
            <PinkButton
              isNewTab={s.newTab}
              className="pf-product__banner--btn-style"
              hash={s.hash}
              link={s.link}
              href={s.href}
              text={s.text}
              style={{
                margin: isMobile && buttonConfig.length > 1 && '0 auto 15px',
              }}
              square={true}
              gtm={
                s.gtm
                  ? `${s.gtm}_top_${webUtils.determineLanguage(i18n.language)}`
                  : `${gtmClassNamePrefix}_top_${webUtils.determineLanguage(
                      i18n.language
                    )}`
              }
              bgColor={s.style}
            />
            {qrLink && width > 1024 && (
              <div className={'qr-code-block'}>
                <QRCode
                  size={100}
                  bgColor={'white'}
                  value={buttonConfig[1].link}
                />
              </div>
            )}
          </div>
        </div>
      );
    }
    return (
      <PinkButton
        isNewTab={s.newTab}
        className="pf-product__banner--btn-style"
        hash={s.hash}
        link={s.link}
        href={s.href}
        text={s.text}
        style={{
          margin: isMobile && buttonConfig.length > 1 && '0 auto 15px',
        }}
        square={true}
        gtm={
          s.gtm
            ? `${s.gtm}_top_${webUtils.determineLanguage(i18n.language)}`
            : `${gtmClassNamePrefix}_top_${webUtils.determineLanguage(
                i18n.language
              )}`
        }
        bgColor={s.style}
      />
    );
  };

  return (
    <div>
      {!_isEmpty(buttonConfig) && (
        <div className="pf-product__banner--btn">
          {buttonConfig
            .filter(s => s.text)
            .map((s, index) => (
              <div key={`product-banner-button${index}`}>
                {button(s, index)}
              </div>
            ))}
        </div>
      )}
      {extraButtons}
      <Modal size="lg" show={show} onHide={hideModel}>
        <Modal.Body>
          <VideoPlayer
            videoUrl={videoUrl}
            autoPlay={true}
            fullscreen={isMobile}
            onCloseFullscreen={hideModel}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
