import React from 'react';
import webUtils from '../../../utils/webUtils';
import './index.css';
import {useTranslation} from 'react-i18next';

export default function VideoSquareButton({
  text,
  dark = false,
  func,
  style,
  gtmClassNamePrefix = '',
  className,
}) {
  const {i18n} = useTranslation();

  return (
    <a
      className={`${className} pf-video-square-white-button ${gtmClassNamePrefix}_${webUtils.determineLanguage(
        i18n.language
      )} ${dark && 'pf-video-square-dark-button'}`}
      onClick={func}
      style={style}
    >
      <div>{text}</div>
      <div>
        <img
          alt="play"
          src={
            'https://plugins-media.makeupar.com/smb/story/2022-01-04/93e24de4-c912-42cc-ac29-1df1f299104f.png'
          }
        />
      </div>
    </a>
  );
}
