import React from 'react';
import Link from '../../../components/link';
import webUtils from '../../../utils/webUtils';
import {useTranslation} from 'react-i18next';

export default function AppIconList({appList}) {
  const isMobile = webUtils.isMobile();
  const {t} = useTranslation();
  return (
    <div className="pf-consumer-home-app-icons" style={{maxWidth: '556px'}}>
      {appList.map((app, i) => (
        <div key={`app-download-${i}`}>
          <Link
            to={app.link}
            className={`gtm_2c-home_banner_${app.name}`}
            isNewTab={app.newTab}
          >
            <img
              className="pf-consumer-home-app-icons__icon"
              style={{margin: '0 auto'}}
              width={isMobile ? '69px' : '66px'}
              height={isMobile ? '69px' : '66px'}
              src={app.icon}
              alt=""
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '6px',
                justifyContent: 'center',
              }}
            >
              <div className="pf-consumer-home-app-icons__name">
                {t(app.text)}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
}
