import classnames from 'classnames';
import React, {useState, useRef} from 'react';
import {useHistory} from 'react-router';
import {useTranslation} from 'react-i18next';
import './index.css';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function GoogleSearch({isScrolled, is2b, isNewHeader}) {
  const ref = useRef('null');
  const domain = is2b ? 'business' : 'consumer';
  const width = useWindowWidth();
  const {i18n, t} = useTranslation();
  const [isSearch, setIsSearch] = useState(false);
  const history = useHistory();
  const [isClick, setIsClick] = useState(false);
  const [key, setKey] = useState('');
  const language =
    i18n.language === 'en' || !i18n.language
      ? ''
      : `/${i18n.language.replace('_', '-').toLowerCase()}`;

  if (width <= 600)
    return (
      <>
        <img
          className="pf-header-mobile-search--icon"
          alt=""
          src={require('../../../assets/images/icon/icon_search.svg')}
          onClick={() => {
            if (key)
              window.location.href = `${language}/${domain}/gcse?q=${key}`;
          }}
        />
        <input
          className="pf-header-mobile-search"
          type="text"
          name="search"
          placeholder={t('business:c.l.g.enter.keywords', 'Enter keywords')}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              window.location.href = `${language}/${domain}/gcse?q=${
                e.target.value
              }`;
            }
            setKey(e.target.value);
          }}
        />
      </>
    );

  return (
    <div className="pf-header__search">
      <img
        alt=""
        src={
          isSearch || (isScrolled && isNewHeader)
            ? require('../../../assets/images/icon/active-search.svg')
            : require('../../../assets/images/icon/icon_search.svg')
        }
        onClick={e => {
          e.stopPropagation();
          if (key && isSearch) {
            window.location.href = `${language}/${domain}/gcse?q=${key}`;
          } else if (!isSearch) {
            setIsSearch(true);
            setIsClick(true);
            setTimeout(() => {
              ref.current.focus();
            }, 400);
          } else {
            setIsSearch(false);
          }
        }}
      />
      <input
        ref={ref}
        className={classnames({
          'pf-header__search-active': isSearch,
          'pf-header__search-inactive': !isSearch && isClick,
        })}
        style={{backgroundColor: isScrolled && '#f2f2f2'}}
        type="text"
        name="search"
        placeholder={t('business:c.l.g.enter.keywords', 'Enter keywords')}
        onKeyUp={e => {
          setIsClick(true);
          if (e.key === 'Enter') {
            history.push(`${language}/${domain}/gcse?q=${e.target.value}`);
          }
          setKey(e.target.value);
        }}
      />
    </div>
  );
}
