import React from 'react';
import {Trans} from 'react-i18next';

export default {
  BUSINESS_SERVICES: {
    BS_WEB_MODULE: {
      id: 2,
      value: 'BS_WEB_MODULE',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.web.module">Web Module/SDK</Trans>
      ),
    },
    BS_MOBILE_SDK: {
      id: 3,
      value: 'BS_MOBILE_SDK',
      label: <Trans i18nKey="business:u.t.b.f.bs.sdk">Mobile App SDK</Trans>,
    },
    BS_INSTORE_CONSULTATION: {
      id: 4,
      value: 'BS_INSTORE_CONSULTATION',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.instore.consultation">
          In-Store Consultation
        </Trans>
      ),
    },
    BS_TAOBAO_MINI_PROGRAM: {
      id: 5,
      value: 'BS_TAOBAO_MINI_PROGRAM',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.taobao">Taobao Mini-program</Trans>
      ),
    },
    BS_WECHAT_MINI_PROGRAM: {
      id: 6,
      value: 'BS_WECHAT_MINI_PROGRAM',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.wechat">WeChat Mini-program</Trans>
      ),
    },
    BS_AR_SHOPIFY: {
      id: 7,
      value: 'BS_AR_SHOPIFY',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.shopify">
          AR for ShopifyBusiness focus
        </Trans>
      ),
    },
    BS_AR_LIVE_TRAINING: {
      id: 8,
      value: 'BS_AR_LIVE_TRAINING',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.live.training">
          AR Live Training
        </Trans>
      ),
    },
    BS_AR_LIVE_CASTING: {
      id: 9,
      value: 'BS_AR_LIVE_CASTING',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.live.casting">
          AR Live Casting
        </Trans>
      ),
    },
    BS_BA_1ON1: {
      id: 10,
      value: 'BS_BA_1ON1',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.ba.1on1">
          Beauty Advisor 1-on-1
        </Trans>
      ),
    },
    BS_AR_SNAPCHAT: {
      id: 11,
      value: 'BS_AR_SNAPCHAT',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.ar.snapchat">
          Virtual Try-on AR on Snapchat
        </Trans>
      ),
    },
    BS_AR_YOUTUBE: {
      id: 12,
      value: 'BS_AR_YOUTUBE',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.youtube">
          Virtual Try-on AR on YouTube
        </Trans>
      ),
    },
    BS_AR_GOOGLE_SEARCH: {
      id: 13,
      value: 'BS_AR_GOOGLE_SEARCH',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.google.search">
          Virtual Try-on AR on Google Search
        </Trans>
      ),
    },
    BS_DOUYIN_MINI_PROGRAM: {
      id: 14,
      value: 'BS_DOUYIN_MINI_PROGRAM',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.douyin">Douyin Mini-program</Trans>
      ),
    },
    BS_YMK_APP_LISTING: {
      id: 15,
      value: 'BS_YMK_APP_LISTING',
      label: (
        <Trans i18nKey="business:u.t.b.f.bs.app.listing">
          YouCam Makeup App Listing
        </Trans>
      ),
    },
  },
  BUSINESS_TOPICS: {
    BT_AI_AR_MAKEUP: {
      id: 16,
      value: 'BT_AI_AR_MAKEUP',
      label: (
        <Trans i18nKey="business:u.t.b.f.bt.virtual.makeup">AI/AR Makeup</Trans>
      ),
    },
    BT_AI_SKINCARE: {
      id: 17,
      value: 'BT_AI_SKINCARE',
      label: <Trans i18nKey="business:u.t.b.f.bt.skincare">AI Skincare</Trans>,
    },
    BT_AI_SHADE_FINDER: {
      id: 18,
      value: 'BT_AI_SHADE_FINDER',
      label: (
        <Trans i18nKey="business:u.t.b.f.bt.shade.finder">
          AI Shade Finder
        </Trans>
      ),
    },
    BT_AI_AR_HAIR: {
      id: 19,
      value: 'BT_AI_AR_HAIR',
      label: <Trans i18nKey="business:u.t.b.f.bt.hair">AI/AR Hair</Trans>,
    },
    BT_PARTNER_SUCCESS: {
      id: 20,
      value: 'BT_PARTNER_SUCCESS',
      label: (
        <Trans i18nKey="business:u.t.b.f.bt.partner.success">
          Partner Success
        </Trans>
      ),
    },
    BT_RETAIL: {
      id: 21,
      value: 'BT_RETAIL',
      label: <Trans i18nKey="business:u.t.b.f.bt.retail">Retail</Trans>,
    },
    BT_ECOMMERCE: {
      id: 22,
      value: 'BT_ECOMMERCE',
      label: <Trans i18nKey="business:u.t.b.f.bt.ecommerce">E-Commerce</Trans>,
    },
    BT_FACE_TECH: {
      id: 23,
      value: 'BT_FACE_TECH',
      label: (
        <Trans i18nKey="business:u.t.b.f.bt.face.tech">Face Technology</Trans>
      ),
    },
    BT_AR_VIDEO_ON_DEMAND: {
      id: 24,
      value: 'BT_AR_VIDEO_ON_DEMAND',
      label: (
        <Trans i18nKey="business:u.t.b.f.bt.ar.video">AR Video On-Demand</Trans>
      ),
    },
    BT_PLATFORM_SUPPORT: {
      id: 25,
      value: 'BT_PLATFORM_SUPPORT',
      label: (
        <Trans i18nKey="business:u.t.b.f.bt.platform.support">
          Platform Support
        </Trans>
      ),
    },
    BT_AR_ACCESSORIES: {
      id: 26,
      value: 'BT_AR_ACCESSORIES',
      label: (
        <Trans i18nKey="business:u.t.b.f.bt.ar.accessories">
          AR Accessories
        </Trans>
      ),
    },
  },
  CONSUMER_APPS: {
    CA_YMK: {
      id: 27,
      value: 'CA_YMK',
      label: <Trans i18nKey="consumer:u.t.b.f.ca.ymk">YouCam Makeup</Trans>,
    },
    CA_YCP: {
      id: 28,
      value: 'CA_YCP',
      label: <Trans i18nKey="consumer:u.t.b.f.ca.ycp">YouCam Perfect</Trans>,
    },
    CA_YCV: {
      id: 29,
      value: 'CA_YCV',
      label: <Trans i18nKey="consumer:u.t.b.f.ca.ycv">YouCam Video</Trans>,
    },
    CA_YCN: {
      id: 30,
      value: 'CA_YCN',
      label: <Trans i18nKey="consumer:u.t.b.f.ca.ycn">YouCam Nails</Trans>,
    },
    CA_YCE: {
      id: 31,
      value: 'CA_YCE',
      label: <Trans i18nKey="consumer:u.t.b.f.ca.yce">YouCam Enhance</Trans>,
    },
    CA_YCA: {
      id: 32,
      value: 'CA_YCA',
      label: <Trans i18nKey="consumer:u.c.applinks.yca">YouCam AI Pro</Trans>,
    },
    CA_YCOE: {
      id: 32,
      value: 'CA_YCOE',
      label: (
        <Trans i18nKey="consumer:u.c.applinks.ycoe">YouCam Online Editor</Trans>
      ),
    },
  },
  CONSUMER_TOPICS: {
    CT_PHOTO_EDITING: {
      id: 30,
      value: 'CT_PHOTO_EDITING',
      label: (
        <Trans i18nKey="consumer:u.t.b.f.ct.photo.edit">Photo Editing</Trans>
      ),
    },
    CT_VIDEO_EDITING: {
      id: 31,
      value: 'CT_VIDEO_EDITING',
      label: (
        <Trans i18nKey="consumer:u.t.b.f.ct.video.edit">Video Editing</Trans>
      ),
    },
    CT_FACE_RETOUCH: {
      id: 32,
      value: 'CT_FACE_RETOUCH',
      label: (
        <Trans i18nKey="consumer:u.t.b.f.ct.face.retouch">
          Face Retouch/Reshape
        </Trans>
      ),
    },
    CT_SELFIE_CAM: {
      id: 33,
      value: 'CT_SELFIE_CAM',
      label: <Trans i18nKey="consumer:u.t.b.f.ct.selfie.cam">Selfie Cam</Trans>,
    },
    CT_EFFECT: {
      id: 34,
      value: 'CT_EFFECT',
      label: <Trans i18nKey="consumer:u.t.b.f.ct.effect">Effect</Trans>,
    },
    CT_HOW_TO: {
      id: 35,
      value: 'CT_HOW_TO',
      label: <Trans i18nKey="consumer:u.t.b.f.ct.how.to">How-to</Trans>,
    },
    CT_MAKEUP_LOOKS: {
      id: 36,
      value: 'CT_MAKEUP_LOOKS',
      label: (
        <Trans i18nKey="consumer:u.t.b.f.ct.makeup.looks">Makeup & Looks</Trans>
      ),
    },
    CT_SKINCARE: {
      id: 37,
      value: 'CT_SKINCARE',
      label: <Trans i18nKey="consumer:u.t.b.f.ct.skincare">Skincare</Trans>,
    },
    CT_HAIR_COLOR: {
      id: 38,
      value: 'CT_HAIR_COLOR',
      label: <Trans i18nKey="consumer:u.t.b.f.ct.hair.color">Hair Color</Trans>,
    },
    CT_AR_FILTER: {
      id: 39,
      value: 'CT_AR_FILTER',
      label: <Trans i18nKey="consumer:u.t.b.f.ct.ar.filter">AR Filter</Trans>,
    },
    CT_TOP_TRENDS: {
      id: 40,
      value: 'CT_TOP_TRENDS',
      label: <Trans i18nKey="consumer:u.t.b.f.ct.trends">Top Trends</Trans>,
    },
    CT_NAIL_DESIGN: {
      id: 41,
      value: 'CT_NAIL_DESIGN',
      label: (
        <Trans i18nKey="consumer:u.t.b.f.ct.naildesign">Nail Design</Trans>
      ),
    },
    CT_PHOTO_ENHANCE: {
      id: 42,
      value: 'CT_PHOTO_ENHANCE',
      label: (
        <Trans i18nKey="consumer:u.t.b.f.ct.photoenhance">Photo Enhance</Trans>
      ),
    },
    CT_HAIRSTYLE: {
      id: 43,
      value: 'CT_HAIRSTYLE',
      label: <Trans i18nKey="business:p.b.s.c.m.hair.style">Hair Style</Trans>,
    },
    CT_AI_IMAGE_GENERATION: {
      id: 44,
      value: 'CT_AI_IMAGE_GENERATION',
      label: (
        <Trans i18nKey="business:p.b.s.c.m.ai.image.generation">
          AI Image Generation
        </Trans>
      ),
    },
    CT_GENERATIVE_AI: {
      id: 45,
      value: 'CT_GENERATIVE_AI',
      label: (
        <Trans i18nKey="business:p.b.s.c.m.generative.ai">Generative ai</Trans>
      ),
    },
    CT_FACE_AI: {
      id: 46,
      value: 'CT_FACE_AI',
      label: <Trans i18nKey="business:p.b.s.c.m.face.ai">Face ai</Trans>,
    },
    CT_HALLOWEEN: {
      id: 47,
      value: 'CT_HALLOWEEN',
      label: <Trans i18nKey="business:p.b.s.c.m.halloween">Halloween</Trans>,
    },
    CT_CHRISTMAS: {
      id: 48,
      value: 'CT_CHRISTMAS',
      label: <Trans i18nKey="business:p.b.s.c.m.christmas">Christmas</Trans>,
    },
    CT_NEW_YEAR: {
      id: 49,
      value: 'CT_NEW_YEAR',
      label: <Trans i18nKey="business:p.b.s.c.m.new.year">New Year</Trans>,
    },
    CT_CHINESE_NEW_YEAR: {
      id: 50,
      value: 'CT_CHINESE_NEW_YEAR',
      label: (
        <Trans i18nKey="business:p.b.s.c.m.chinese.new.year">
          Chinese New Year
        </Trans>
      ),
    },
    CT_VALENTINES_DAY: {
      id: 51,
      value: 'CT_VALENTINES_DAY',
      label: (
        <Trans i18nKey="business:p.b.s.c.m.valentines.day">
          Valentine’s Day
        </Trans>
      ),
    },
  },
  FOCUS: {
    BF_BUSINESS_FOCUS: {
      id: 0,
      value: 'BF_BUSINESS_FOCUS',
      label: <Trans i18nKey="business:u.t.b.f.bf.focus">Business Focus</Trans>,
    },
    CF_CONSUMER_FOCUS: {
      id: 1,
      value: 'CF_CONSUMER_FOCUS',
      label: <Trans i18nKey="consumer:u.t.b.f.cf.focus">Consumer Focus</Trans>,
    },
  },
};
