import React from 'react';
import {Trans} from 'react-i18next';

const links = {
  aifacialrecognition: {
    id: 'menu.business.aifacialrecognition',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.facial.recognition">
        AI Facial Recognition
      </Trans>
    ),
    link: '/business/products/ai-facial-recognition',
    i18nKey: 'business:u.c.solutionlinks.ai.facial.recognition',
  },
  aiproductrecommendation: {
    id: 'menu.business.aiproductrecommendation',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.product.recommendation">
        AI Product Recommendations
      </Trans>
    ),
    link: '/business/products/ai-product-recommendation',
    i18nKey: 'business:u.c.solutionlinks.ai.product.recommendation',
  },
  aishadefinder: {
    id: 'menu.business.aishadefinder',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.shade.finder">
        AI Foundation Shade Finder and Matcher
      </Trans>
    ),
    link: '/business/products/ai-shade-finder',
    i18nKey: 'business:u.c.solutionlinks.ai.shade.finder',
  },
  arskindiagnostic: {
    id: 'menu.business.arskindiagnostic',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.skin.diagnostic">
        AI Skin Analysis
      </Trans>
    ),
    link: '/business/products/ai-skin-diagnostic',
    i18nKey: 'business:u.c.solutionlinks.ai.skin.diagnostic',
  },
  ar3deyewear: {
    id: 'menu.business.ar3deyewear',
    text: (
      <Trans i18nKey="business:u.c.servicelinks.ar.3d.eyewear">
        AI-Powered Virtual Try-On for Glasses
      </Trans>
    ),
    link: '/business/products/eye-wear',
    i18nKey: 'business:u.c.servicelinks.ar.3d.eyewear',
  },
  ba1on1: {
    id: 'menu.business.ba1on1',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ba.1.on.1">
        Beauty Advisor 1-on-1
      </Trans>
    ),
    link: '/business/products/ba-1-on-1',
    i18nKey: 'business:u.c.solutionlinks.ba.1.on.1',
  },
  instorebarcode: {
    id: 'menu.business.instorebarcode',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.in.store.barcode">
        In-Store Barcode Try-On
      </Trans>
    ),
    link: '/business/products/in-store-barcode',
    i18nKey: 'business:u.c.solutionlinks.in.store.barcode',
  },
  liveartraining: {
    id: 'menu.business.liveartraining',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.live.ar.training">
        Live AR for Corporate Training
      </Trans>
    ),
    link: '/business/products/live-ar-training',
    i18nKey: 'business:u.c.solutionlinks.live.ar.training',
  },
  livehaircolor: {
    id: 'menu.business.livehaircolor',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.live.hair.color">
        AI Virtual Hair Color Try-On
      </Trans>
    ),
    link: '/business/products/live-hair-color',
    i18nKey: 'business:u.c.solutionlinks.live.hair.color',
  },
  livestreaming: {
    id: 'menu.business.livestreaming',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.live.streaming">
        AR Live Casting for Web and App
      </Trans>
    ),
    link: '/business/products/live-streaming',
    i18nKey: 'business:u.c.solutionlinks.live.streaming',
  },
  virtualaccessories: {
    id: 'menu.business.virtualaccessories',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.virtual.accessories">
        Hat and Headband Virtual Try-On
      </Trans>
    ),
    link: '/business/products/ar-virtual-accessories',
    i18nKey: 'business:u.c.solutionlinks.virtual.accessories',
  },
  virtualmakeup: {
    id: 'menu.business.virtualmakeup',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.virtual.makeup">
        AI-Powered Virtual Makeup
      </Trans>
    ),
    link: '/business/products/virtual-makeup',
    i18nKey: 'business:u.c.solutionlinks.virtual.makeup',
  },
  youcamtutorial: {
    id: 'menu.business.youcam.tutorial',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.youcam.tutorial">
        YouCam Tutorial
      </Trans>
    ),
    link: '/business/products/youcam-tutorial',
    i18nKey: 'business:u.c.solutionlinks.youcam.tutorial',
  },
  videos: {
    id: 'menu.business.videos',
    text: <Trans i18nKey="business:c.l.s.leftmenu.demo.video">Videos</Trans>,
    link: '/business/videos',
    sublinks: ['/business/videos', '/business/video'],
    i18nKey: 'business:c.l.s.leftmenu.demo.video',
  },
  blog: {
    id: 'menu.business.blog',
    text: <Trans i18nKey="business:c.l.s.leftmenu.demo.blog">Blog</Trans>,
    link: '/business/blog/1',
    i18nKey: 'business:c.l.s.leftmenu.demo.blog',
  },
  stories: {
    id: 'menu.business.stories',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.demo.story">
        Success Stories
      </Trans>
    ),
    link: '/business/successstory/list',
    i18nKey: 'business:c.l.s.leftmenu.demo.story',
  },
  news: {
    id: 'p.b.h.news.title',
    text: <Trans i18nKey="business:p.b.h.news.title">News</Trans>,
    link: '/business/news/list',
    i18nKey: 'business:p.b.h.news.title',
  },
  colorReport: {
    id: 'menu.business.color.report',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.color.report">
        Beauty Trend Report
      </Trans>
    ),
    link: '/business/color-list',
    i18nKey: 'business:c.l.f.menudefinitions.color.report',
  },
  partners: {
    id: 'menu.business.partners',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.partners">Partners</Trans>
    ),
    link: '/business/partners',
    i18nKey: 'business:c.l.f.menudefinitions.partners',
  },
  integrationPartner: {
    id: 'menu.business.integration.partner',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.integration.partner">
        インテグレーション・パートナー
      </Trans>
    ),
    link: '/business/integration-partner',
    i18nKey: 'business:c.l.f.menudefinitions.integration.partner',
  },
  btf: {
    id: 'menu.business.btf',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.btf">
        Beauty Tech Forum Master Series
      </Trans>
    ),
    link: '/business/beauty-tech-forum-master-class',
    i18nKey: 'business:c.l.f.menudefinitions.btf',
  },
  education: {
    id: 'menu.business.education',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.education">
        Beauty Tech Forum Education Series
      </Trans>
    ),
    link: '/business/education-series',
    i18nKey: 'business:c.l.f.menudefinitions.education',
  },
  gbtf2021: {
    id: 'menu.business.gbtf2021',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.gbtf2021">
        Global Beauty & Fashion AI Forum
      </Trans>
    ),
    link: '/business/global-beauty-tech-forum',
    i18nKey: 'business:c.l.f.menudefinitions.gbtf2021',
  },
  btfVirtual: {
    id: 'menu.business.btfVirtual',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.gbtf2021">
        Beauty Tech Forum 2022
      </Trans>
    ),
    // link: '/business/global-beauty-tech-forum',
    i18nKey: 'business:c.l.f.menudefinitions.btfVirtual',
  },
  btfVirtualUS: {
    id: 'menu.business.btfVirtual-us',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.btfVirtual-us">
        Beauty Tech Forum - US
      </Trans>
    ),
    link: '/business/global-beauty-tech-forum',
    i18nKey: 'business:c.l.f.menudefinitions.btfVirtual-us',
  },
  btfVirtualFR: {
    id: 'menu.business.btfVirtual-fr',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.btfVirtual-fr">
        Global Beauty & Fashion AI Forum - France
      </Trans>
    ),
    link: '/business/global-beauty-tech-forum/fra',
    i18nKey: 'business:c.l.f.menudefinitions.btfVirtual-fr',
  },
  btfVirtualJP: {
    id: 'menu.business.btfVirtual-jp',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.btfVirtual-jp">
        Beauty Tech Forum - Japan
      </Trans>
    ),
    link: '/business/global-beauty-tech-forum/jpn',
    i18nKey: 'business:c.l.f.menudefinitions.btfVirtual-jp',
  },
  aifaceanalyzer: {
    id: 'menu.business.ai.face.analyzer',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.ai.face.analyzer">
        AI Face Analyzer
      </Trans>
    ),
    link: '/business/products/ai-face-analyzer',
    i18nKey: 'business:c.l.f.menudefinitions.ai.face.analyzer',
  },
  aibearddye: {
    id: 'menu.business.ai.beard.dye',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.ai.beard.dye">
        AI Beard Dye and Beard Style Virtual Try-On
      </Trans>
    ),
    link: '/business/products/ai-beard-dye',
    i18nKey: 'business:c.l.f.menudefinitions.ai.beard.dye',
  },
  nailColor: {
    id: 'menu.business.ai.beard.dye',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.nail.color">
        Virtual Try-On for Nails
      </Trans>
    ),
    link: '/business/products/ai-nail-color',
    i18nKey: 'business:c.l.f.menudefinitions.nail.color',
  },
  ces2022: {
    id: 'menu.business.ces2022',
    text: (
      <Trans i18nKey="business:c.l.f.menudefinitions.ces.2022">
        Beauty & Fashion Metaverse
      </Trans>
    ),
    link: '/business/beauty-fashion-metaverse',
    i18nKey: 'business:c.l.f.menudefinitions.ces.2022',
  },
  arwatch: {
    id: 'menu.business.arwatch',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ar.watch">
        AR Watch Virtual Try-On
      </Trans>
    ),
    link: '/business/products/ar-watch',
    i18nKey: 'business:u.c.solutionlinks.ar.watch',
  },
  arring: {
    id: 'menu.business.arring',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ar.ring">
        AR Ring Virtual Try-On
      </Trans>
    ),
    link: '/business/products/ar-virtual-ring',
    i18nKey: 'business:u.c.solutionlinks.ar.ring',
  },
  arbracelet: {
    id: 'menu.business.arbracelet',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ar.bracelet">
        AR Bracelet Virtual Try-On
      </Trans>
    ),
    link: '/business/products/ar-bracelet',
    i18nKey: 'business:u.c.solutionlinks.ar.bracelet',
  },
  arearring: {
    id: 'menu.business.arbracelet',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ar.earring">
        AR Earring Virtual Try-On
      </Trans>
    ),
    link: '/business/products/ar-virtual-earrings',
    i18nKey: 'business:u.c.solutionlinks.ar.earring',
  },
  facereshaping: {
    id: 'menu.business.face.reshaping',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.face.reshaping">
        AI Face Reshape Simulator
      </Trans>
    ),
    link: '/business/products/face-reshaping',
    i18nKey: 'business:u.c.solutionlinks.face.reshaping',
  },
  aipersonality: {
    id: 'menu.business.ai.personality',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.personality">
        AI Personality Finder
      </Trans>
    ),
    link: '/business/products/ai-personality',
    i18nKey: 'business:u.c.solutionlinks.ai.personality',
  },
  background: {
    id: 'menu.business.virtual.background',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.virtual.background">
        AI Virtual Background Changer
      </Trans>
    ),
    link: '/business/products/virtual-background',
    i18nKey: 'business:u.c.solutionlinks.virtual.background',
  },
  hairstyle: {
    id: 'menu.business.hair.style',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.hair.style">
        AR Hairstyle Virtual Try-On
      </Trans>
    ),
    link: '/business/products/virtual-hairstyles',
    i18nKey: 'business:u.c.solutionlinks.hair.style',
  },
  hairtype: {
    id: 'menu.business.hair.type',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.hair.type">
        AI Hair Type Analysis
      </Trans>
    ),
    link: '/business/products/hair-type-analysis',
    i18nKey: 'business:u.c.solutionlinks.hair.type',
  },
  hairlength: {
    id: 'menu.business.hair.length',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.hair.length">
        AI Hair Length Analysis
      </Trans>
    ),
    link: '/business/products/hair-length-analysis',
    i18nKey: 'business:u.c.solutionlinks.hair.length',
  },
  skinemulation: {
    id: 'menu.business.skin.emulation',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.skin.emulation">
        AI Skin Emulation
      </Trans>
    ),
    link: '/business/products/ai-skin-emulation',
    i18nKey: 'business:u.c.solutionlinks.skin.emulation',
  },
  arnecklace: {
    id: 'menu.business.ar.necklace',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ar.necklace">
        AR Necklace Virtual Try-On
      </Trans>
    ),
    link: '/business/products/ai-virtual-necklace',
    i18nKey: 'business:u.c.solutionlinks.ar.necklace',
  },
  threedviewer: {
    id: 'menu.business.3d.viewer',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.3d.viewer">
        3D Viewer & 3D Authoring Tool
      </Trans>
    ),
    link: '/business/products/3d-viewer-virtual-try-on',
    i18nKey: 'business:u.c.solutionlinks.3d.viewer',
  },
  whitepaper: {
    id: 'menu.business.whitepaper',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.whitepaper">Whitepaper</Trans>
    ),
    link: '/business/color-trends/3d-vto-format',
    i18nKey: 'business:u.c.solutionlinks.whitepaper',
  },
  _3dvtoformat: {
    id: 'menu.business.3dvtoformat',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.3d.vto.format">
        3D VTO Format
      </Trans>
    ),
    link: '/business/campaign/3d-vto-format',
    i18nKey: 'business:u.c.solutionlinks.3d.vto.format',
  },
  westcoastgbtf: {
    id: 'u.c.solutionlinks.west.coast.gbtf',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.west.coast.gbtf">
        West Coast Global Beauty & Fashion Tech Forum
      </Trans>
    ),
    link: 'https://www.perfectcorp.com/business/global-beauty-tech-forum/WCF24',
    i18nKey: 'business:u.c.solutionlinks.west.coast.gbtf',
  },
  aiskinanalysisvalidator: {
    id: 'menu.business.aiskinanalysisvalidator',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.skin.analysis.validator">
        AI Skin Analysis Validator
      </Trans>
    ),
    link: '/business/products/ai-skin-analysis-validator',
    i18nKey: 'business:u.c.solutionlinks.ai.skin.analysis.validator',
  },
  aiapi: {
    id: 'menu.business.ai.api',
    text: <Trans i18nKey="business:u.c.solutionlinks.ai.api">AI API</Trans>,
    link: '/business/ai-apis',
    i18nKey: 'business:u.c.solutionlinks.ai.api',
  },
  aihairlengthanalysis: {
    id: 'menu.business.ai.hair.length.analysis',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.hair.length.analysis">
        AI Hair Length Analysis
      </Trans>
    ),
    link: '/business/products/hair-length-analysis',
    i18nKey: 'business:u.c.solutionlinks.ai.hair.length.analysis',
  },
  aimakeuptransfer: {
    id: 'menu.business.ai.makeup.transfer',
    text: (
      <Trans i18nKey="business:u.c.solutionlinks.ai.makeup.transfer">
        AI Makeup Transfer
      </Trans>
    ),
    link: '/business/products/ai-makeup-transfer',
    i18nKey: 'business:u.c.solutionlinks.ai.makeup.transfer',
  },
};

export default links;
