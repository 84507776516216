import React from 'react';
import AsyncPage from '../../../business/misc/info/aboutus/async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/about-us"
    exact
    path="/consumer/about-us"
    component={AsyncPage}
    // backgroundImage={require('../../../../assets/images/consumer/about-us-bg.jpg')}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'About us',
        item: '/consumer/about-us',
      },
    ]}
  />,
];
