import React from 'react';
import AsyncPrivacy from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default (
  <DefaultBusinessLayout
    key="/business/privacy"
    exact
    path="/business/privacy"
    component={AsyncPrivacy}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Privacy',
        item: '/business/privacy',
      },
    ]}
  />
);
