import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

export default function AndroidBtn(noAndroid = false) {
  const {i18n} = useTranslation();

  const langBtn = {
    noAndroid: require('../../../assets/images/consumer/appdownloadicons/yce-button-web.svg'),
    de: require('../../../assets/images/consumer/appdownloadicons/btn_android_deu.png'),
    en: require('../../../assets/images/consumer/appdownloadicons/btn_android_en.svg'),
    es: require('../../../assets/images/consumer/appdownloadicons/btn_android_es.png'),
    fr: require('../../../assets/images/consumer/appdownloadicons/btn_android_fr.png'),
    ja: require('../../../assets/images/consumer/appdownloadicons/btn_android_ja.png'),
    ko: require('../../../assets/images/consumer/appdownloadicons/btn_android_kr.png'),
    pt: require('../../../assets/images/consumer/appdownloadicons/btn_android_ptb.png'),
    it: require('../../../assets/images/consumer/appdownloadicons/btn_android_ita.png'),
    zh_cn: require('../../../assets/images/consumer/appdownloadicons/btn_android_cn.svg'),
    zh_tw: require('../../../assets/images/consumer/appdownloadicons/btn_android_ch.png'),
  };

  const [btn, setBtn] = useState(langBtn.en);

  useEffect(() => {
    if (noAndroid) {
      return setBtn(langBtn.noAndroid);
    }

    if (
      i18n.language.toLowerCase() === 'zh-cn' ||
      i18n.language.toLowerCase() === 'zh_cn'
    ) {
      return setBtn(langBtn.zh_cn);
    }

    if (
      i18n.language.toLowerCase() === 'zh-tw' ||
      i18n.language.toLowerCase() === 'zh_tw'
    ) {
      return setBtn(langBtn.zh_tw);
    }

    if (Object.keys(langBtn).includes(i18n.language)) {
      return setBtn(langBtn[i18n.language]);
    }
    setBtn(langBtn.en);
  }, [i18n.language]);

  return btn;
}
