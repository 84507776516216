import ShowocaseLayout from '../../../../components/layout/ShowocaseLayout';
import AsyncPage from './async';
import React from 'react';

export default [
    <ShowocaseLayout
        key="/business/showcase/bracelets"
        exact
        path="/business/showcase/bracelets"
        component={AsyncPage}
    />,
];
