import React from 'react';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import AsyncPage from './async';

export default [
  <DefaultBusinessLayout
    key="/business/integration-partner"
    exact
    path="/business/integration-partner"
    component={AsyncPage}
    isEnterprise={true}
    breadcrumb={[
      {
        position: 1,
        name: 'Business',
        item: '/business',
      },
      {
        position: 2,
        name: 'Integration Partners',
        item: '/business/integration-partner',
      },
    ]}
    showContactSales={false}
  />,
];
