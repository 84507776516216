const ymkConfigs = {
  functionType: {
    shadefinder: 'shadefinder',
    makeup: 'makeup',
    skincare: 'skincare',
    faceattribute: 'faceattribute',
    productrecommendation: 'productrecommendation',
    livestream: 'livestream',
    handar: 'handar',
    necklacear: 'necklacear',
    offline: 'offline',
    pbrviewer: 'pbrviewer',
  },
  recordingPhases: {
    live: 'live',
    videoinit: 'video-init',
    videorecord: 'video-recording',
    videoplay: 'video-playing',
  },
  handarMode: {
    none: 'none',
    nail: 'nail',
    wrist: 'wrist',
    ring: 'ring',
    pbr: 'pbr',
  },
  emulationDisplayModes: {
    emulation: 'emulation',
    diffImage: 'diffImage',
  },
  makeupResetTypes: [
    'blush',
    'bronzer',
    'eyelashes',
    'eyeliner',
    'eyeshadow',
    'lipstick',
    'lipliner',
    'foundation',
    'eyecontact',
    'eyebrow',
    'hairdye',
    'eyebrow_highlight',
    'concealer',
    'face_contour',
    'face_art',
    'face_art_layer2',
    'eyewear',
    'earrings',
  ],
  skuTypes: [
    'background',
    'blush',
    'eyelash',
    'eyeliner',
    'eyeshadow',
    'lipliner',
    'lipstick',
    'foundation',
    'eyecontact',
    'eyebrow',
    'hairdye',
    'eye_wear',
    'eyebrow_highlight',
    'face_art_layer2',
    'face_contour',
    'concealer',
    'bronzer',
    'earrings',
    'bearddye',
    'nail',
    'watch',
    'bracelet',
    'ring',
  ],
  vtoType: {
    twoD: '2d',
    threeD: '3d',
  },
  ringFingerTypes: {
    ring: 'ring',
    middle: 'middle',
    index: 'index',
    thumb: 'thumb',
    pinky: 'pinky',
  },
};

export default ymkConfigs;
