import React from 'react';
import {Trans} from 'react-i18next';
import Link from '../../../../components/link';
import _get from 'lodash/get';
import ActionButton from './ActionButton';
import './EventCard.css';
import _isEmpty from 'lodash/isEmpty';
import webUtils from "../../../../utils/webUtils";

function EventCard({
  label = 'PANEL',
  title = '',
  speakers = [],
  actionLink,
  actionType,
  image,
  labelColor,
  showWatchReplay,
  eventList,
}) {
  const moreSpeaker = !_isEmpty(
    eventList.filter((s) => s.sessionSpeakers && s.sessionSpeakers.length > 2)
  );
  return (
    <div className="btfv-event-card__wrapper">
      <Link to={actionLink}>
        <div
          className="btfv-event-card__label"
          style={{
            backgroundColor: labelColor,
          }}
        >
          {label}
        </div>
        <div className="btfv-event-card__image">
          <img src={webUtils.transformMakeupArUrl(image)} alt={title} />
          {showWatchReplay && (
            <div className="btfv-event-card__button">
              <span className="btfv-event-card__button__text">
                <Trans i18nKey="business:p.b.b.v.watch.replay">WATCH NOW</Trans>
              </span>{' '}
              <img
                alt=""
                src={require('../../../../assets/images/icon/btn_story_play.png')}
                style={{maxWidth: '26px', width: '100%'}}
              />
            </div>
          )}
        </div>
        <div className="btfv-event-card__title">{title}</div>
        <div
          className="btfv-event-card__speakers"
          style={{minHeight: moreSpeaker ? '270px' : '156px'}}
        >
          {speakers.map((s, i) => (
            <div
              className="btfv-event-card__speaker"
              key={_get(s, 'speaker.speakerName', `speaker${i}`)}
            >
              <img
                src={webUtils.transformMakeupArUrl(_get(s, 'speaker.speakerImageUrl', ''))}
                alt=""
                className="btfv-event-card__speaker__img"
              />
              <div style={{width: 'calc(100% - 70px)'}}>
                <div className="btfv-event-card__speaker__name">
                  {_get(s, 'speaker.speakerName', '')}
                </div>
                {_get(s, 'speaker.brandName', '') && (
                  <div className="btfv-event-card__speaker__brand">
                    {s.speaker.brandName}
                  </div>
                )}
                <div className="btfv-event-card__speaker__position">
                  {_get(s, 'speaker.speakerPosition', '')}
                </div>
              </div>
            </div>
          ))}
          {_isEmpty(speakers) && (
            <div className="btfv-event-card__speaker">
              <div className="btfv-event-card__speaker__name">
                <Trans i18nKey="business:p.b.c.eventcard.speaker.announce">
                  Guest speakers to be announced
                </Trans>
              </div>
            </div>
          )}
        </div>
        <ActionButton actionType={actionType} />
      </Link>
    </div>
  );
}

export default EventCard;
