import _flattenDeep from 'lodash/flattenDeep';
import applisting from './applisting/routes';
import facebookarchatbot from './facebookarchatbot/routes';
import instoreconsultation from './instoreconsultation/routes';
import sdk from './sdk/routes';
import wechatminiprogram from './wechatminiprogram/routes';
import youcam4web from './youcam4web/routes';
import youcam4google from './youcam4google/routes';
import taobaominiprogram from './taobaominiprogram/routes';
import douyin from './douyin/routes';
import snapchat from './snapchat/routes';
import instagram from './instagram/routes';

export default [
  applisting,
  instoreconsultation,
  sdk,
  wechatminiprogram,
  youcam4web,
  youcam4google,
  taobaominiprogram,
  douyin,
  snapchat,
  instagram,
];
