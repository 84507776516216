import React from 'react';
import {Trans} from 'react-i18next';
import AgileFaceText from '../../pages/business/technologies/agileface/RegisteredName';
import AgileHandText from '../../pages/business/technologies/agilehand/component/registeredname/index';
import webUtils from '../webUtils';

const links = {
  makeup: {
    functionType: 'MAKEUP',
    solutionType: 'MAKEUP',
    demoTitle: (
      <Trans i18nKey="business:c.l.s.smb.menus.right.card1.title">
        Makeup Try-on
      </Trans>
    ),
    demoIcon:
      'https://plugins-media.makeupar.com/smb/story/2024-10-08/ee813852-5115-4c14-8f46-0f19725fff1f.png',

    id: 'c.l.s.leftmenu.online.service.makeup',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.makeup">
        Virtual Makeup Try-On
      </Trans>
    ),
    link: '/business/solutions/online-service/makeup',
    i18nKey: 'business:c.l.s.leftmenu.online.service.makeup',
    activeLink: '/business/solutions/online-service/makeup',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card1.desc">
        Add MAKEUP TRY ON button on your site using our web module and web
        widget
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.makeup.card.desc">
        <span>Makeup</span> Virtual Try On
      </Trans>
    ),
    solutionStyle: 'makeup',
  },
  eyewear: {
    functionType: 'EYEWEAR',
    solutionType: 'EYEWEAR',
    demoIcon:
      'https://plugins-media.makeupar.com/smb/story/2024-10-08/99445251-a639-477a-8aba-91233a34e01f.png',
    demoTitle: (
      <Trans i18nKey="business:c.l.s.smb.menus.right.card2.title">
        Glasses Try-on
      </Trans>
    ),
    id: 'c.l.s.leftmenu.online.service.eyewear',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.eyewear">
        Virtual Glasses Try-On
      </Trans>
    ),
    link: '/business/solutions/online-service/eye-wear',
    i18nKey: 'business:c.l.s.leftmenu.online.service.eyewear',
    activeLink: '/business/solutions/online-service/eye-wear',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card2.desc">
        Integrate our GLASSES TRY ON module or widget for better sales
        conversion
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.eyewear.card.desc">
        <span>Glasses</span> Virtual Try On
      </Trans>
    ),
    solutionStyle: 'eyewear',
  },
  skincare: {
    functionType: 'SKINCARE_PRO',
    solutionType: 'SKIN_ANALYSIS',
    demoIcon:
      'https://plugins-media.makeupar.com/smb/story/2024-10-08/e7254dab-5cb1-4aa4-a2d4-81f0b56ca94e.png',
    demoTitle: (
      <Trans i18nKey="business:c.l.s.smb.menus.right.card3.title">
        Skin Analysis
      </Trans>
    ),
    id: 'c.l.s.leftmenu.online.service.skincare',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.skincare">
        Skincare Pro
      </Trans>
    ),
    link: '/business/solutions/online-service/skincare-pro',
    i18nKey: 'business:c.l.s.leftmenu.online.service.skincare',
    activeLink: '/business/solutions/online-service/skincare-pro',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card3.desc">
        Software-base SKIN SCANNER solution on iPads with customizable skincare
        recommendations
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.skin.analysis.card.desc">
        <span>AI Skin Analysis</span>
      </Trans>
    ),
    solutionStyle: 'skin-analysis',
  },
  aesthetic: {
    functionType: 'SKINCARE_PRO',
    solutionType: 'AESTHETIC_SIMULATION',
    demoIcon:
      'https://plugins-media.makeupar.com/smb/story/2024-10-08/828ddd65-e059-45ed-b850-51944be7afc4.png',
    demoTitle: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card4.title">
        Aesthetic Simulation
      </Trans>
    ),
    id: 'c.l.s.leftmenu.online.service.aesthetic.simulation',
    text: (
      <Trans i18nKey="business:c.l.s.leftmenu.online.service.aesthetic.simulation">
        aesthetic.simulation
      </Trans>
    ),
    link: '/business/solutions/online-service/aesthetic-simulation',
    i18nKey: 'business:c.l.s.leftmenu.online.service.aesthetic.simulation',
    activeLink: '/business/solutions/online-service/aesthetic-simulation',
    desc: (
      <Trans i18nKey="business:c.l.s.smb.menus.left.card4.desc">
        Software-base SKIN SCANNER solution on iPads with customizable skincare
        recommendations
      </Trans>
    ),
    solutionCardDesc: (
      <Trans i18nKey="business:p.b.h.solutions.skin.simulation.card.desc">
        <span>AI Aesthetic Simulation</span>
      </Trans>
    ),
    solutionStyle: 'skin-simulation',
  },
};

export default links;
