import React from 'react';
import AsyncComponent from './async';
import DefaultCampaignLayout from '../../../../components/layout/DefaultCampaignLayout';

export default [
  <DefaultCampaignLayout
    key="/business/campaign/ai-skin-diagnostic"
    exact
    path="/business/campaign/ai-skin-diagnostic"
    component={AsyncComponent}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'AI Skin Diagnostic',
        item: '/business/campaign/ai-skin-diagnostic',
      },
    ]}
  />,
  <DefaultCampaignLayout
    key="/business/campaign/skin-diagnostic"
    exact
    path="/business/campaign/skin-diagnostic"
    component={AsyncComponent}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Skin Diagnostic',
        item: '/business/campaign/skin-diagnostic',
      },
    ]}
  />,
];
