import aiphotoeditingtoolLinks from './config/aiphotoeditingtool';
import productLinks from './config/productLinks';
import blogUtils from './blogUtils';
import webUtils from './webUtils';
import btfUtils from './btfUtils';
import technologyLinks from './config/technologyLinks';
import serviceLinks from './config/serviceLinks';
import appLinks from './config/appLinks';
import smbLinks from './config/smbLinks';

const utils = {
  aiphotoeditingtool: [
    aiphotoeditingtoolLinks.photoEnhance,
    aiphotoeditingtoolLinks.photoColorize,
    aiphotoeditingtoolLinks.objectRemoval,
    aiphotoeditingtoolLinks.removeBackground,
    aiphotoeditingtoolLinks.blurBackground,
    aiphotoeditingtoolLinks.changeBackground,
    aiphotoeditingtoolLinks.aiLighting,
    aiphotoeditingtoolLinks.aiImageExtender,
    aiphotoeditingtoolLinks.aiReplace,
    aiphotoeditingtoolLinks.colorCorrection,
    aiphotoeditingtoolLinks.aivideoenhance,
    aiphotoeditingtoolLinks.viewalltools,
  ],
  genai: [
    aiphotoeditingtoolLinks.textimage,
    aiphotoeditingtoolLinks.aihairstyle,
    aiphotoeditingtoolLinks.aihairbang,
    aiphotoeditingtoolLinks.aihairvolume,
    aiphotoeditingtoolLinks.aihairextension,
    aiphotoeditingtoolLinks.aiHeadshot,
    aiphotoeditingtoolLinks.aistudio,
    aiphotoeditingtoolLinks.aiavatar,
    aiphotoeditingtoolLinks.viewalltools,
    // aiphotoeditingtoolLinks.aihairstyles,
    // aiphotoeditingtoolLinks.aifashion,
  ],
  faceai: [
    aiphotoeditingtoolLinks.aifaceshapedetector,
    aiphotoeditingtoolLinks.aifaceswap,
    aiphotoeditingtoolLinks.makeTransfer,
    aiphotoeditingtoolLinks.viewalltools,
  ],
  consumerApps: [
    appLinks.ymk,
    //appLinks.y4b,
    appLinks.ycp,
    appLinks.yca,
    appLinks.yce,
    appLinks.ycv,
    appLinks.ycn,
  ],
  resource: lang => {
    return [
      productLinks.videos,
      blogUtils.isEnabled() && productLinks.blog,
      productLinks.news,
      productLinks.stories,
      !webUtils.isChs(lang) && !webUtils.isJa(lang) && productLinks.partners,
    ];
  },
  footerResource: [
    productLinks.videos,
    productLinks.blog,
    productLinks.aiapi,
    productLinks.news,
    productLinks.stories,
    productLinks.colorReport,
    productLinks.btf,
    productLinks.gbtf2021,
  ],
  btflist: lang => {
    return [
      webUtils.isJa(lang) && productLinks.integrationPartner,
      btfUtils.isBtfEnabled() && productLinks.btf, // latest btf
      lang !== 'fr' && lang !== 'ja' && productLinks.gbtf2021,
      btfUtils.isBtfvEnabled() && productLinks.btfVirtual,
      lang === 'en' && productLinks.westcoastgbtf,
      productLinks.colorReport,
    ];
  },
  btfvList: lang => {
    return [
      (lang === 'fr' || lang === 'ja') && productLinks.btfVirtualUS,
      lang === 'fr' && productLinks.btfVirtualFR,
      lang === 'ja' && productLinks.btfVirtualJP,
    ];
  },
  techMenu: [
    technologyLinks.agilehand,
    technologyLinks.makeupar,
    technologyLinks.live3dfacear,
    technologyLinks.skincarear,
    technologyLinks.faceai,
  ],
  makeupProducts: [
    productLinks.virtualmakeup,
    productLinks.youcamtutorial,
    productLinks.aimakeuptransfer,
    productLinks.aishadefinder,
    productLinks.background,
  ],
  nailProducts: [productLinks.nailColor],
  faceDiaProducts: [
    productLinks.arskindiagnostic,
    productLinks.aiskinanalysisvalidator,
    productLinks.skinemulation,
    productLinks.aifaceanalyzer,
    productLinks.facereshaping,
    productLinks.aipersonality,
  ],
  jewelryWatchesProducts: [
    productLinks.threedviewer,
    productLinks._3dvtoformat,
    productLinks.arring,
    productLinks.arbracelet,
    productLinks.arwatch,
    productLinks.arearring,
    productLinks.arnecklace,
  ],
  eyewearProducts: [productLinks.ar3deyewear],
  accessoriesProducts: [productLinks.virtualaccessories],
  hairProducts: [
    productLinks.livehaircolor,
    productLinks.hairstyle,
    productLinks.hairtype,
    productLinks.aihairlengthanalysis,
  ],
  bearddyeProducts: [productLinks.aibearddye],
  services: [
    serviceLinks.instoreconsultation,
    serviceLinks.youcam4web,
    serviceLinks.youcam4google,
    serviceLinks.virtualmakeupsnapchat,
    serviceLinks.virtualmakeupinstagram,
    serviceLinks.wechatminiprogram,
    serviceLinks.taobaominiprogram,
    serviceLinks.sdk,
    serviceLinks.applisting,
    serviceLinks.douyinminiprogram,
  ],
  onlineService: webUtils.isCnDomain()
    ? [smbLinks.makeup, smbLinks.eyewear, smbLinks.skincare]
    : [
        smbLinks.makeup,
        smbLinks.eyewear,
        smbLinks.skincare,
        smbLinks.aesthetic,
      ],
};

export default utils;
