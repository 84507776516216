import React from 'react';
import './index.css'
export default function DefaultHeader({title}) {
  return (<>
      <div className="pf-header__default">
          <div className="pf-header__default-title">
              {title}
          </div>

      </div>
  </>);
}
