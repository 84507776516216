import React from 'react';
import AsyncPage from './index';
import {Route} from 'react-router-dom';

export default [
  <Route
    key="/business/app/sign-in"
    exact
    path="/business/app/sign-in"
    component={AsyncPage}
  />,
];
