import langs from 'langs';
import i18n from '../i18n';

const langCode = {
  convertLanguageCode(lang = 'en') {
    let languageCode = [
      {language: 'zh_tw', langCode: 'cht'},
      {language: 'zh-tw', langCode: 'cht'},
      {language: 'zh_cn', langCode: 'chs'},
      {language: 'zh-cn', langCode: 'chs'},
      {language: 'en', langCode: 'enu'},
      {language: 'es', langCode: 'esp'},
      {language: 'pt', langCode: 'ptb'},
      {language: 'it', langCode: 'ita'},
      {language: 'de', langCode: 'deu'},
      {language: 'ja', langCode: 'jpn'},
      {language: 'ko', langCode: 'kor'},
      {language: 'fr', langCode: 'fra'},
    ];
    for (let l in languageCode) {
      if (languageCode[l].language === lang.toLowerCase()) {
        return languageCode[l].langCode;
      }
    }
    let target = langs.where('1', i18n.language);
    if (target) {
      return target['3'];
    }
    return 'enu';
  },
};

export default langCode;
