import {useRef, useCallback} from 'react';
import deviceUtils from '../../../../../utils/deviceUtils';
import webUtils from '../../../../../utils/webUtils';

export default function useForceControlPageScroll() {
  const originalPagePaddingRight = useRef(document.body.style['padding-right']);

  const isAllowed = () => !webUtils.isMobile() && webUtils.isWithinIframe();

  const disablePageScroll = useCallback(() => {
    if (!isAllowed()) {
      return;
    }
    document.body.style.setProperty('overflow', 'hidden', 'important');
    document.body.style.setProperty(
      'padding-right',
      `${deviceUtils.getScrollbarWidth()}px`,
      'important'
    );
  }, []);

  const enablePageScroll = () => {
    if (!isAllowed()) {
      return;
    }
    if (document.body.style.overflow !== 'hidden') {
      return;
    }
    document.body.style.overflow = '';
    document.body.style['padding-right'] = originalPagePaddingRight.current;
  };

  return {isAllowed, disablePageScroll, enablePageScroll};
}
