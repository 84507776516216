import React from 'react';
import PropTypes from 'prop-types';

export default function Avatar({
  imageUrl = '',
  width = 150,
  height = 150,
  borderRadius = 50,
  style = {},
  isOriginalSize = false,
}) {
  return (
    <img
      alt="avatar"
      src={
        imageUrl ? imageUrl : require('../../assets/images/account/avatar.png')
      }
      style={
        !isOriginalSize
          ? {
              width: width.toString() + 'px',
              height: height.toString() + 'px',
              borderRadius: borderRadius.toString() + '%',
              ...style,
            }
          : style
      }
    />
  );
}

Avatar.propTypes = {
  imageUrl: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  style: PropTypes.object,
  isOriginalSize: PropTypes.bool,
};
