import fetchUtils from '../utils/fetchUtils';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import fetch from 'isomorphic-fetch';
import qs from 'query-string';
import axios from 'axios';
import utmUtils from '../utils/utmUtils';
import storeUtils from '../utils/storeUtils';
import webUtils from '../utils/webUtils';
import _get from 'lodash/get';

const dao = {
  checkEmail: async email => {
    const response = await fetch(`/smb-frontend/support/check-email.action`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email}),
    });
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  checkSupportEmail: async (email, supportType) => {
    const response = await fetch(
      `/smb-frontend/support/check-support-email.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({email, supportType}),
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  createRequest: async (request, files) => {
    const data = new FormData();
    const fields = Object.keys(request);
    fields.forEach(field =>
      data.append(
        `support.${field}`,
        _isObject(request[field])
          ? JSON.stringify(request[field])
          : request[field]
      )
    );
    let fileIdx = 0;
    files &&
      files.forEach(file => file && data.append(`files[${fileIdx++}]`, file));
    return await fetchUtils
      .fetch(`/smb-frontend/support/create-request.action`, {
        method: 'POST',
        credentials: 'same-origin',
        body: data,
      })
      .then(result => console.log({result}));
  },
  createSupportRequest: async support => {
    const sourceUtm = utmUtils.getSupportSourceUtm();
    const refUrlFirst = storeUtils.getFirstUrlSource();
    const refUrlLast = storeUtils.getLastUrlSource();
    const response = await fetch(
      `/smb-frontend/support/create-request.action`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          support: {...support, sourceUtm, refUrlFirst, refUrlLast},
        }),
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
      statusText: response.statusText,
    });
  },
  querySupport: async supportId => {
    const response = await fetch(
      `/smb-frontend/support/query-support.action` + dao.buildQs({supportId}),
      {
        method: 'GET',
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  getUtmMedium: async medium => {
    return new Promise(resolve => {
      if (_isEmpty(medium) || medium.toLowerCase().indexOf('edm') === -1) {
        return resolve({medium: 'WEB'});
      }
      return resolve({medium: 'EDM'});
    });
  },
  checkColorReport: async supportId => {
    const response = await fetch(
      `/smb-frontend/support/check-color-report.action` +
        dao.buildQs({supportId}),
      {
        method: 'GET',
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  buildQs: (params = {}) => '?' + qs.stringify(params),
  updateSupport: async (supportId, isSubscribed) => {
    const response = await fetch(
      `/smb-frontend/support/update-support.action` +
        dao.buildQs({supportId, isSubscribed}),
      {
        method: 'GET',
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  ipCountry: async () => {
    if (webUtils.isCnDomain()) return 'CN';
    if (webUtils.isMakeupArUrl()) return 'IN';
    const res = await axios.get('/smb-frontend/location');
    if (_get(res, 'data')) return _get(res, 'data');
    return Promise.reject({
      status: res.status,
    });
  },
};

export default dao;
