import React from 'react';
import AsyncPage from './async';
import ShowocaseLayout from '../../../../components/layout/ShowocaseLayout';

export default [
  <ShowocaseLayout
    key="/business/showcase/face-analyzer/:type(attributes|facial-ratio)?"
    exact
    path="/business/showcase/face-analyzer/:type(attributes|facial-ratio)?"
    component={AsyncPage}
  />,
];
