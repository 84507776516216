import React, {useState} from 'react';
import PopupMenu1 from '../../content/popupmenu1';
import classnames from 'classnames';
import webUtils from '../../../utils/webUtils';
import {useLocation} from 'react-router-dom';
import GoogleSearch from '../googlesearch';
import {Trans} from 'react-i18next';
import _isEmpty from 'lodash/isEmpty';
import Link from '../../link';

export default function ConsumerMenuList({
  isNewHeader,
  menu,
  text,
  id,
  activeLink = '',
  setPopupMenu = () => {},
}) {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isActive = paths => webUtils.isSublink(location.pathname, paths);

  function renderArrowIcon(isUp) {
    const iconClass = isUp
      ? 'fa fa-caret-up pf-header-mobile-consumer-menus__icon'
      : 'fa fa-caret-down pf-header-mobile-consumer-menus__icon';

    return <i className={iconClass} />;
  }

  const renderLinks = menu => {
    return (
      !_isEmpty(menu) &&
      menu.map((m, i) => (
        <div
          key={'menu-' + i}
          className={classnames({
            'pf-header-mobile-consumer-menus__subitem': true,
            // 'pf-header-mobile-consumer-menus__subitem--active': isActive(
            //   location.href
            // ),
          })}
        >
          {console.log('aaa', m.link)}
          <Link
            isYce={m.isYce}
            yceKeyword={m.yceKeyword}
            onClick={() => setPopupMenu(false)}
            hash={m.hash}
            to={m.link}
          >
            {m.text}
          </Link>
        </div>
      ))
    );
  };
  if (webUtils.isMobile()) {
    return (
      <>
        <div
          className={classnames({
            'pf-header-mobile-consumer-menus__item': true,
            'pf-header-mobile-consumer-menus__item--opened': isOpen,
          })}
          id={id}
          onClick={() => setIsOpen(!isOpen)}
        >
          {text} {renderArrowIcon(activeLink)}
        </div>
        {isOpen && renderLinks(menu)}
      </>
    );
  }
  return (
    <PopupMenu1
      key={id}
      component={menu}
      theme={isNewHeader ? 'dark' : 'white'}
      placement="bottom-start"
      onChange={setIsOpen}
    >
      <div
        className={classnames({
          'pf-subheader-4-consumer__left-menu': true,
          'pf-subheader-4-consumer__left-menu--active': isActive(activeLink),
        })}
        id={id}
      >
        {text}{' '}
        {isOpen ? (
          <i className="fa fa-caret-up" />
        ) : (
          <i className="fa fa-caret-down" />
        )}
      </div>
    </PopupMenu1>
  );
}
