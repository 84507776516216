import React from 'react';
import AsyncPage from './async';
import DefaultConsumerLayout from '../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/appdownload/:defaultApp?"
    exact
    path="/consumer/appdownload/:defaultApp(ycf|ycn|ycp|ycv|ycc|ymk)?"
    component={AsyncPage}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'App download',
        item: '/consumer/appdownload/ymk',
      },
    ]}
  />,
];
