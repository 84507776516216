import faceai from './faceai/routes';
import live3dfacear from './live3dfacear/routes';
import makeupar from './makeupar/routes';
import skincarear from './skincarear/routes';
import agileface from './agileface/routes';
import agilehand from './agilehand/routes';

export default [
  faceai,
  live3dfacear,
  makeupar,
  skincarear,
  agileface,
  agilehand,
];
