import React from 'react';
import AsyncPage from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/business/user/sign-in"
    exact
    path="/business/user/sign-in"
    component={AsyncPage}
  />,
];
