import React from 'react';
import AsyncSdkPrivacy from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default (
    <DefaultBusinessLayout
        key="/business/SDK/privacy"
        exact
        path="/business/SDK/privacy"
        component={AsyncSdkPrivacy}
        breadcrumb={[
            {
                position: 1,
                name: 'Home',
                item: '/',
            },
            {
                position: 2,
                name: 'Business',
                item: '/business',
            },
            {
                position: 3,
                name: 'SDK Privacy',
                item: '/business/SDK/privacy',
            }
        ]}
    />
);
