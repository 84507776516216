import React from 'react';
import Link from '../../link';
import './index.css';

export default function ExtraButton({link, img, text}) {
  return (
    <div className="pf-y4b-smb-banner-awards">
      {link ? (
        <Link to={link}>
          <img alt="" src={img} />
        </Link>
      ) : (
        <div className="pf-y4b-smb-banner-awards-image">
          <img alt="" src={img} />
        </div>
      )}
      <div>{text}</div>
    </div>
  );
}
