import React from 'react';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import './MobileConsumerMenus.css';
import {useHistory} from 'react-router';
import {Trans, useTranslation} from 'react-i18next';
import {HashLink} from 'react-router-hash-link';
import './MobileNFTMenus.css';

export default function MobileNFTMenus({setPopupMenu}) {
  const params = new URLSearchParams(window.location.search);
  const is2b = params.get('is2b') === 'true';
  const {i18n} = useTranslation();
  const language =
    i18n.language === 'en' || !i18n.language
      ? ''
      : `/${i18n.language.replace('_', '-').toLowerCase()}`;

  return (
    <div className="pf-header-mobile-consumer-menus">
      {is2b && (
        <div
          className="nft-header-mobile-menus__item"
          onClick={() => setPopupMenu(false)}
        >
          <HashLink
            className={classnames({
              'nft-content-popupmenu1--active':
                window.location.href.indexOf('/nft#howitwork') !== -1,
              'nft-content-popupmenu1--white': true,
            })}
            to={`${language}/nft?is2b=${is2b}#howitwork`}
          >
            <Trans i18nKey="business:c.l.nft.header.how.it.works">
              How it works?
            </Trans>
          </HashLink>
        </div>
      )}
      <div
        className="nft-header-mobile-menus__item"
        onClick={() => setPopupMenu(false)}
      >
        <HashLink
          className={classnames({
            'nft-content-popupmenu1--active':
              window.location.href.indexOf('/nft#nftcollection') !== -1,
            'nft-content-popupmenu1--white': true,
          })}
          to={`${language}/nft?is2b=${is2b}#nftcollection`}
        >
          <Trans i18nKey="business:c.l.nft.header.nft.collection">
            NFT Collection
          </Trans>
        </HashLink>
      </div>
      <div
        className="nft-header-mobile-menus__item"
        onClick={() => setPopupMenu(false)}
      >
        <HashLink
          className={classnames({
            'nft-content-popupmenu1--active':
              window.location.href.indexOf('/nft#faq') !== -1,
            'nft-content-popupmenu1--white': true,
          })}
          to={`${language}/nft?is2b=${is2b}#faq`}
        >
          <Trans i18nKey="business:c.l.nft.header.faq">FAQ</Trans>
        </HashLink>
      </div>
      {is2b && (
        <div
          className="nft-header-mobile-menus__item"
          onClick={() => setPopupMenu(false)}
        >
          <HashLink
            className={classnames({
              'nft-content-popupmenu1--active':
                window.location.href.indexOf('/nft#contactus') !== -1,
              'nft-content-popupmenu1--white': true,
            })}
            to={`${language}/business/contact-us/sales`}
          >
            <Trans i18nKey="business:c.l.nft.header.contact.us">
              Contact Us
            </Trans>
          </HashLink>
        </div>
      )}
    </div>
  );
}
