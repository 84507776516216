import React from 'react';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import BeautyTechVirtualEventDetail from './async';

export default [
  <DefaultBusinessLayout
    key="/business/beauty-tech-forum-master-class/detail/:sessionId?"
    exact
    path="/business/beauty-tech-forum-master-class/detail/:sessionId?"
    component={props => <BeautyTechVirtualEventDetail {...props} />}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Master Series',
        item: '/business/beauty-tech-forum-master-class/detail',
      },
    ]}
  />,
  <DefaultBusinessLayout
    key="/business/beauty-tech-forum-master-class/detail/:sessionId?/:thankyou?"
    exact
    path="/business/beauty-tech-forum-master-class/detail/:sessionId?/:thankyou?"
    component={props => <BeautyTechVirtualEventDetail {...props} />}
  />,
  <DefaultBusinessLayout
    key="/business/global-beauty-tech-forum/detail/:sessionId?"
    exact
    path="/business/global-beauty-tech-forum/detail/:sessionId?"
    component={props => <BeautyTechVirtualEventDetail {...props} />}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'GBTF',
        item: '/business/global-beauty-tech-forum/detail',
      },
    ]}
  />,
];
