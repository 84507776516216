import ShowocaseLayout from "../../../../components/layout/ShowocaseLayout";
import AsyncPage from "./async";
import React from "react";

export default [
    <ShowocaseLayout
        key="/business/showcase/beard-style"
        exact
        path="/business/showcase/beard-style"
        component={AsyncPage}
    />,
];