import React from 'react';
import Link from '../../../../components/link';
import './ActionButton.css';
import {Trans} from 'react-i18next';
import {HashLink} from 'react-router-hash-link';
function ActionButton({
  actionLink,
  actionText,
  actionType,
  style = {},
  className = '',
  onClick = () => {},
  actionHref = '',
  actionWebinar,
}) {
  const content = (
    <>
      {!actionType && <span>{actionText}</span>}
      {(actionType === 'register' || actionType === 'gotowebinar') && (
        <span>
          <Trans i18nKey="business:p.b.b.home.btf.register.free">
            REGISTER FOR FREE
          </Trans>
        </span>
      )}
      {actionType === 'video' && (
        <>
          <span>
            <Trans i18nKey="business:p.b.b.v.replay">WATCH NOW</Trans>
          </span>{' '}
          <img
            alt=""
            src={require('../../../../assets/images/icon/btn_story_play.png')}
            style={{maxWidth: '26px', width: '100%'}}
          />
        </>
      )}
    </>
  );

  if (actionWebinar) {
    return (
        <HashLink to={actionWebinar}>
          <div className={`btfv-action-button ${className}`} style={style}>
            {content}
          </div>
        </HashLink>
    )
  }

  if (actionLink) {
    return (
      <Link to={actionLink}>
        <div className={`btfv-action-button ${className}`} style={style}>
          {content}
        </div>
      </Link>
    );
  }

  if (actionHref) {
    return (
      <a href={actionHref} target="_blank" rel="noopener noreferrer">
        <div className={`btfv-action-button ${className}`} style={style}>
          {content}
        </div>
      </a>
    );
  }

  return (
    <div
      className={`btfv-action-button ${className}`}
      style={style}
      onClick={onClick}
    >
      {content}
    </div>
  );
}

export default ActionButton;
