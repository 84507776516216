import React from 'react';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import AboutAsync from '../info/aboutus/async';
import AwardAsync from '../info/awards/async';
import EsgAsync from '../info/esg/async';

export default [
  <DefaultBusinessLayout
    key="/business/info/awards"
    exact
    path="/business/info/awards"
    component={AwardAsync}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Info',
        item: '/business/info',
      },
    ]}
  />,
  <DefaultBusinessLayout
    key="/business/info/aboutus"
    exact
    path="/business/info/aboutus"
    component={AboutAsync}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Info',
        item: '/business/info',
      },
    ]}
  />,
  <DefaultBusinessLayout
    key="/business/info/esg"
    exact
    path="/business/info/esg"
    component={EsgAsync}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Info',
        item: '/business/info',
      },
    ]}
    showContactSales={false}
    showDemoStore={false}
  />,
];
