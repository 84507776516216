import React from 'react';
import AsyncComponent from './async';
import DefaultCampaignLayout from '../../../../../components/layout/DefaultCampaignLayout';

export default [
  <DefaultCampaignLayout
    key="/business/campaign/smb"
    exact
    path="/business/campaign/smb"
    component={AsyncComponent}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'SMB Campaign',
        item: '/business/campaign/smb',
      },
    ]}
  />,
];
