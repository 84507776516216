import React from 'react';
import AsyncSmb from './index';
import {Route} from 'react-router-dom';

export default [
  <Route
    key="/business/solutions/online-service/campaign"
    exact
    path="/business/solutions/online-service/campaign"
    component={AsyncSmb}
  />,
];
