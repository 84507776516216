import React from 'react';
import {Route} from 'react-router-dom';
import AsyncPage from './async';

export default [
  <Route
    key="/business/services/in-store-consultation"
    exact
    path="/business/services/in-store-consultation"
    component={AsyncPage}
  />,
];
