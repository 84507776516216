import React, {useRef, useEffect, useCallback, useState} from 'react';
import {findDOMNode} from 'react-dom';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import screenfull from 'screenfull';
import './index.css';

export default function VideoPlayer({
  videoUrl = '',
  videoCover,
  autoPlay = true,
  fullscreen = false,
  onLoad,
  onCloseFullscreen = () => {},
  playing = true,
  controls = true,
  loop = false,
  muted = false,
  playsInline = true,
  poster,
}) {
  const [playYouKu, setPlayYouku] = useState(!videoCover);
  const doPlay = !!videoCover || autoPlay;
  const playerRef = useRef(null);
  const onScreenfullChange = useCallback(() => {
    if (!screenfull.isFullscreen) onCloseFullscreen();
  }, [onCloseFullscreen]);

  useEffect(() => {
    onLoad && onLoad();
  }, []);

  useEffect(() => {
    screenfull.enabled && screenfull.on('change', onScreenfullChange);
    if (
      fullscreen &&
      screenfull.enabled &&
      !screenfull.isFullscreen &&
      playerRef &&
      playerRef.current
    ) {
      screenfull.request(findDOMNode(playerRef.current));
    }
    return () => {
      screenfull.enabled && screenfull.off('change');
    };
  });
  return (
    <div className="video-player-wrapper">
      {videoUrl && videoUrl.indexOf('youku') !== -1 ? (
        <div>
          <div className="video-player-block-youku">
            {playYouKu ? (
              <iframe
                src={videoUrl}
                allowFullScreen
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              />
            ) : (
              <img
                className="video-player-block-youku-cover"
                alt="video-cover"
                src={videoCover}
                onClick={() => setPlayYouku(true)}
              />
            )}
          </div>
        </div>
      ) : (
        <ReactPlayer
          className="video-player-block"
          url={videoUrl}
          poster={poster}
          light={videoCover || false}
          playing={playing}
          autoPlay={doPlay}
          controls={controls}
          width="100%"
          height="100%"
          ref={playerRef}
          fileConfig={{attributes: {autoPlay: doPlay}}}
          playsinline={playsInline}
          loop={loop}
          muted={muted}
          fullscreen={'false'}
          config={{
            youtube: {
              playerVars: {
                modestbranding: 1,
                disablekb: true,
                autoplay: autoPlay ? 1 : 0,
                playsinline: 1,
                showinfo: 1,
                enablejsapi: 1,
                fs: 1,
                muted: muted ? 1 : 0,
              },
            },
          }}
        />
      )}
    </div>
  );
}

VideoPlayer.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  videoCover: PropTypes.string,
  autoPlay: PropTypes.bool,
  fullscreen: PropTypes.bool,
  onCloseFullscreen: PropTypes.func,
};
