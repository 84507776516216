import React from 'react';
import webUtils from '../../utils/webUtils';

const prefix = `${process.env.REACT_APP_IMAGE_PREFIX}`;

export default function Picture({
  className,
  platform = 'mobile',
  filename = '',
  alt = '',
  style,
  onLoad = () => {},
  small = false,
  ...props
}) {
  {
    if (!webUtils.isPictureTagSupported()) {
      return (
        <img
          as="image"
          alt={alt}
          className={className}
          src={getTargetFilename(filename, platform === 'mobile' ? 828 : 2732)}
          style={style}
          {...props}
        />
      );
    }
    if (small) {
      return (
        <picture>
          {platform === 'mobile'
            ? getMobileSources4Fallback(filename)
            : getDesktopSources4Fallback(filename)}
          <img
            as="image"
            className={className}
            alt={alt}
            style={style}
            src={`${prefix}/${filename}`}
            onLoad={onLoad}
            {...props}
          />
        </picture>
      );
    }
    return (
      <picture>
        {platform === 'mobile'
          ? getMobileSources(filename)
          : getDesktopSources(filename)}
        <img
          rel="preload"
          as="image"
          className={className}
          alt={alt}
          style={style}
          src={`${prefix}/${filename}`}
          onLoad={onLoad}
          {...props}
        />
      </picture>
    );
  }
}

const getExtension = filename => filename.split('.').pop();
const getName = filename => filename.substring(0, filename.lastIndexOf('.'));

const getTargetFilename = (filename, width) =>
  `${prefix}/${getName(filename)}-${width}.${getExtension(filename)}`;

// DO NOT change desktopSizes and mobileSizes, if you DO want to change this, need to adjust scripts/optimize and re-generate all the images.

const getDesktopSources4Fallback = filename => [
  <source
    key={`${filename}-s-1440`}
    srcSet={`${getTargetFilename(filename, 1440)}`}
    media="(max-width:1024px)"
  />,
  <source
    key={`${filename}-s-2732`}
    srcSet={`${getTargetFilename(filename, 2732)}`}
    media="(min-width:1024px)"
  />,
];

const getDesktopSources = filename => [
  <source
    key={`${filename}-s-768`}
    srcSet={`${getTargetFilename(filename, 768)}, ${getTargetFilename(
      filename,
      768
    )} 2x`}
    media="(max-width:375px)"
  />,
  <source
    key={`${filename}-s-768-2`}
    srcSet={`${getTargetFilename(filename, 768)}, ${getTargetFilename(
      filename,
      1024
    )} 2x`}
    media="(max-width:414px)"
  />,
  <source
    key={`${filename}-s-768-3`}
    srcSet={`${getTargetFilename(filename, 768)}, ${getTargetFilename(
      filename,
      1536
    )} 2x`}
    media="(max-width:768px)"
  />,
  <source
    key={`${filename}-s-1024`}
    srcSet={`${getTargetFilename(filename, 1024)}, ${getTargetFilename(
      filename,
      2048
    )} 2x`}
    media="(min-width:768px) and (max-width:1024px)"
  />,
  <source
    key={`${filename}-s-1280`}
    srcSet={`${getTargetFilename(filename, 1280)}`}
    media="(min-width:1024px) and (max-width:1280px)"
  />,
  <source
    key={`${filename}-s-1366`}
    srcSet={`${getTargetFilename(filename, 1366)}, ${getTargetFilename(
      filename,
      2732
    )} 2x`}
    media="(min-width:1280px) and (max-width:1366px)"
  />,
  <source
    key={`${filename}-s-1440`}
    srcSet={`${getTargetFilename(filename, 1440)}`}
    media="(min-width:1366px) and (max-width:1440px)"
  />,
  <source
    key={`${filename}-s-1536`}
    srcSet={`${getTargetFilename(filename, 1536)}`}
    media="(min-width:1440px) and (max-width:1536px)"
  />,
  <source
    key={`${filename}-s-1600`}
    srcSet={`${getTargetFilename(filename, 1600)}`}
    media="(min-width:1536px) and (max-width:1600px)"
  />,
  <source
    key={`${filename}-s-1920`}
    srcSet={`${getTargetFilename(filename, 1920)}`}
    media="(min-width:1600px) and (max-width:1920px)"
  />,
  <source
    key={`${filename}-s-2048`}
    srcSet={`${getTargetFilename(filename, 2048)}`}
    media="(min-width:1920px) and (max-width:2048px)"
  />,
  <source
    key={`${filename}-s-2732`}
    srcSet={`${getTargetFilename(filename, 2732)}`}
    media="(min-width:2048px)"
  />,
];

const getMobileSources4Fallback = filename => [
  <source
    key={`${filename}-s-414`}
    srcSet={`${getTargetFilename(filename, 414)}, ${getTargetFilename(
      filename,
      828
    )} 2x`}
    media="(max-width:414px)"
  />,
  <source
    key={`${filename}-s-828`}
    srcSet={`${getTargetFilename(filename, 828)}`}
    media="(min-width:414px)"
  />,
];

const getMobileSources = filename => [
  <source
    key={`${filename}-s-320`}
    srcSet={`${getTargetFilename(filename, 320)}, ${getTargetFilename(
      filename,
      640
    )} 2x`}
    media="(max-width:320px)"
  />,
  <source
    key={`${filename}-s-360`}
    srcSet={`${getTargetFilename(filename, 360)}, ${getTargetFilename(
      filename,
      720
    )} 2x`}
    media="(min-width:320px) and (max-width:360px)"
  />,
  <source
    key={`${filename}-s-375`}
    srcSet={`${getTargetFilename(filename, 375)}, ${getTargetFilename(
      filename,
      750
    )} 2x`}
    media="(min-width:360px) and (max-width:375px)"
  />,
  <source
    key={`${filename}-s-414`}
    srcSet={`${getTargetFilename(filename, 414)}, ${getTargetFilename(
      filename,
      828
    )} 2x`}
    media="(min-width:375px) and (max-width:414px)"
  />,
  <source
    key={`${filename}-s-640`}
    srcSet={`${getTargetFilename(filename, 640)}`}
    media="(min-width:414px) and (max-width:640px)"
  />,
  <source
    key={`${filename}-s-720`}
    srcSet={`${getTargetFilename(filename, 720)}`}
    media="(min-width:640px) and (max-width:720px)"
  />,
  <source
    key={`${filename}-s-750`}
    srcSet={`${getTargetFilename(filename, 750)}`}
    media="(min-width:720px) and (max-width:750px)"
  />,
  <source
    key={`${filename}-s-828`}
    srcSet={`${getTargetFilename(filename, 828)}`}
    media="(min-width:750px)"
  />,
];
