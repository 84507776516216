import React from 'react';
import {Trans} from 'react-i18next';

export default [
  {
    key: 'watches',
    path: '/business/showcase/watches',
    label: <Trans i18nKey="business:p.b.s.c.m.watches">WATCHES</Trans>,
    reload: true,
  },
  {
    key: 'stacking',
    path: '/business/showcase/set',
    label: <Trans i18nKey="business:p.b.s.c.m.stacking">STACKING</Trans>,
    reload: true,
  },
  {
    key: '3d-viewer',
    path: '/business/showcase/3d-viewer/home?index=1',
    label: <Trans i18nKey="business:p.b.s.c.m.3d.viewer">3D viewer</Trans>,
    reload: true,
  },
];
