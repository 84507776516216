import React from 'react';
import AsyncPartner from './async';
import DefaultBusinessLayout from '../../../components/layout/DefaultBusinessLayout';
import applied from './applied/routes';

export default [
  <DefaultBusinessLayout
    key="/business/partner/sign-up"
    exact
    path="/business/partner/sign-up"
    component={AsyncPartner}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Partner Sign-up',
        item: '/business/partner/sign-up',
      },
    ]}
  />,
  applied,
];
