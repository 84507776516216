import React from 'react';
import AsyncPage from './async';
import DefaultConsumerLayout from '../../../../components/layout/DefaultConsumerLayout';

export default [
  <DefaultConsumerLayout
    key="/consumer/contact-us"
    exact
    path="/consumer/contact-us/:type(media|support)?"
    component={AsyncPage}
    backgroundImage={require('../../../../assets/images/consumer/contact-us-bg.jpg')}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Consumer',
        item: '/consumer',
      },
      {
        position: 3,
        name: 'Contact us',
        item: '/consumer/contact-us',
      },
    ]}
  />,
];
