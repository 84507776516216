import React from 'react';
import {Redirect} from 'react-router';
import home from './home/routes';
import diagnostics from './diagnostics/routes';
import hddiagnostics from './hddiagnostics/routes';
import emulation from './emulation/routes';

export default [
  <Redirect
    key="/business/showcase/skincare/"
    exact
    from="/business/showcase/skincare/"
    to="/business/showcase/skincare/home"
  />,
  home,
  diagnostics,
  hddiagnostics,
  emulation,
];
