import React from 'react';
import AsyncSmb from '../smb/index';
import {Route, Redirect} from 'react-router-dom';

export default [
  <Route
    key="/business/solutions/online-service/eye-wear/:type?"
    exact
    path="/business/solutions/online-service/eye-wear/:type?"
    component={AsyncSmb}
  />,
  <Route
    key="/business/solutions/online-service/skincare-pro/:type?"
    exact
    path="/business/solutions/online-service/skincare-pro/:type?"
    component={AsyncSmb}
  />,
  <Route
    key="/business/solutions/online-service/makeup/:type?"
    exact
    path="/business/solutions/online-service/makeup/:type?"
    component={AsyncSmb}
  />,
  <Route
    key="/business/solutions/online-service/aesthetic-simulation/:type?"
    exact
    path="/business/solutions/online-service/aesthetic-simulation/:type?"
    component={AsyncSmb}
  />,
  <Redirect
    key="/business/solutions/online-service"
    exact
    from="/business/solutions/online-service"
    to="/business/solutions/online-service/makeup"
  />,
];
