import React, {useContext} from 'react';
import {AuthContext} from '../../auth';
import {Trans} from 'react-i18next';
import Avatar from '../../account/Avatar';
import authDao from '../../../dao/authDao';
import webUtils from '../../../utils/webUtils';

export default function MobileAvatarMenus() {
  const {
    accountSummary: {account, customer},
  } = useContext(AuthContext);

  return (
    <div className="pf-header-mobile-business-menus">
      <div
        className="pf-header-mobile-business-menus__item bg-white"
        style={{cursor: 'pointer'}}
        onClick={() => {
          customer.partner
            ? (window.location.href = webUtils.getSMBPartnerEnvReactAppUrl())
            : (window.location.href = `${webUtils.getSMBEnvReactAppUrl()}`);
        }}
      >
        <div className="d-flex flex-nowrap align-items-center">
          <Avatar
            imageUrl={account.imageUrl}
            width={30}
            height={30}
            style={{marginRight: '10px'}}
          />
          <span
            className="text-dark flex-grow-1"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >{`${account.firstName} ${account.lastName}`}</span>
        </div>
      </div>
      <div
        className="pf-header-mobile-business-menus__item bg-white"
        style={{cursor: 'pointer', marginBottom: '0', justifyContent: 'left'}}
        onClick={() => authDao.signOut().then(() => window.location.reload())}
      >
        <img
          alt="sign-out"
          src={require('../../../assets/images/account/sign_out.svg')}
          style={{marginTop: '-6px', marginLeft: '5px', marginRight: '10px'}}
        />
        <span className="text-dark">
          <Trans i18nKey="business:c.l.h.mobile.avatar.menus.log.out">
            Log out
          </Trans>
        </span>
      </div>
    </div>
  );
}
