import React from 'react';
import AsyncComponent from './async';
import DefaultCampaignLayout from '../../../../components/layout/DefaultCampaignLayout';

export default [
    <DefaultCampaignLayout
        key="/business/campaign/ai-skin-analysis"
        exact
        path="/business/campaign/ai-skin-analysis"
        component={AsyncComponent}
        breadcrumb={[
            {
                position: 1,
                name: 'Home',
                item: '/',
            },
            {
                position: 2,
                name: 'Business',
                item: '/business',
            },
            {
                position: 3,
                name: 'AI Skin Analysis',
                item: '/business/campaign/ai-skin-analysis',
            },
        ]}
    />,
    <DefaultCampaignLayout
        key="/business/campaign/skin-analysis"
        exact
        path="/business/campaign/skin-analysis"
        component={AsyncComponent}
        breadcrumb={[
            {
                position: 1,
                name: 'Home',
                item: '/',
            },
            {
                position: 2,
                name: 'Business',
                item: '/business',
            },
            {
                position: 3,
                name: 'Skin Analysis',
                item: '/business/campaign/skin-analysis',
            },
        ]}
    />,
];
