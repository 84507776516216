import React, {useState, useEffect} from 'react';
import FormattedDate from '../../../../../../components/FormattedDate';
import classnames from 'classnames';
import './index.css';
import useWindowWidth from '../../../../../../components/hooks/useWindowWidth';
import webUtils from '../../../../../../utils/webUtils';
import Parser from 'react-html-parser';
import _SortUniqBy from 'lodash/sortedUniqBy';
import _isEmpty from 'lodash/isEmpty';

export default function Sec4List({data, speakerCircle, lang}) {
  const width = useWindowWidth();
  const [open, setOpen] = useState(true);
  const showSpeakerBtn =
    data.sessionSpeakers.length > 0 ||
    (data.sessionSubTitle && data.sessionSubTitle !== '&nbsp;');
  const openBtn = (
    <div
      className={classnames({
        'pf-gbtf--event-sec4-list--btn': true,
        'pf-gbtf--event-sec4-list--btn--active': open,
      })}
    />
  );
  const timezone = {
    enu: 'ET',
    jpn: 'JST',
    fra: 'CET',
  };
  return (
    <div
      className={classnames({
        'pf-gbtf--event-sec4-list': true,
        'pf-gbtf--event-sec4-list--hover': showSpeakerBtn,
      })}
      onClick={() => {
        if (showSpeakerBtn) setOpen(!open);
      }}
    >
      <div className="pf-gbtf--event-sec4-list--content">
        <div className="pf-gbtf--event-sec4-list--date">
          <FormattedDate
            type="estMin"
            time={webUtils.convertToUst(data.sessionStartDate)}
          />{' '}
          -{' '}
          <FormattedDate
            type="estMin"
            time={webUtils.convertToUst(data.sessionEndDate)}
          />{' '}
          {/*{timezone[lang]}*/}
        </div>
        <div className="pf-gbtf--event-sec4-list--info">
          <div className="pf-gbtf--event-sec4-list--info-title">
            <div className="pf-gbtf--event-sec4-list--info-title-container">
              {data.sessionTitle}
              {open &&
                data.sessionSubTitle &&
                data.sessionSubTitle !== '&nbsp;' && (
                  <div className="pf-gbtf--event-sec4-list--info-subtitle">
                    {Parser(data.sessionSubTitle)}
                  </div>
                )}
            </div>
            {width <= 600 && showSpeakerBtn && <>{openBtn}</>}
          </div>
          {open && !_isEmpty(data.sessionSpeakers) && (
            <div className="pf-gbtf--event-sec4-list--info-container">
              {data.sessionSpeakers.map((s, index) => (
                <div
                  key={`gbtf-event-sec4-list${index}`}
                  style={{
                    display: width > 600 ? 'flex' : 'block',
                    alignItems: 'center',
                  }}
                >
                  {speakerCircle(
                    s.speaker.speakerImageUrl,
                    100,
                    {margin: width <= 600 ? '0 auto 10px' : 'initial'},
                    4
                  )}
                  <div className="gbtf--event-speaker--cover--list--speaker">
                    {s.speaker.brandImageUrl && (
                      <div className="gbtf--event-speaker--cover--list--speaker-img">
                        <img alt="" src={s.speaker.brandImageUrl} />
                      </div>
                    )}
                    <div>{s.speaker.speakerName}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {!open &&
            !_isEmpty(
              _SortUniqBy(data.sessionSpeakers, `speaker.brandName`)
            ) && (
              <div className="pf-gbtf--event-sec4-list--brandlogo-container">
                {_SortUniqBy(data.sessionSpeakers, `speaker.brandName`).map(
                  (s, index) => (
                    <div key={`${data.sessionTitle}${index}`}>
                      {s.speaker.brandImageUrl && (
                        <img alt="" src={s.speaker.brandImageUrl} />
                      )}
                    </div>
                  )
                )}
              </div>
            )}
        </div>
      </div>
      {showSpeakerBtn && width > 600 && <>{openBtn}</>}
    </div>
  );
}
