import React from 'react';
import AsyncDetail from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default [
  <DefaultBusinessLayout
    key="/:type(business)/news/detail/:id?"
    exact
    path="/:type(business)/news/detail/:id?"
    component={AsyncDetail}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'news_title',
        item: '/business/news/detail',
      },
    ]}
  />,
    <DefaultBusinessLayout
        key="/:type(business)/news/:newsHandle?"
        exact
        path="/:type(business)/news/:newsHandle?"
        component={AsyncDetail}
        breadcrumb={[
            {
                position: 1,
                name: 'Home',
                item: '/',
            },
            {
                position: 2,
                name: 'Business',
                item: '/business',
            },
            {
                position: 3,
                name: 'news_title',
                item: '/business/news',
            },
        ]}
    />,
  <DefaultBusinessLayout
    key="/:type(business)/news/preview/:id?"
    exact
    path="/:type(business)/news/preview/:id?"
    component={AsyncDetail}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'news_title',
        item: '/business/news/preview',
      },
    ]}
  />,
];
