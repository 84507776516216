import Loadable from '../../../../components/loadable';
import prerenderedLoadable from '../../../../components/prerender';

const Page = Loadable(() =>
  import(
    /* webpackChunkName: "business-services-wechat-mini-program" */ './index'
  )
);

export default prerenderedLoadable(Page);
