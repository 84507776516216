import fetch from 'isomorphic-fetch';
import qs from 'query-string';

const strapiDao = {
  getProductPageByVuid: async params => {
    const url =
      `/smb-frontend/strapi/query-content-by-vuid.action` +
      strapiDao.buildQs(params);
    if (window.snapStore && window.snapStore[url]) return window.snapStore[url];
    const response = await fetch(url, {
      method: 'GET',
    });
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  getProductPageById: async params => {
    const response = await fetch(
      `/smb-frontend/strapi/query-content-by-id.action` +
        strapiDao.buildQs(params),
      {
        method: 'GET',
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  getProductPageByLanguage: async (params, pfFormId) => {
    const header = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (pfFormId) {
      header['cookie'] = pfFormId;
    }
    const url =
      `/smb-frontend/strapi/query-content-by-language.action` +
      strapiDao.buildQs(params);
    if (window.snapStore) {
      return window.snapStore[url];
    }
    const response = await fetch(url, {
      method: 'GET',
      headers: header,
    });
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  getProductPageByFormId: async params => {
    const response = await fetch(
      `/smb-frontend/strapi/query-content-by-form-id.action` +
        strapiDao.buildQs(params),
      {
        method: 'GET',
      }
    );
    if (response.ok) return await response.json();
    return Promise.reject({
      status: response.status,
    });
  },
  buildQs: (params = {}) => '?' + qs.stringify(params),
};

export default strapiDao;
