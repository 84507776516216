import React from 'react';
import DefaultBusinessLayout from '../../../components/layout/DefaultBusinessLayout';
import EducationSeries from './home/async';
import EducationSeriesDetail from './detail/async';

export default [
  <DefaultBusinessLayout
    key="/business/education-series/detail/:sessionId?"
    exact
    path="/business/education-series/detail/:sessionId?"
    component={props => <EducationSeriesDetail {...props} />}
    breadcrumb={[
      {
        position: 1,
        name: 'Home',
        item: '/',
      },
      {
        position: 2,
        name: 'Business',
        item: '/business',
      },
      {
        position: 3,
        name: 'Education Series',
        item: '/business/education-series/detail',
      },
    ]}
  />,
  <DefaultBusinessLayout
    key="/business/education-series"
    exact
    path="/business/education-series"
    component={props => <EducationSeries {...props} />}
    breadcrumb={[
        {
            position: 1,
            name: 'Home',
            item: '/',
        },
        {
            position: 2,
            name: 'Business',
            item: '/business',
        },
        {
            position: 3,
            name: 'Education Series',
            item: '/business/education-series',
        },
    ]}
  />,
];
