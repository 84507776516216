import React from 'react';
import AsyncList from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';
import BlankLayout from '../../../../components/layout/BlankLayout';

export default [
  <DefaultBusinessLayout
    key="/business/successstory/detail/:id?"
    exact
    path="/business/successstory/detail/:id?"
    component={AsyncList}
  />,
  <DefaultBusinessLayout
    key="/business/successstory/:storyHandle"
    exact
    path="/business/successstory/:storyHandle"
    component={AsyncList}
  />,
  <BlankLayout
    key="/business/iframe/successstory/detail/:id?"
    exact
    path="/business/iframe/successstory/detail/:id?"
    component={AsyncList}
  />,
  <BlankLayout
    key="/business/iframe/successstory/:storyHandle"
    exact
    path="/business/iframe/successstory/:storyHandle"
    component={AsyncList}
  />,
];
