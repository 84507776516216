import React from 'react';
import classnames from 'classnames';
import './index.css';
export default function Container({
  children,
  style = {},
  className = '',
  mobileType = false,
  id = '',
}) {
  return (
    <div
      id={id}
      style={style}
      className={classnames({
        'pf-layout-container': true,
        'pf-layout-container--mobile': mobileType,
        [className]: !!className,
      })}
    >
      {children}
    </div>
  );
}
