import React from 'react';
import GlobalBeautyTechForum from './index';
import {Route} from 'react-router-dom';

export default [
  <Route
    key="/business/global-beauty-tech-forum"
    exact
    path="/business/global-beauty-tech-forum/:gbtfLang(|fra|enu|jpn)?"
    component={GlobalBeautyTechForum}
  />,
  <Route
    key="/business/global-beauty-tech-forum"
    exact
    path="/business/global-beauty-tech-forum/:courseKey"
    component={GlobalBeautyTechForum}
  />,
];
