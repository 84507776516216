import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import classnames from 'classnames';
import useReactRouter from 'use-react-router';
import webUtils from '../../../utils/webUtils';
import Link from '../../../components/link';
import Container from '../../../components/layout/container';
import './Resources.css';
import './Menus.css';
import headerUtils from '../../../utils/headerUtils';
import _isEmpty from 'lodash/isEmpty';

const cover =
  'https://plugins-media.makeupar.com/smb/story/2024-01-26/ff150b47-54e7-4820-8dea-497da6543a49.jpg';
export const fixedData = {
  post: {
    en: {
      imageUrl: cover,
      pathname: '/business/blog/general/the-complete-guide-to-beauty-tech',
      title: 'Beauty Tech: The Complete Guide 2024',
    },
    fr: {
      imageUrl: cover,
      pathname: '/business/blog/general/le-guide-complet-de-la-beauty-tech',
      title: 'Le guide complet de la Beauty Tech',
    },
    ja: {
      imageUrl: cover,
      pathname: '/business/blog/general/the-complete-guide-to-beauty-tech-ja',
      title: '【保存版】2024年ビューティーテック導入・活用完全ガイド',
    },
    'zh-cn': {
      imageUrl: cover,
      pathname: '/business/blog/general/the-complete-guide-to-beauty-tech',
      title: '美妝科技：2024 年趨勢報告',
    },
    ko: {
      imageUrl: cover,
      pathname: '/business/blog/general/the-complete-guide-to-beauty-tech',
      title: '뷰티 테크: 2024년 완벽 가이드',
    },
    de: {
      imageUrl: cover,
      pathname:
        '/business/blog/general/der-komplette-beauty-tech-leitfaden-2023',
      title: 'Beauty Tech: Der komplette Leitfaden 2024',
    },
    it: {
      imageUrl: cover,
      pathname: '/business/blog/general/Beauty-Tech-La-Guida-Completa-2023',
      title: 'Beauty Tech: La guida completa 2024',
    },
    es: {
      imageUrl: cover,
      pathname:
        '/business/blog/general/la-guia-completa-de-la-innovacion-en-la-industria-cosmetica',
      title: 'Tecnología de belleza: Guía completa 2024',
    },
    'zh-tw': {
      imageUrl: cover,
      pathname: '/business/blog/general/the-complete-guide-to-beauty-tech',
      title: '美妝科技：2024 年趨勢報告',
    },
  },
  video: {
    en: {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/73',
      title: 'The Next Generation of YouCam’s AI Skin Diagnostic Solution',
    },
    fr: {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/73',
      title: 'The Next Generation of YouCam’s AI Skin Diagnostic Solution',
    },
    ja: {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/29',
      title: 'AI肌分析機能の紹介',
    },
    'zh-cn': {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/76',
      title: '新一代玩美AI肌肤诊断方案',
    },
    de: {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/73',
      title: 'Die nächste Generation der KI-Hautdiagnoselösung von YouCam',
    },
    es: {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/73',
      title:
        'La próxima generación de la solución de diagnóstico de la piel con IA de YouCam',
    },
    it: {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/73',
      title:
        'La Nuova Generazione della Soluzione AI di Diagnostica Pelle YouCam',
    },
    ko: {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/73',
      title: '차세대 YouCam AI 피부 진단 솔루션',
    },
    'zh-tw': {
      imageUrl:
        'https://plugins-media.makeupar.com/smb/story/2022-01-26/6e58686b-9de4-42f0-a7c2-690bf963a365.png',
      pathname: '/business/video/76',
      title: '新世代AI 肌膚檢測科技服務',
    },
  },
};

export default function Resources({onClick}) {
  const {location} = useReactRouter();
  const {i18n} = useTranslation();
  const isActive = paths => webUtils.isSublink(location.pathname, paths);
  const isBtfActive = paths =>
    webUtils.isSameLink(location.pathname, paths, i18n.language);
  const [showBtfv, setShowBtfv] = useState(true);
  let resources = headerUtils.resource(i18n.language).filter(r => r);
  const btfList = headerUtils.btflist(i18n.language).filter(btf => btf);
  const btfvList = headerUtils.btfvList(i18n.language).filter(btfv => btfv);

  const expandBtfv = () => setShowBtfv(true);
  const collapseBtfv = () => setShowBtfv(false);
  const language = i18n.language
    ? i18n.language.toLowerCase().replace('_', '-')
    : 'en';

  return (
    <div className="pf-subheader-pop-menu" style={{width: '100vw', padding: 0}}>
      <Container
        className="pf-subheader-pop-menu__container"
        style={{maxWidth: '85%'}}
      >
        <div className="subheader-resources__left">
          <div className="subheader-resources__featured">
            <Link
              to={fixedData.post[language] && fixedData.post[language].pathname}
              className="subheader-resources__featured__img"
              style={{
                backgroundImage: `url(${fixedData.post[language] &&
                  fixedData.post[language].imageUrl})`,
              }}
              onClick={onClick}
            />
            <div className="subheader-resources__featured__text">
              <div>
                <div className="subheader-resources__featured__type">
                  <Trans i18nKey="business:c.l.s.resources.featured.post">
                    Featured Blog Post
                  </Trans>
                </div>
                <Link
                  to={
                    fixedData.post[language] &&
                    fixedData.post[language].pathname
                  }
                  className="subheader-resources__featured__title"
                  onClick={onClick}
                >
                  {fixedData.post[language] && fixedData.post[language].title}
                </Link>
              </div>
              <Link
                className="subheader-resources__featured__link"
                to="/business/blog"
                onClick={onClick}
              >
                <Trans i18nKey="business:c.l.s.resources.more.blog.posts">
                  More Blog Posts
                </Trans>{' '}
                >
              </Link>
            </div>
          </div>
          <div className="subheader-resources__featured">
            <Link
              to={
                fixedData.video[language] && fixedData.video[language].pathname
              }
              className="subheader-resources__featured__img"
              style={{
                backgroundImage: `url(${fixedData.video[language] &&
                  fixedData.video[language].imageUrl})`,
              }}
              onClick={onClick}
            />
            <div className="subheader-resources__featured__text">
              <div>
                <div className="subheader-resources__featured__type">
                  <Trans i18nKey="business:c.l.s.resources.featured.video">
                    Featured Video
                  </Trans>
                </div>
                <Link
                  to={
                    fixedData.video[language] &&
                    fixedData.video[language].pathname
                  }
                  className="subheader-resources__featured__title"
                  onClick={onClick}
                >
                  {fixedData.video[language] && fixedData.video[language].title}
                </Link>
              </div>
              <Link
                to="/business/videos"
                className="subheader-resources__featured__link"
                onClick={onClick}
              >
                <Trans i18nKey="business:c.l.s.resources.more.video">
                  More Videos
                </Trans>{' '}
                >
              </Link>
            </div>
          </div>
        </div>
        <div className="pf-subheader-pop-menu__right subheader-resources__right">
          <div className="pf-subheader-pop-menu__menus subheader-resources__group">
            {!_isEmpty(resources) &&
              resources.map((m, i) => (
                <div key={`res-${i}`}>
                  <Link
                    key={'menu-' + i}
                    to={m.link}
                    className={classnames({
                      'pf-subheader-pop-menu__menus--active': isActive(
                        m.sublinks || m.link
                      ),
                      'subheader-resources__link': true,
                    })}
                    onClick={onClick}
                  >
                    {m.text}
                  </Link>
                </div>
              ))}
          </div>
          <div className="pf-subheader-pop-menu__menus subheader-resources__group">
            {!_isEmpty(btfList) &&
              btfList.map((btf, i) => (
                <div
                  key={`res-${i}`}
                  onMouseEnter={
                    btf.id === 'menu.business.btfVirtual'
                      ? expandBtfv
                      : collapseBtfv
                  }
                  onMouseLeave={
                    btf.id === 'menu.business.btfVirtual'
                      ? collapseBtfv
                      : () => {}
                  }
                >
                  {btf.link ? (
                    <Link
                      key={'btf-' + i}
                      to={btf.link}
                      className={classnames({
                        'pf-subheader-pop-menu__menus--active': isBtfActive(
                          btf.link
                        ),
                        'subheader-resources__link': true,
                      })}
                      style={{
                        // whiteSpace: width >= 1280 && 'nowrap',
                        fontSize: '16px',
                      }}
                      onMouseEnter={
                        btf.id === 'menu.business.btfVirtual'
                          ? expandBtfv
                          : collapseBtfv
                      }
                      onClick={onClick}
                    >
                      {btf.text}
                      {i18n.language !== 'en' &&
                        btf.id === 'menu.business.btfVirtual' && (
                          <img
                            width="20"
                            alt=""
                            src={
                              showBtfv
                                ? require('../../../assets/images/icon/arrow_s_up.svg')
                                : require('../../../assets/images/icon/arrow_s_down.svg')
                            }
                          />
                        )}
                    </Link>
                  ) : (
                    <div
                      key={'btf-' + i}
                      className={classnames({
                        'pf-subheader-pop-menu__menus--active': isBtfActive(
                          btf.link
                        ),
                        'subheader-resources__link': true,
                        'pf-subheader-pop-menu__menus--hover': true,
                      })}
                      style={{
                        // whiteSpace: width >= 1280 && 'nowrap',
                        fontSize: '16px',
                      }}
                      onMouseEnter={
                        btf.id === 'menu.business.btfVirtual'
                          ? expandBtfv
                          : collapseBtfv
                      }
                      onClick={onClick}
                    >
                      {btf.text}
                      {i18n.language !== 'en' &&
                        btf.id === 'menu.business.btfVirtual' && (
                          <img
                            width="20"
                            alt=""
                            src={
                              showBtfv
                                ? require('../../../assets/images/icon/arrow_s_up.svg')
                                : require('../../../assets/images/icon/arrow_s_down.svg')
                            }
                          />
                        )}
                    </div>
                  )}
                  {i18n.language !== 'en' &&
                    btf.id === 'menu.business.btfVirtual' && (
                      <div className="subheader-resources__link__sub">
                        {i18n.language !== 'en' &&
                          showBtfv &&
                          btfvList.map((m, i) => (
                            <Link
                              key={'menu-' + i}
                              to={m.link}
                              className={classnames({
                                'pf-subheader-pop-menu__menus--active': isActive(
                                  m.link
                                ),
                              })}
                              onClick={onClick}
                            >
                              {m.text}
                            </Link>
                          ))}
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
