import React from 'react';
import Async from './async';
import DefaultBusinessLayout from '../../../../components/layout/DefaultBusinessLayout';

export default (
  <DefaultBusinessLayout
    key="/business/impressum"
    exact
    path="/business/impressum"
    component={Async}
  />
);
