import qs from 'query-string';
import _omitBy from 'lodash/omitBy';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';

const utils = {
  triggerMemberRegisterAffiliate: customerId => {
    if (_isEmpty(customerId)) {
      return;
    }

    return utils.loadScript(
      '//track.bannerbridge.net/lead_js.php',
      [18240],
      customerId
    );
  },
  loadScript: (
    src = undefined,
    proID = [18240],
    orderid = undefined,
    trans_amount = undefined,
    productID = undefined
  ) => {
    if (_isEmpty(src)) {
      return;
    }

    const params = _omitBy(
      {proID, orderid, trans_amount, productID},
      _isUndefined
    );

    return new Promise(function(resolve, reject) {
      const script = document.createElement('script');
      script.src = `${src}?${qs.stringify({...params})}`;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  },
};

export default utils;
