import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import PinkButton from '../../../../../components/button/pinkbutton';

export default function RelatedCourseButton({
  relatedCourse,
  width,
  isGbtf = false,
}) {
  const btnStyle = {
    padding: width > 600 ? '0 40px' : 0,
    margin: width > 600 ? '0 30px' : '0 auto 20px',
    wordWrap: 'break-word',
    width: width > 600 ? 'initial' : '100%',
  };

  const courseType = isGbtf
    ? 'global-beauty-tech-forum'
    : 'beauty-tech-forum-master-class';

  if (
    _isEmpty(relatedCourse) &&
    _isEmpty(relatedCourse.filter(s => s.key && s.text))
  )
    return <div id={'emptyRelatedCourse'} />;

  return (
    <div className="btfv-archive-button-wrapper">
      {relatedCourse
        .filter(s => s.key && s.text)
        .map((s, index) => (
          <div
            key={`relatedCourse${index}`}
            className="btfv-archive-button-wrapper-item"
          >
            <PinkButton
              key={`master-series-bottom-button${index}`}
              style={btnStyle}
              link={`/business/${courseType}/${s.key}`}
              text={s.text}
              square={true}
            />
          </div>
        ))}
    </div>
  );
}
