import React from 'react';
import AsyncPage from './async';
import {Redirect, Route} from 'react-router-dom';

export default [
  <Route
    key="/business/products/3d-vto-format"
    exact
    path="/business/products/3d-vto-format"
    component={AsyncPage}
  />,
  <Redirect
    from="/business/campaign/3d-vto-format"
    to="/business/products/3d-vto-format"
    component={AsyncPage}
  />,
];
