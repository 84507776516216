import React from 'react';
import Link from '../../../components/link';
import './Logo.css';
import Picture from '../../picture';
import webUtils from '../../../utils/webUtils';
import useWindowWidth from '../../hooks/useWindowWidth';

export default function Logo({isBusiness, setPopupMenu = () => {}}) {
  const width = useWindowWidth();

  return (
    <div className="pf-header__logo" onClick={() => setPopupMenu(false)}>
      <span className="helper" />
      <Link to={isBusiness ? '/business' : '/consumer'}>
        <img
          alt="logo"
          src={require('../../../assets/images/icon/PERFECT_logo_newcolor.svg')}
        />
      </Link>
    </div>
  );
}
