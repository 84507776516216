import React, {useRef, useState} from 'react';
import _isEmpty from 'lodash/isEmpty';
import Container from '../../../../layout/container';
import {Trans} from 'react-i18next';
import classnames from 'classnames';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import useWindowWidth from '../../../../hooks/useWindowWidth';
import _get from 'lodash/get';
import StrapiUtils from '../../../../strapi/strapiUtils';

export default function ProductHeader({
  buttons,
  setProductType,
  productType,
  show,
  setIsSliding,
}) {
  const width = useWindowWidth();
  const swiperRef = useRef(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [productButton, setProductButton] = useState(false);
  function changeProductType(type) {
    setIsSliding(true);
    setProductType({
      label: type,
      value: StrapiUtils.getAnchor(type),
    });
    const elem = document.querySelector(`#${StrapiUtils.getAnchor(type)}`);
    elem &&
      elem.scrollIntoView({
        behavior: width > 600 ? 'smooth' : 'instant',
        block: 'start',
      });
    setTimeout(() => setIsSliding(false), 1000);
    if (width <= 600) setProductButton(false);
  }
  if (width <= 600) {
    return (
      <div
        className="multiple-buttons--container"
        style={{
          display: show ? 'block' : 'none',
        }}
      >
        <Container mobileType={true} className="multiple-buttons--container-mb">
          <div className="product-floating--title">
            <Trans i18nKey="business:p.c.t.fade.header.explore.solutions">
              Explore Solutions:
            </Trans>
          </div>
          <div className="product-floating--active-btn">
            <div>{_get(productType, 'label')}</div>
            <img
              style={{
                transform: productButton ? 'rotate(180deg)' : 'rotate(0deg)',
              }}
              alt=""
              src={require('../../../../../assets/images/icon/btn_arrow_right.svg')}
              onClick={() => {
                setProductButton(!productButton);
              }}
            />
          </div>
        </Container>
        <div className="product-floating--banner-divider" />
        {productButton && (
          <Container
            className="product-link--buttons-container"
            mobileType={true}
          >
            {!_isEmpty(buttons) &&
              buttons.map((s, i) => (
                <div
                  key={`mb-floating-button${i}`}
                  className={classnames({
                    'product-link--buttons-active':
                      s === _get(productType, 'label'),
                  })}
                  onClick={() => changeProductType(s)}
                >
                  {s}
                </div>
              ))}
          </Container>
        )}
      </div>
    );
  }
  return (
    <div
      className="multiple-buttons--container"
      style={{
        display: show ? 'block' : 'none',
      }}
    >
      {!_isEmpty(buttons) && (
        <Container style={{display: 'flex', alignItems: 'center'}}>
          <div className="product-floating--title">
            <Trans i18nKey="business:p.c.t.fade.header.explore.solutions">
              Explore Solutions:
            </Trans>
          </div>
          <div
            className={classnames({
              'product-floating--btn': true,
              'product-floating--previous-btn': true,
              'product-floating--btn-inactive': !showPrev,
            })}
            onClick={() => {
              swiperRef.current.translateTo(1000);
            }}
          />
          <div style={{overflowX: 'hidden', margin: '0 40px'}}>
            <Swiper
              className="enterprise-swiper"
              // modules={[Pagination]}
              slidesPerView={'auto'}
              spaceBetween={40}
              modules={[Navigation]}
              onBeforeInit={swiper => {
                swiperRef.current = swiper;
              }}
              onSlideChange={swiper => {
                setShowPrev(!swiper.isBeginning);
                setShowNext(!swiper.isEnd);
              }}
              // navigation={{
              //   nextEl: '.product-floating--next-btn',
              //   prevEl: '.product-floating--previous-btn',
              // }}
              // freeMode={true}
              // onReachBeginning={() => {}}
              // onReachEnd={() => {
              //   setShowNext(false);
              // }}
            >
              {buttons.map((s, i) => (
                <SwiperSlide>
                  <div
                    className={classnames({
                      'floating-banner--btn': true,
                      'floating-banner--btn-active':
                        s === _get(productType, 'label'),
                    })}
                    key={`floating-button${i}`}
                    onClick={() => {
                      changeProductType(s);
                    }}
                  >
                    {s}
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div
            className={classnames({
              'product-floating--btn': true,
              'product-floating--next-btn': true,
              'product-floating--btn-inactive': !showNext,
            })}
            onClick={() => {
              swiperRef.current.slideNext();
            }}
          />
        </Container>
      )}
    </div>
  );
}
