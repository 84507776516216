import React, {useRef, useEffect} from 'react';

export default function PardotForm({
  sendPardot,
  input,
  iframe,
  action,
  target,
}) {
  const ref = useRef(null);
  const hiddenStyle = {
    visibility: 'hidden',
    height: 0,
    border: 0,
    padding: 0,
    width: 0,
  };

  useEffect(() => {
    if (sendPardot) {
      ref && ref.current && ref.current.submit();
    }
  }, [sendPardot]);

  return (
    <div style={hiddenStyle}>
      {target && <iframe name={target} />}
      <iframe
        src={iframe}
        width="100%"
        height="500"
        type="text/html"
        frameBorder="0"
        style={hiddenStyle}
      />
      <form ref={ref} action={action} method="post" target={target}>
        {input.map(s => (
          <input key={s.name} name={s.name} value={s.value} readOnly />
        ))}
      </form>
    </div>
  );
}
