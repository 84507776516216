const key = '6LeH8-kUAAAAAOVJWQvJWKHC5HuR3aoU7zD6T_Wv';

const utils = {
  getToken: () => {
    return utils.initRecaptcha().then(t => t);
  },
  initRecaptcha: () => {
    const promise = new Promise(resolve => {
      const script = document.createElement('script');
      script.src = `//www.google.com/recaptcha/api.js?render=${key}`;
      script.addEventListener('load', handleLoaded);
      document.body.appendChild(script);

      function handleLoaded() {
        window.grecaptcha.ready(r => {
          window.grecaptcha
            .execute(key, {action: 'sign_in'})
            .then(token => resolve(token));
        });
      }
    });

    return promise.then(r => r);
  },
};

export default utils;
