import React from 'react';
import smbLinks from '../../../utils/config/smbLinks';
import {Trans, useTranslation} from 'react-i18next';
import './smbmenus.css';
import PFLink from '../../link';
import classnames from 'classnames';
import webUtils from '../../../utils/webUtils';
import _get from 'lodash/get';
import headerUtils from '../../../utils/headerUtils';
import _isEmpty from 'lodash/isEmpty';

export default function SmbMenus() {
  const {i18n} = useTranslation();
  const card1 = (index, data) => {
    return (
      <PFLink
        className={classnames({
          'pf-subheader-menu--card--active':
            window.location.href.indexOf(data.activeLink) !== -1,
        })}
        to={data.link}
        key={`smb-card${index}`}
      >
        <div className="pf-subheader-menu--card-icon" />
        <div className="pf-subheader-menu--card-title">{data.text}</div>
        <div className="pf-subheader-menu--card-desc">{data.desc}</div>
      </PFLink>
    );
  };

  const card2 = (key, data) => {
    return (
      <PFLink
        className={classnames({
          'pf-subheader-menu--demostore-menu-container': true,
        })}
        to={webUtils.goToOnlineService(
          i18n,
          data.functionType,
          data.solutionType
        )}
        isNewTab={true}
      >
        <div
          style={{
            backgroundImage: data.demoIcon
              ? `url(${data.demoIcon})`
              : 'initial',
          }}
          className="pf-subheader-menu--demostore-menu-icon"
        />
        <div className="pf-subheader-menu--demostore-menu-label">
          {data.demoTitle}
        </div>
        <img
          alt=""
          width={24}
          height={24}
          src={
            'https://plugins-media.makeupar.com/smb/story/2023-01-30/4308e7d9-88cb-46f4-9872-3f5415f6e8d0.png'
          }
        />
      </PFLink>
    );
  };

  if (_isEmpty(headerUtils.onlineService)) return <div />;

  return (
    <div className="pf-subheader-menu pf-subheader-smb-menu--container">
      <div className="pf-subheader-smb-left-menu--container">
        <div className="pf-subheader-menu--title">
          <Trans i18nKey="business:c.l.s.smb.menus.left.title">
            Self-Service Solutions
          </Trans>
        </div>
        <div className="pf-subheader-menu--desc">
          <Trans i18nKey="business:c.l.s.smb.menus.left.desc">
            Self-serve AR try-on and AI analysis solutions to improve customer
            engagements and conversions
          </Trans>
        </div>
        <div className="pf-subheader-menu--card-container">
          {headerUtils.onlineService.map((s, i) => (
            <>{card1(i, s)}</>
          ))}
        </div>
      </div>
      <div className="smb-menu--divider" />
      <div className="pf-subheader-smb-right-menu--container">
        <div className="pf-subheader-menu--title">
          <Trans i18nKey="business:c.l.s.smb.menus.right.title">Try Demo</Trans>
        </div>
        <div className="pf-subheader-menu--desc pf-subheader-menu--desc2">
          <Trans i18nKey="business:c.l.s.smb.menus.right.desc">
            Experience the future of beauty and fashion with Al & AR! Create
            incredible shopping experiences.
          </Trans>
        </div>

        {headerUtils.onlineService.map((s, i) => (
          <>{card2(i, s)}</>
        ))}
      </div>
    </div>
  );
}
