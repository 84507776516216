import home from './home/routes';
import apps from './apps/routes';
import misc from './misc/routes';
import appdownload from './appdownload/routes';
import blog from './blog/routes';
import news from './news/routes';
import event from './event/routes';
import gcse from './gcse/routes';

export default [home, apps, misc, appdownload, blog, news, event, gcse];
