import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import StrapiUtils from '../../../../strapi/strapiUtils';

export default function EnterpriseButtons({buttons}) {
  if (_isEmpty(buttons)) return <div />;
  return (
    <div className="enterprise-btn-container">
      {buttons.map((s, index) => (
        <div
          className="enterprise-btn"
          key={`enterprise-btn${index}`}
          onClick={() => {
            const elem = document.querySelector(
              `#${StrapiUtils.getAnchor(s)}`
            );
            elem &&
              elem.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
          }}
        >
          {s}
        </div>
      ))}
    </div>
  );
}
