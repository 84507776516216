import React from 'react';
import {Trans} from 'react-i18next';

const configs = {
  subscriptionTypes: [
    {
      type: 'PREMIUM_TRIAL',
      isTrial: true,
      title: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.premium">Premium</Trans>
      ),
      desc: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.smb.premium.desc">
          Everything you need for a growing business
        </Trans>
      ),
    },
    {
      type: 'ESSENTIAL_TRIAL',
      isTrial: true,
      title: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.essential">
          Essential
        </Trans>
      ),
      desc: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.smb.essential.desc">
          All the basics for starting a new business
        </Trans>
      ),
    },
    {
      type: 'ESSENTIAL',
      isTrial: false,
    },
    {
      type: 'PREMIUM',
      isTrial: false,
    },
  ],
  subscriptionTypes1: [
    {
      type: 'PREMIUM',
      isTrial: true,
      title: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.premium">Premium</Trans>
      ),
      desc: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.smb.premium.desc">
          Everything you need for a growing business
        </Trans>
      ),
    },
    {
      type: 'ESSENTIAL',
      isTrial: true,
      title: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.essential">
          Essential
        </Trans>
      ),
      desc: (
        <Trans i18nKey="business:p.b.u.freetrial.plan.smb.essential.desc">
          All the basics for starting a new business
        </Trans>
      ),
    },
    {
      type: 'ESSENTIAL',
      isTrial: false,
    },
    {
      type: 'PREMIUM',
      isTrial: false,
    },
  ],
  recurringTypes: [
    {
      type: 'MONTH',
    },
    {
      type: 'QUARTER',
    },
    {
      type: 'ANNUAL',
    },
  ],
  freeTrialDays: '30',
};

export default configs;
