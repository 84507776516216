import React, {useEffect, useState} from 'react';

import './index.css';
import Link from '../../link';
import classnames from 'classnames';
import useWindowScroll from '../../hooks/useWindowScroll';
import _defer from 'lodash/defer';

export default function GoContactButton({
  scrollTopThreshold = 0,
  style,
  displayNoneOnHidden,
  ...props
}) {
  return (
    <div>
      <Link
        className="go-contact-btn"
        to={'/business/contact-us/sales'}
        {...props}
      />
    </div>
  );
}
