import React from 'react';
import {Route} from 'react-router-dom';
import AsyncPage from './async';

export default [
  <Route
    key="/business/products/ai-skin-diagnostic"
    exact
    path="/business/products/ai-skin-diagnostic"
    component={AsyncPage}
  />,
  // <Route
  //   key="/ja/business/products/ai-skin-diagnostic"
  //   exact
  //   path="/ja/business/products/ai-skin-diagnostic"
  //   component={AsyncPage}
  // />,
];
