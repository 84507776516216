import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import importResourceBundleBackend from './importResourceBundleBackend';
const availableLanguages = [
  'en',
  'fr',
  'ja',
  'de',
  'ko',
  'es',
  'it',
  'pt',
  'zh',
  'zh-cn',
  'zh-tw',
];

const getLangFromHtml = ()=> {
  if(typeof document === 'undefined') {
    return 'en';
  }
  return document.getElementsByTagName('html')[0].getAttribute('lang');
}

i18n
  .use(importResourceBundleBackend)
  .use(initReactI18next)
  .init({
    // debug: process.env.NODE_ENV === 'development',
    // lowerCaseLng: true,
    debug: false,
    lng: getLangFromHtml(),
    supportedLngs: availableLanguages,
    whitelist: availableLanguages,
    initImmediate: false,
    nonExplicitWhitelist: true,
    defaultNS: '',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    keySeparator: false,
    ns: ['business', 'consumer', 'seo'],
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: 'added',
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
