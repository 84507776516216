import classnames from 'classnames';
import {Trans} from 'react-i18next';
import React from 'react';

export default function RegisteredName({highlight = false, style = {}}) {
  return (
    <span
      className={classnames({'agileFace--registered': true, highlight})}
      style={style}
    >
      <Trans i18nKey="business:u.c.solutionlinks.agile.face">
        AgileFace
        <i
          style={{
            display: 'inline-block',
            transform: 'scale(0.7)',
            fontStyle: 'normal',
            verticalAlign: 'super',
            position: 'relative',
            right: '2px',
            lineHeight: '1',
          }}
        >
          ®
        </i>
      </Trans>
    </span>
  );
}
