const utils = {
  cardScroll: (leftRef, leftRef1, setFixed, setBottom) => {
    if (leftRef && leftRef.current && leftRef.current.clientHeight) {
      const refHeight = leftRef.current.clientHeight - 100;
      const refHeight1 = leftRef1.current.clientHeight + 120;

      if (window.scrollY >= refHeight1 && window.scrollY < refHeight) {
        setFixed(2);
      } else if (window.scrollY >= refHeight) {
        setFixed(3);
      } else {
        setFixed(1);
      }

      if (window.scrollY >= document.body.offsetHeight - 1500) {
        setBottom(true);
      } else {
        setBottom(false);
      }
    }
  },
  esteelauder: {
    en: 1,
    'zh-cn': 42,
    zh_cn: 42,
    ja: 6,
    fr: 24,
    ko: 153,
    de: 99,
    es: 117,
    it: 135,
  },
  benefit: {
    en: 2,
    'zh-cn': 43,
    zh_cn: 43,
    ja: 11,
    fr: 25,
    ko: 154,
    de: 100,
    es: 118,
    it: 136,
  },
  elf: {en: 21, 'zh-cn': 50, zh_cn: 50, fr: 34, ko: 160, de: 106},
  isInFrame: () => {
    return window.location.pathname.indexOf('/iframe') !== -1;
  },
};

export default utils;
